import { logDebug } from '../services/debugService';

const debugRating = (message, data = {}) => {
  logDebug('RatingUtils', message, data);
};

/**
 * Converts a rating value (0-100) to a star count (0-5)
 * Based on the original Java implementation from LibraryItem.getStarCount
 * 
 * Scale:
 * < 25: 1 star
 * 25-49: 2 stars
 * 50-74: 3 stars
 * 75-99: 4 stars
 * 100: 5 stars
 */
export function getStarCount(rating) {

  if (rating < 0) return 0;
  if (rating < 25) return 1;
  if (rating < 50) return 2;
  if (rating < 75) return 3;
  if (rating < 100) return 4;
  return 5;
}

/**
 * Converts a star rating (1-5) to a numeric value (0-100)
 * Scale:
 * 1 star = 1
 * 2 stars = 25
 * 3 stars = 50
 * 4 stars = 80
 * 5 stars = 100
 */
export function getNumericRating(stars) {
  debugRating('Converting stars to numeric rating', { stars });
  switch (stars) {
    case 1: return 1;
    case 2: return 25;
    case 3: return 50;
    case 4: return 80;
    case 5: return 100;
    default: return 0;
  }
}
