import { useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { Flex, Text, Spinner } from '@radix-ui/themes';
import { logDebug } from '../services/debugService';
import { setToken } from '../services/authService';

// Debug helper
const debugAuth = (message, data = {}) => {
  logDebug('AuthCallback', message, data);
};

export const AuthCallback = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { updateUser } = useAuth();
  const processed = useRef(false);

  useEffect(() => {
    const handleCallback = () => {
      // Prevent multiple processing
      if (processed.current) {
        debugAuth('Already processed callback, skipping', {
          pathname: location.pathname,
          search: location.search,
          localStorageKeys: Object.keys(localStorage)
        });
        console.log('[AuthCallback] Callback already processed, skipping');
        return;
      }

      debugAuth('Processing callback', {
        pathname: location.pathname,
        search: location.search,
        localStorageKeys: Object.keys(localStorage)
      });

      const params = new URLSearchParams(location.search);
      console.log('[AuthCallback] Processing URL params:', Object.fromEntries(params));
      
      // Check if this is an OAuth2 code callback or our final success callback
      const code = params.get('code');
      if (code) {
        debugAuth('Received OAuth2 code callback, waiting for success redirect', { 
          code,
          state: params.get('state')
        });
        processed.current = true;
        return;
      }
      
      const success = params.get('success') === 'true';
      const id = params.get('id');
      const login = params.get('login');
      const name = params.get('name');
      const email = params.get('email');
      const token = params.get('token');

      debugAuth('Processing params', { 
        success, 
        id, 
        login, 
        name, 
        email,
        hasToken: !!token,
        localStorageKeys: Object.keys(localStorage)
      });

      if (success && id && login && token) {
        console.log('[AuthCallback] Received token from params:', token);
        
        console.log('[AuthCallback] Setting token in auth service');
        setToken(token);

        const userData = {
          id,
          login,
          name: name || login,
          email,
          authenticated: true
        };
        console.log('[AuthCallback] Setting user data:', userData);
        
        // Update auth context (this will handle storage)
        updateUser(userData);
        
        // Mark as processed to prevent loops
        processed.current = true;
        
        // Get the intended path from localStorage
        const intendedPath = localStorage.getItem('intended_path') || '/';
        debugAuth('Redirecting to intended path', { 
          intendedPath,
          localStorageKeys: Object.keys(localStorage)
        });
        
        // Clear the stored path
        try {
          localStorage.removeItem('intended_path');
          debugAuth('Cleared intended_path from localStorage');
        } catch (e) {
          console.error('[AuthCallback] Error clearing intended_path:', e);
        }

        // Navigate to the intended path
        navigate(intendedPath);
      } else if (!code) { // Only show error if this isn't the code callback
        debugAuth('Auth failed', { 
          success, 
          id, 
          login, 
          name,
          localStorageKeys: Object.keys(localStorage)
        });
        debugAuth('No valid user data found, redirecting to root', {
          login, 
          name,
          localStorageKeys: Object.keys(localStorage)
        });
        processed.current = true;
        navigate('/', { replace: true });
      }
    };

    // Only process if we have URL parameters
    if (location.search) {
      handleCallback();
    } else {
      debugAuth('No URL parameters found, redirecting to root', {
        pathname: location.pathname,
        localStorageKeys: Object.keys(localStorage)
      });
      navigate('/', { replace: true });
    }
  }, [location, navigate, updateUser]);

  return (
    <Flex 
      direction="column" 
      align="center" 
      justify="center" 
      gap="3"
      style={{ minHeight: '100vh' }}
    >
      <Spinner size="large" />
      <Text size="3">Completing authentication...</Text>
    </Flex>
  );
};
