import { useCallback } from 'react';
import { ratingApi } from '../../services/api/ratingApi';

/**
 * Custom hook for handling ratings
 * @param {Function} onRate Callback function to handle rating
 * @returns {Object} Rating handlers
 */
export function useRating(onRate) {
  const handleTrackRate = useCallback(async (id, rating) => {
    try {
      if (!id || isNaN(id)) {
        console.error('Invalid track ID for rating:', id);
        return;
      }
      
      console.log('Rating track:', { id, rating });
      const response = await ratingApi.rateTrack(id, rating);
      
      if (response?.success !== false) {
        console.log('Rating successful, updating local state');
        onRate?.(id, rating, 'track');
      } else {
        console.error('Rating API returned error:', response);
      }
    } catch (error) {
      console.error('Failed to rate track:', error);
    }
  }, [onRate]);

  const handleAlbumRate = useCallback(async (id, rating) => {
    try {
      if (!id) {
        console.error('Missing album ID for rating');
        return;
      }
      
      console.log('Rating album:', { id, rating });
      const response = await ratingApi.rateAlbum({ 
        albumKey: id, 
        rating: rating 
      });
      
      if (response?.success !== false) {
        console.log('Rating successful, updating local state');
        onRate?.(id, rating, 'album');
      } else {
        console.error('Rating API returned error:', response);
      }
    } catch (error) {
      console.error('Failed to rate album:', error);
    }
  }, [onRate]);

  const handleArtistRate = useCallback(async (id, rating) => {
    try {
      if (!id) {
        console.error('Missing artist ID for rating');
        return;
      }
      
      console.log('Rating artist:', { id, rating });
      const response = await ratingApi.rateArtist(id, rating);
      
      if (response?.success !== false) {
        console.log('Rating successful, updating local state');
        onRate?.(id, rating, 'artist');
      } else {
        console.error('Rating API returned error:', response);
      }
    } catch (error) {
      console.error('Failed to rate artist:', error);
    }
  }, [onRate]);

  return {
    handleTrackRate,
    handleAlbumRate,
    handleArtistRate
  };
}
