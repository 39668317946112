import React, { useCallback } from 'react';
import { RatingStars } from '../../../RatingStars';
import { DefaultAlbumArt } from '../../../common/DefaultAlbumArt';

export function ArtistDetailView({ 
  artist, 
  albums, 
  onArtistRate,
  onAlbumRate,
  onAlbumClick
}) {
  // Handle rating click without opening album
  const handleRatingClick = (album, newRating) => {
    if (!onAlbumRate) {
      console.error('onAlbumRate prop is not provided to ArtistDetailView');
      return;
    }
    onAlbumRate(album, newRating);
  };

  const handleArtistRating = useCallback((artist, rating) => {
    if (!artist?.artist) return;
    onArtistRate(artist.artist, rating);
  }, [onArtistRate]);

  return (
    <div className="space-y-6">
      <div className="flex flex-col items-center mb-6">
        <h2 className="text-2xl font-bold text-white mb-2">{artist.artist}</h2>
        {artist.artistRating && (
          <div className="flex items-center gap-2">
            <RatingStars
              rating={(artist.artistRating.rating || 0) / 20}
              onChange={(newRating) => handleArtistRating(artist, newRating)}
              readonly={false}
              size={24}
            />
          </div>
        )}
      </div>

      <div className="grid grid-cols-1 gap-2">
        {albums.map((album) => (
          <div 
            key={album.id} 
            className="flex items-center p-3 bg-[#1a1a1a] hover:bg-[#252525] rounded-lg transition-colors cursor-pointer"
            onClick={() => onAlbumClick({ 
              ...album, 
              type: 'album',
              rdioKey: album.rdioKey 
            })}
          >
            <div className="flex-shrink-0 w-16 h-16 mr-4">
              <img
                src={album.baseicon || DefaultAlbumArt.dataUrl}
                alt={album.name}
                className="w-full h-full object-cover rounded hover:opacity-80 transition-opacity"
                onError={DefaultAlbumArt.onError}
              />
            </div>
            <div className="flex-1 min-w-0">
              <h3 className="text-white font-medium truncate">
                {album.name}
              </h3>
              <p className="text-gray-400 text-sm">
                {album.artist}
                {album.length > 0 && (
                  <span className="text-gray-500 ml-2">• {album.length} tracks</span>
                )}
              </p>
              <div className="text-gray-500 text-xs space-y-1">
                <p className="truncate">
                  ID: {album.albumKey}
                </p>
                {album.displaydate && (
                  <p className="truncate">
                    Released: {album.displaydate}
                  </p>
                )}
                <div className="flex gap-2 mt-1">
                  {album.isexplicit && (
                    <span className="px-1.5 py-0.5 bg-red-900 text-red-100 rounded">
                      Explicit
                    </span>
                  )}
                  {album.isclean && (
                    <span className="px-1.5 py-0.5 bg-green-900 text-green-100 rounded">
                      Clean
                    </span>
                  )}
                  {album.iscompliation && (
                    <span className="px-1.5 py-0.5 bg-blue-900 text-blue-100 rounded">
                      Compilation
                    </span>
                  )}
                </div>
                {album.album_rating && (
                  <div className="mt-1">
                    <RatingStars
                      rating={(album.album_rating.rating || 0) / 20}
                      onChange={(newRating) => handleRatingClick(album, newRating)}
                      readonly={false}
                      size={16}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
