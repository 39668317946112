//Copyright 2025 ZMusicCafe
import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import { RoomProvider } from './contexts/RoomContext';
import { logDebug } from './services/debugService';
import { Theme } from '@radix-ui/themes';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import '@radix-ui/themes/styles.css';
import './App.css';

const APP_VERSION = '0.0.0'; // Match with package.json

const debugApp = (message, data = {}) => {
  // Only log non-sensitive app information
  if (!message.toLowerCase().includes('environment')) {
    logDebug('App', message, data);
  }
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 30, // 30 seconds
      retry: 2,
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
    },
  },
});

const ProtectedRoute = ({ children }) => {
  const { user, loading } = useAuth();

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!user?.authenticated) {
    return <Login />;
  }

  return children;
};

function AppRoutes() {
  return (
    <Routes>
      <Route path="/auth/twitch" element={<AuthCallback />} />
      <Route path="/auth/callback" element={<AuthCallback />} />
      <Route path="/" element={
        <ProtectedRoute>
          <Room />
        </ProtectedRoute>
      } />
      <Route path="/room/:roomName" element={
        <ProtectedRoute>
          <Room />
        </ProtectedRoute>
      } />
      <Route path="/room/undefined" element={<Navigate to="/" replace />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
}

function App() {
  useEffect(() => {
    const mode = import.meta.env.MODE;
    logDebug('App', `ZMusic Frontend v${APP_VERSION} (${mode})`);
    debugApp('Application initialized', { version: APP_VERSION, mode });
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <Theme>
        <AuthProvider>
          <RoomProvider>
            <Outlet />
          </RoomProvider>
        </AuthProvider>
      </Theme>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

export default App;
