import { initiateOAuth, BACKEND_URL } from '../authService';
import { logDebug } from '../debugService';
import apiClient from '../apiClient';

const debugRoomApi = (message, data = {}) => {
  logDebug('RoomApi', message, data);
};

const getAuthHeaders = async () => {
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  
  // Get CSRF token from cookie
  const csrfToken = document.cookie
    .split('; ')
    .find(row => row.startsWith('XSRF-TOKEN='))
    ?.split('=')[1];

  if (csrfToken) {
    headers.append('X-XSRF-TOKEN', csrfToken);
  }

  // Add session ID for iOS Chrome
  const sessionId = document.cookie
    .split('; ')
    .find(row => row.startsWith('JSESSIONID='))
    ?.split('=')[1];

  if (sessionId) {
    headers.append('X-Session-ID', sessionId);
  }

  // Add iOS Chrome header if applicable
  if (navigator.userAgent.includes('CriOS')) {
    headers.append('X-iOS-Chrome', 'true');
  }

  return headers;
};

const handleAuthError = async () => {
  debugRoomApi('Auth error detected', {
    cookieCount: document.cookie.split(';').length
  });
  sessionStorage.removeItem('auth_token');
  sessionStorage.removeItem('userData');
  throw new Error('Not authenticated');
};

const fetchWithCookieDebug = async (url, options = {}) => {
  // Only log essential request info
  debugRoomApi('Request details', {
    url,
    options: {
      ...options,
      headers: Object.keys(options.headers || {})
    }
  });

  // Always include credentials
  const response = await fetch(url, {
    ...options,
    credentials: 'include',
    headers: {
      ...options.headers,
      'X-iOS-Chrome': navigator.userAgent.includes('CriOS') ? 'true' : undefined
    }
  });

  // Only log essential response info
  debugRoomApi('Response details', {
    status: response.status,
    ok: response.ok,
    headers: Object.fromEntries(response.headers.entries())
  });

  return response;
};

/**
 * Room API service
 * Handles all room-related API calls
 * Preparing for React Query migration
 */
export const roomApi = {
  /**
   * Get room information including recent tracks
   */
  getRoomInfo: async (roomName) => {
    try {
      debugRoomApi('Fetching room info for:', roomName);
      const response = await apiClient.get(`/room/${encodeURIComponent(roomName)}/info`);
      debugRoomApi('Room info response:', response.data);
      return response.data;
    } catch (error) {
      debugRoomApi('Error fetching room info:', error.response || error);
      throw error;
    }
  },

  /**
   * Fetch current room data including queue and current track
   * @returns {Promise<Object>} Room data
   * @throws {Error} If request fails
   */
  fetchRoomData: async () => {
    try {
      debugRoomApi('Fetching room data');
      const [roomResponse, queueResponse] = await Promise.all([
        apiClient.get('/room'),
        apiClient.get('/room/queue').catch(() => null) // Make queue endpoint optional
      ]);

      debugRoomApi('Raw room response:', { 
        data: roomResponse?.data,
        status: roomResponse?.status,
        headers: roomResponse?.headers
      });

      // Ensure we have a valid response
      if (!roomResponse?.data) {
        debugRoomApi('No room data received', {
          response: roomResponse,
          responseType: typeof roomResponse,
          hasData: !!roomResponse?.data,
          dataType: typeof roomResponse?.data
        });
        return {
          room: {
            queue: []
          },
          user: null,
          artistRating: null,
          albumRating: null,
          roomState: null
        };
      }

      // Extract room data from UserRoomView
      const roomData = roomResponse.data;
      debugRoomApi('Processing UserRoomView data', {
        roomData,
        hasRoom: !!roomData?.room,
        roomName: roomData?.room?.name,
        hasUser: !!roomData?.user,
        hasRatings: {
          artist: !!roomData?.artistRating,
          album: !!roomData?.albumRating
        },
        roomState: roomData?.roomState,
        currentTrack: roomData?.room?.currentplayinglibrary ? {
          id: roomData?.room?.currentplayinglibrary?.lid,
          hasLibraryItem: !!roomData?.room?.currentplayinglibrary?.track
        } : null
      });

      // Ensure we have a valid room object
      if (!roomData.room) {
        roomData.room = {};
      }

      // Merge queue data if available
      if (queueResponse?.data) {
        debugRoomApi('Merging queue data', { 
          queueData: queueResponse.data,
          queueLength: queueResponse.data?.length || 0 
        });
        roomData.room.queue = queueResponse.data;
      }

      debugRoomApi('Final room data:', {
        roomData,
        hasRoom: !!roomData?.room,
        hasQueue: !!roomData?.room?.queue,
        queueLength: roomData?.room?.queue?.length || 0,
        hasUser: !!roomData?.user,
        hasCurrentTrack: !!roomData?.room?.currentplayinglibrary?.track
      });

      return roomData;
    } catch (error) {
      debugRoomApi('Failed to fetch room data', { 
        error,
        message: error.message,
        status: error.response?.status,
        responseData: error.response?.data
      });
      // Return a valid default structure instead of throwing
      return {
        room: {
          queue: []
        },
        user: null,
        artistRating: null,
        albumRating: null,
        roomState: null
      };
    }
  },

  /**
   * Fetch room rating
   * @returns {Promise<number>} Room rating value
   * @throws {Error} If request fails
   */
  async fetchRoomRating() {
    try {
      debugRoomApi('Fetching room rating');
      const response = await apiClient.get('/room/roomrating');
      debugRoomApi('Room rating received', { rating: response?.data });
      return response?.data || 0; // Return the data field or 0 if not available
    } catch (error) {
      debugRoomApi('Failed to fetch room rating', { error });
      throw error;
    }
  },

  /**
   * Join a room by name
   * @param {string} roomName - Name of the room to join
   * @returns {Promise<Object>} Response data
   * @throws {Error} If request fails
   */
  joinRoom: async (roomName) => {
    try {
      // Check for reserved room names
      const reservedNames = ['room', 'login', 'settings', 'auth'];
      if (reservedNames.includes(roomName?.toLowerCase())) {
        throw new Error('This room name is reserved for system use');
      }

      debugRoomApi('Joining room', { roomName });
      const response = await apiClient.post(`/room/${encodeURIComponent(roomName)}`);
      debugRoomApi('Room join response:', { 
        status: response?.status,
        data: response?.data,
        room: response?.data?.room,
        roomName: response?.data?.room?.name
      });
      return response?.data;
    } catch (error) {
      debugRoomApi('Failed to join room', { 
        error,
        message: error.message,
        status: error.response?.status,
        data: error.response?.data
      });
      throw error;
    }
  },

  /**
   * Rate a track
   * @param {string} trackId - ID of track to rate
   * @param {number} rating - Rating value (0-100)
   * @returns {Promise<Object>} Response data
   * @throws {Error} If request fails
   */
  async rateTrack(trackId, rating) {
    if (!trackId) {
      throw new Error('Track ID is required');
    }

    try {
      debugRoomApi('Rating track', { trackId, rating });
      const response = await apiClient.get(`/room/rate`, {
        params: {
          trackid: trackId,
          rating
        }
      });
      debugRoomApi('Track rated successfully', { response });
      return response;
    } catch (error) {
      debugRoomApi('Failed to rate track', { error, trackId, rating });
      throw error;
    }
  },

  /**
   * Rate an album
   * @param {string} albumId - MusicBrainz ID of the album to rate
   * @param {number} rating - Rating value (0-100)
   * @returns {Promise<Object>} Response data
   * @throws {Error} If request fails
   */
  async rateAlbum(albumId, rating) {
    if (!albumId) {
      throw new Error('Album ID is required');
    }

    try {
      debugRoomApi('Rating album', { albumId, rating });
      const response = await apiClient.get(`/room/rateAlbum`, {
        params: {
          musicbrainz_id: albumId,
          rate: rating
        }
      });
      debugRoomApi('Album rated successfully', { response });
      return response;
    } catch (error) {
      debugRoomApi('Failed to rate album', { error, albumId, rating });
      throw error;
    }
  },

  /**
   * Rate an artist
   * @param {string} artistName - Name of the artist to rate
   * @param {number} rating - Rating value (0-100)
   * @returns {Promise<Object>} Response data
   * @throws {Error} If request fails
   */
  async rateArtist(artistName, rating) {
    if (!artistName) {
      throw new Error('Artist name is required');
    }

    try {
      // Ensure artist name is properly encoded
      const encodedArtistName = encodeURIComponent(decodeURIComponent(artistName));
      debugRoomApi('Rating artist', { artistName: encodedArtistName, rating });
      const response = await apiClient.get(`/room/rateArtist`, {
        params: {
          artist_name: encodedArtistName,
          rate: rating
        }
      });
      debugRoomApi('Artist rated successfully', { response });
      return response;
    } catch (error) {
      debugRoomApi('Failed to rate artist', { error, artistName, rating });
      throw error;
    }
  },

  /**
   * Vote on a track
   * @param {string} trackId - ID of the track to vote on
   * @param {string} voteType - Type of vote ('up', 'down', or 'top')
   * @returns {Promise<Object>} Response data
   * @throws {Error} If request fails
   */
  voteOnTrack: async (trackId, voteType) => {
    try {
      debugRoomApi('Voting on track', { trackId, voteType });
      const response = await apiClient.post('/room/queue/vote', { 
        trackId,
        direction: voteType
      });
      debugRoomApi('Track vote successful', { response });
      return response;
    } catch (error) {
      debugRoomApi('Error voting on track', { error });
      throw error;
    }
  },

  /**
   * Add track to queue
   * @param {string} trackId - ID of the track to add
   * @returns {Promise<Object>} Response data
   * @throws {Error} If request fails
   */
  async addToQueue(trackId) {
    try {
      debugRoomApi('Adding track to queue', { trackId });
      
      if (!trackId) {
        throw new Error('Missing track ID for queue addition');
      }
      
      // IMPORTANT: The parameter is misleadingly named 'libraryItem' but expects a track ID
      // The backend uses this to look up a Track: Track track = trackService.findById(libraryItem);
      const response = await apiClient.get('/room/queueAdd', {
        params: {
          libraryItem: Number(trackId),
          messageUsers: true
        }
      });
      return response;
    } catch (error) {
      debugRoomApi('Failed to add track to queue', { error });
      throw error;
    }
  },

  /**
   * Remove track from queue
   * @param {string} trackId - ID of the track to remove
   * @returns {Promise<Object>} Response data
   * @throws {Error} If request fails
   */
  async removeFromQueue(trackId) {
    try {
      debugRoomApi('Removing track from queue', { trackId });
      const response = await apiClient.get(`/room/queueRemove?lid=${trackId}`);
      return response;
    } catch (error) {
      debugRoomApi('Failed to remove track from queue', { error });
      throw error;
    }
  },

  /**
   * Skip current song
   * @returns {Promise<Object>} Response data
   * @throws {Error} If request fails
   */
  async skipSong() {
    try {
      debugRoomApi('Skipping current song');
      const response = await apiClient.post('/room/skipsong');
      return response;
    } catch (error) {
      debugRoomApi('Failed to skip song', { error });
      throw error;
    }
  },

  /**
   * Toggle room mode
   * @returns {Promise<Object>} Response data
   * @throws {Error} If request fails
   */
  async toggleRoomMode() {
    try {
      debugRoomApi('Toggling room mode');
      const response = await apiClient.post('/room/toggleroommode');
      return response;
    } catch (error) {
      debugRoomApi('Failed to toggle room mode', { error });
      throw error;
    }
  },

  /**
   * Toggle room type (between Republic and Democracy)
   * @returns {Promise<Object>} Response data
   * @throws {Error} If request fails
   */
  async toggleRoomType() {
    try {
      debugRoomApi('Toggling room type');
      const response = await apiClient.post('/room/toggleroomtype');
      return response;
    } catch (error) {
      debugRoomApi('Failed to toggle room type', { error });
      throw error;
    }
  },

  /**
   * Move track up in queue
   * @param {string} trackId - ID of the track to move up
   * @returns {Promise<Object>} Response data
   * @throws {Error} If request fails
   */
  moveUpInQueue: async (trackId) => {
    try {
      debugRoomApi('Moving track up', { trackId });
      const response = await apiClient.get(`/room/queueMoveUp`, {
        params: { lid: Number(trackId) }
      });
      debugRoomApi('Successfully moved track up', { response });
      return response;
    } catch (error) {
      debugRoomApi('Failed to move track up', { error });
      throw error;
    }
  },

  /**
   * Move track down in queue
   * @param {string} trackId - ID of the track to move down
   * @returns {Promise<Object>} Response data
   * @throws {Error} If request fails
   */
  moveDownInQueue: async (trackId) => {
    try {
      debugRoomApi('Moving track down', { trackId });
      return await apiClient.get(`/room/queueMoveDown`, {
        params: { lid: Number(trackId) }
      });
    } catch (error) {
      debugRoomApi('Failed to move track down', { error });
      throw error;
    }
  },

  /**
   * Move track to top of queue
   * @param {string} trackId - ID of the track to move to top
   * @returns {Promise<Object>} Response data
   * @throws {Error} If request fails
   */
  moveToTopInQueue: async (trackId) => {
    try {
      debugRoomApi('Moving track to top', { trackId });
      return await apiClient.get(`/room/queueMoveAllUp`, {
        params: { lid: Number(trackId) }
      });
    } catch (error) {
      debugRoomApi('Failed to move track to top', { error });
      throw error;
    }
  },

  /**
   * Get list of online rooms
   * @returns {Promise<Array>} List of rooms
   * @throws {Error} If request fails
   */
  getRooms: async () => {
    try {
      debugRoomApi('Fetching room list');
      const response = await apiClient.get('/room/rooms');
      debugRoomApi('Room list received', { count: response?.data?.length });
      return response?.data || [];
    } catch (error) {
      debugRoomApi('Failed to fetch room list', { error });
      throw error;
    }
  },

  /**
   * Get list of offline rooms
   * @returns {Promise<Array>} List of offline rooms
   * @throws {Error} If request fails
   */
  getOfflineRooms: async () => {
    try {
      debugRoomApi('Fetching offline rooms');
      const response = await apiClient.get('/room/rooms?includeOffline=true');
      const rooms = response?.data?.filter(room => room.idle === true) || [];
      debugRoomApi('Offline rooms received', { rooms });
      return rooms;
    } catch (error) {
      debugRoomApi('Failed to fetch offline rooms', { error });
      throw error;
    }
  },

  /**
   * Get current state of a room including currently playing track
   * @param {string} roomName - Name of the room
   * @returns {Promise<Object>} Room state including current track
   * @throws {Error} If request fails
   */
  getRoomState: async (roomName) => {
    try {
      debugRoomApi('Fetching room state', { roomName });
      const response = await apiClient.get(`/room/${encodeURIComponent(roomName)}/state`);
      debugRoomApi('Room state received', { state: response?.data });
      return response?.data;
    } catch (error) {
      debugRoomApi('Failed to fetch room state', { error });
      throw error;
    }
  },
};

/**
 * Room API client for making authenticated requests to room endpoints
 */
