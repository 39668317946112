import { Button } from '@radix-ui/themes';
import { initiateOAuth } from '../services/authService';
import { logDebug } from '../services/debugService';

// Storage debug helper
const debugStorage = (operation) => {
  logDebug('Login', 'Storage Operation', { operation });
};

export const Login = () => {
  const handleLogin = () => {
    // Only clear specific storage items instead of all storage
    try {
      // Clear only our application-specific items
      const ourStorageKeys = [
        'oauth_state',
        'user_data'
      ];
      
      ourStorageKeys.forEach(key => {
        localStorage.removeItem(key);
        sessionStorage.removeItem(key);
        debugStorage(`remove item: ${key}`);
      });
    } catch (e) {
      console.error('Failed to clear storage:', e);
    }
    initiateOAuth();
  };

  return (
    <div className="min-h-screen bg-[#121212] flex flex-col items-center justify-center p-4">
      <div className="bg-[#1A1A1A] p-8 rounded-lg shadow-xl text-center max-w-md w-full">
        <h1 className="text-3xl font-bold mb-6 text-white">Welcome to ZMusic</h1>
        <p className="text-gray-400 mb-8">
          Connect with your Twitch account to start listening and sharing music.
        </p>
        <Button 
          size="3" 
          onClick={handleLogin}
          className="w-full bg-[#9146FF] hover:bg-[#7B31FF] text-white font-medium py-3 px-6 rounded-lg transition-colors"
        >
          <svg 
            viewBox="0 0 24 24" 
            className="w-5 h-5 mr-2 inline-block"
            fill="currentColor"
          >
            <path d="M11.571 4.714h1.715v5.143H11.57zm4.715 0H18v5.143h-1.714zM6 0L1.714 4.286v15.428h5.143V24l4.286-4.286h3.428L22.286 12V0zm14.571 11.143l-3.428 3.428h-3.429l-3 3v-3H6.857V1.714h13.714Z"/>
          </svg>
          Login with Twitch
        </Button>
      </div>
    </div>
  );
};
