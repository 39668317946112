import React, { useState } from 'react';
import { GearIcon, ChevronDownIcon } from '@radix-ui/react-icons';
import { RoomInfoDialog } from './RoomInfoDialog';
import { useQuery } from '@tanstack/react-query';
import { roomApi } from '../../services/api/roomApi';
import { logDebug } from '../../services/debugService';
import { RoomSelector } from '../../components/RoomSelector';

const debugHeader = (message, data = {}) => {
  logDebug('Header', message, data);
};

export function Header({ roomName, userName, onSettingsOpen, onLogout, onRating, onAddToQueue }) {
  const [isRoomInfoOpen, setIsRoomInfoOpen] = useState(false);

  debugHeader('Rendering Header', { roomName, isRoomInfoOpen });

  // Only fetch room info when dialog is open
  const { data: roomInfo, isLoading, isError, error } = useQuery({
    queryKey: ['roomInfo', roomName],
    queryFn: async () => {
      debugHeader('Fetching room info', { roomName });
      try {
        const data = await roomApi.getRoomInfo(roomName);
        debugHeader('Room info fetched successfully', { data });
        return data;
      } catch (err) {
        debugHeader('Error fetching music info', { error: err.message });
        throw err;
      }
    },
    enabled: isRoomInfoOpen && !!roomName,
    retry: 1,
    staleTime: 30000, // Consider data fresh for 30 seconds
    cacheTime: 5 * 60 * 1000, // Keep in cache for 5 minutes
  });

  return (
    <header className="bg-[#121212] px-6 py-4">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-4">
          <button
            onClick={onSettingsOpen}
            className="p-2 rounded-full hover:bg-[#3E3E3E] focus:outline-none"
          >
            <GearIcon className="h-5 w-5 text-gray-400" />
          </button>

          <div className="flex flex-col">
            <div className="flex items-center gap-2">
              <button
                onClick={() => setIsRoomInfoOpen(true)}
                className="text-lg font-medium bg-[#282828] text-white hover:bg-[#3E3E3E] rounded-l-full px-4 py-2 transition-colors"
              >
                {roomName || 'Loading...'}
              </button>
              <RoomSelector
                trigger={
                  <button
                    className="text-lg font-medium bg-[#282828] text-white hover:bg-[#3E3E3E] rounded-r-full px-2 py-2 transition-colors border-l border-[#3E3E3E]"
                    aria-label="Change Room"
                  >
                    <ChevronDownIcon className="h-5 w-5" />
                  </button>
                }
              />
            </div>
            <div className="text-sm text-gray-400 mt-1">
              Logged in as {userName || 'Loading...'}
            </div>
          </div>
        </div>

        <button
          onClick={onLogout}
          className="px-4 py-2 text-sm text-white bg-[#282828] hover:bg-[#3E3E3E] rounded-full"
        >
          Logout
        </button>
      </div>

      <RoomInfoDialog
        isOpen={isRoomInfoOpen}
        onClose={() => setIsRoomInfoOpen(false)}
        roomInfo={roomInfo}
        onRating={onRating}
        onAddToQueue={onAddToQueue}
        isLoading={isLoading}
        isError={isError}
        error={error}
      />
    </header>
  );
}
