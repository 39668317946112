import { useState, useCallback, useEffect } from 'react';
import { searchApi } from '../../services/api/searchApi';
import { useDebounce } from '../useDebounce';

/**
 * Custom hook for handling search functionality
 * @param {Object} options - Hook options
 * @param {string} options.type - Type of search ('tracks', 'albums', 'artists')
 * @param {number} options.debounceMs - Debounce time in milliseconds
 * @returns {Object} Search state and handlers
 */
export const useSearch = ({ type = 'tracks', debounceMs = 300 } = {}) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [results, setResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [error, setError] = useState(null);
  const [lastSearchQuery, setLastSearchQuery] = useState('');
  const [hasActiveSearch, setHasActiveSearch] = useState(false);
  const [activeSearchType, setActiveSearchType] = useState(type);
  
  // Use debounce to avoid too many API calls
  const debouncedSearchQuery = useDebounce(searchQuery, debounceMs);

  /**
   * Perform a search with the given query
   * @param {string} query - Search query
   * @param {string} searchType - Type of search
   */
  const performSearch = useCallback(async (query, searchType = type) => {
    if (!query || query.trim() === '') {
      return;
    }

    setIsSearching(true);
    setError(null);
    setLastSearchQuery(query);
    setActiveSearchType(searchType);

    try {
      console.log('Performing search for:', query, 'with type:', searchType);
      const searchResults = await searchApi.search(query, searchType);
      
      // Results are already sorted and filtered by the API service
      setResults(searchResults || []);
      setHasActiveSearch(true);
    } catch (error) {
      console.error('Search error:', error);
      setError('Failed to search. Please try again.');
      setResults([]);
    } finally {
      setIsSearching(false);
    }
  }, [type]);

  // Auto-search when debounced query changes
  useEffect(() => {
    if (debouncedSearchQuery.length >= 3) {
      performSearch(debouncedSearchQuery, activeSearchType);
    } else if (debouncedSearchQuery === '') {
      setResults([]);
      setHasActiveSearch(false);
    }
  }, [debouncedSearchQuery, performSearch, activeSearchType]);

  // Clear search results and query
  const clearSearch = useCallback(() => {
    setSearchQuery('');
    setResults([]);
    setError(null);
    setHasActiveSearch(false);
  }, []);

  return {
    searchQuery,
    setSearchQuery,
    results,
    isSearching,
    error,
    hasActiveSearch,
    lastSearchQuery,
    activeSearchType,
    clearSearch,
    performSearch,
  };
};
