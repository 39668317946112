import { BACKEND_URL, getToken, clearToken } from './authService';

/**
 * Common API client for making authenticated requests
 * Automatically handles JWT token from session storage
 */
const MAX_RETRIES = 3;
const RETRY_DELAY = 1000;

const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));

const getAuthHeaders = () => {
  const token = getToken();
  return token ? { 'Authorization': token } : {};
};

const handleUnauthorized = () => {
  console.debug('[API] Clearing token due to unauthorized response');
  clearToken();
  throw new Error('Unauthorized - Please log in again');
};

const getBaseUrl = () => {
  // In development, use relative URLs to go through Vite proxy
  if (import.meta.env.DEV) {
    return '';
  }
  return BACKEND_URL;
};

const apiClient = {
  /**
   * Make an API request
   * @param {string} endpoint - API endpoint
   * @param {Object} options - Request options
   * @returns {Promise<Response>} Fetch response
   */
  async request(endpoint, options = {}) {
    try {
      const config = {
        ...options,
        headers: {
          'Content-Type': 'application/json',
          ...getAuthHeaders(),
          ...options.headers
        },
        credentials: 'include' // Always include credentials
      };

      // Handle query params
      let baseUrl = getBaseUrl();
      
      // For room-related endpoints in development, use the backend URL directly
      if (import.meta.env.DEV && (endpoint.startsWith('/room/') || endpoint === '/room')) {
        baseUrl = BACKEND_URL || 'https://auricle.ngrok.io';
      }
      
      let url = `${baseUrl}${endpoint}`;
      if (options.params) {
        const params = new URLSearchParams(options.params);
        url = `${url}?${params}`;
        delete config.params;
      }

      console.debug(`[API] ${options.method || 'GET'} ${endpoint}`, {
        url,
        config: {
          ...config,
          headers: Object.keys(config.headers)
        }
      });

      const response = await fetch(url, config);
      
      // Get response text and try to parse as JSON
      const responseText = await response.text();
      let data = null;
      try {
        data = responseText ? JSON.parse(responseText) : null;
      } catch (e) {
        console.warn(`[API] Failed to parse response as JSON from ${endpoint}:`, e);
        data = responseText;
      }

      if (response.status === 401) {
        console.debug('[API] 401 Unauthorized', {
          endpoint,
          status: response.status,
          data
        });
        handleUnauthorized();
      }

      if (!response.ok) {
        console.warn(`[API] Request to ${endpoint} failed:`, {
          status: response.status,
          data,
          headers: Object.fromEntries(response.headers.entries())
        });
        throw new Error(`Request failed with status ${response.status}: ${data?.message || responseText}`);
      }

      return {
        data,
        status: response.status,
        headers: response.headers,
        ok: response.ok
      };
    } catch (error) {
      console.error(`[API] Request to ${endpoint} failed:`, {
        message: error.message,
        status: error.response?.status,
        data: error.response?.data,
        stack: error.stack
      });
      throw error;
    }
  },

  /**
   * Make a GET request
   * @param {string} endpoint - API endpoint
   * @param {object} options - Request options
   * @returns {Promise<any>} Response data
   */
  async get(endpoint, options = {}) {
    const response = await this.request(endpoint, {
      ...options,
      method: 'GET'
    });
    return { data: response.data, status: response.status, headers: response.headers };
  },

  /**
   * Make a POST request
   * @param {string} endpoint - API endpoint
   * @param {Object} data - Request body data
   * @param {Object} options - Additional fetch options
   * @returns {Promise<any>} Response data
   */
  async post(endpoint, data, options = {}) {
    const response = await this.request(endpoint, {
      ...options,
      method: 'POST',
      body: JSON.stringify(data)
    });
    return { data: response.data, status: response.status, headers: response.headers };
  },

  /**
   * Make a PUT request
   * @param {string} endpoint - API endpoint
   * @param {Object} data - Request body data
   * @param {Object} options - Additional fetch options
   * @returns {Promise<any>} Response data
   */
  async put(endpoint, data, options = {}) {
    return this.request(endpoint, {
      ...options,
      method: 'PUT',
      body: JSON.stringify(data)
    });
  },

  /**
   * Make a DELETE request
   * @param {string} endpoint - API endpoint
   * @param {Object} options - Additional fetch options
   * @returns {Promise<any>} Response data
   */
  async delete(endpoint, options = {}) {
    return this.request(endpoint, {
      ...options,
      method: 'DELETE'
    });
  }
};

export default apiClient;
