import React, { createContext, useContext, useEffect, useCallback } from 'react';
import { useAuth } from './AuthContext';
import { useRoom as useRoomQuery } from '../queries/useRoom';
import { getToken, BACKEND_URL } from '../services/authService';
import apiClient from '../services/apiClient';
import { useQueryClient } from '@tanstack/react-query';

import { 
  connectWebSocket, 
  disconnectWebSocket, 
  subscribeToRoom, 
  unsubscribeFromRoom,
  isConnected
} from '../services/websocketService';

import { logDebug } from '../services/debugService';

const debugRoom = (message, data = {}) => {
  logDebug('RoomContext', message, data);
};

const RoomContext = createContext(null);

export const ROOM_TYPES = {
  DEMOCRACY: 'Democracy',
  REPUBLIC: 'Republic',
  DICTATORSHIP: 'Dictatorship',
  MONARCHY: 'Monarchy',
  RANDOM: 'Random'
};

export function useRoom() {
  const context = useContext(RoomContext);
  if (!context) {
    throw new Error('useRoom must be used within a RoomProvider');
  }
  return context;
}

export function RoomProvider({ children }) {
  const { user } = useAuth();
  const roomQuery = useRoomQuery();
  const queryClient = useQueryClient();

  // WebSocket setup
  const handleWebSocketMessage = useCallback((message) => {
    debugRoom('WebSocket message received', { 
      topic: message?.topic,
      body: message?.body,
      headers: message?.headers
    });
    
    // Handle message from backend
    const messageBody = message?.body;
    if (messageBody?.userName === 'system') {
      switch (messageBody.event) {
        case 'room_update':
          try {
            // Parse the message content if it's a string
            const roomData = typeof messageBody.message === 'string' 
              ? JSON.parse(messageBody.message) 
              : messageBody.message;
              
            debugRoom('Received room update', { roomData });

            // Update room data in react-query cache
            queryClient.setQueryData(['room'], (oldData) => {
              if (!oldData) return roomData;

              // Merge the new data with existing data
              const updatedData = {
                ...oldData,
                ...roomData,
                // Preserve queue if not in update
                queue: roomData.queue || oldData.queue || [],
                // Preserve current track if not in update
                currentTrack: roomData.currentTrack || oldData.currentTrack,
                // Preserve user data if not in update
                currentUser: roomData.currentUser || oldData.currentUser,
                // Update room state
                room: {
                  ...oldData.room,
                  ...roomData.room
                }
              };

              debugRoom('Updated room data', { 
                oldData,
                newData: roomData,
                mergedData: updatedData
              });

              return updatedData;
            });

            // Trigger a background refetch to ensure consistency
            queryClient.invalidateQueries(['room']);
          } catch (error) {
            debugRoom('Error processing room update', { error });
          }
          break;

        case 'newsong':
          try {
            // Handle basic song change notification
            const [title, artist] = messageBody.message.split(' by ');
            debugRoom('Received new song notification', { title, artist });

            // Instead of partial updates, trigger a full room data refresh
            // This ensures we get complete track data including album art
            queryClient.invalidateQueries(['room']);
            
            // Still update the minimal info we have immediately for a responsive feel
            queryClient.setQueryData(['room'], (oldData) => {
              if (!oldData) return oldData;

              return {
                ...oldData,
                currentTrack: {
                  ...oldData.currentTrack,
                  track: {
                    ...oldData.currentTrack?.track,
                    title,
                    artist
                  }
                }
              };
            });
          } catch (error) {
            debugRoom('Error processing new song notification', { error });
          }
          break;

        default:
          debugRoom('Unknown event type', { event: messageBody.event });
      }
    }
  }, [queryClient]);

  // Setup WebSocket connection and room subscription
  useEffect(() => {
    if (!user || !roomQuery.data?.name) return;

    debugRoom('Setting up WebSocket connection', {
      userName: user.username,
      roomName: roomQuery.data.name,
      isConnected: isConnected()
    });

    // Connect WebSocket if not already connected
    if (!isConnected()) {
      connectWebSocket();
    }

    // Subscribe to room updates if not already subscribed
    const roomName = roomQuery.data.name;
    subscribeToRoom(roomName, handleWebSocketMessage);

    // Only clean up if we're actually unmounting or changing rooms
    return () => {
      debugRoom('Checking if cleanup needed', { roomName });
      // Don't cleanup if we're still in the same room
      if (roomName && roomName !== roomQuery.data?.name) {
        debugRoom('Cleaning up room subscription', { roomName });
        unsubscribeFromRoom(roomName);
      }
    };
  }, [user, roomQuery.data?.name, handleWebSocketMessage]);

  // Join room by name
  const joinRoomByName = useCallback(async (roomName) => {
    try {
      debugRoom('Joining room', { roomName });
      await roomQuery.joinRoom(roomName);
    } catch (error) {
      debugRoom('Failed to join room', { error: error.message });
      throw error;
    }
  }, [roomQuery.joinRoom]);

  // Play current track on Spotify
  const playOnSpotify = useCallback(async () => {
    try {
      const token = getToken();
      if (!token) {
        throw new Error('Authentication required');
      }
      const cleanToken = token.startsWith('Bearer ') ? token.substring(7) : token;
      window.location.href = `${BACKEND_URL}/spotifyRedirect?frontend_url=${encodeURIComponent(window.location.origin)}&token=${encodeURIComponent(cleanToken)}`;
    } catch (error) {
      debugRoom('Failed to initiate Spotify auth', { error: error.message });
      throw error;
    }
  }, []);

  // Handle track rating
  const handleRating = async (id, rating, type = 'track') => {
    try {
      const serverRating = Math.round(Math.max(0, Math.min(100, Number(rating) || 0)));
      debugRoom('Rating content', { id, rating, serverRating, type });
      
      // Get current track info
      const currentTrack = roomQuery.data?.currentTrack;
      debugRoom('Current track info', { 
        currentTrack,
        track: currentTrack?.track,
        artist: currentTrack?.track?.artist,
        albumKey: currentTrack?.track?.albumkey
      });

      // If no ID is provided, use the current track's ID
      const targetId = id || currentTrack?.viewTrackID;
      if (!targetId) {
        throw new Error('No track ID found');
      }

      switch (type) {
        case 'track':
          await roomQuery.rateTrack({ trackId: targetId, rating: serverRating });
          break;
        case 'album':
          await roomQuery.rateAlbum({ albumId: targetId, rating: serverRating });
          break;
        case 'artist':
          await roomQuery.rateArtist({ artistName: targetId, rating: serverRating });
          break;
        default:
          throw new Error(`Unknown rating type: ${type}`);
      }

      debugRoom('Content rated successfully', { id: targetId, rating: serverRating, type });
      return true; // Return success for optimistic updates
    } catch (error) {
      debugRoom('Failed to rate content', { error, rating, type });
      throw error; // Re-throw for optimistic update handling
    }
  };

  const value = {
    currentRoom: roomQuery.data,  
    queue: roomQuery.data?.queue || [],
    loading: roomQuery.isLoading,
    error: roomQuery.error,
    joinRoomByName,
    updateRoom: roomQuery.updateRoom,
    skipSong: roomQuery.skipSong,
    addToQueue: roomQuery.addToQueue,
    removeFromQueue: roomQuery.removeFromQueue,
    voteOnTrack: roomQuery.voteOnTrack,
    handleRating,
    playOnSpotify,
    refetchRoom: roomQuery.refetchRoom
  };

  // Debug room context value
  console.log('[RoomContext] Current value:', {
    hasData: !!roomQuery.data,
    data: roomQuery.data,
    isLoading: roomQuery.isLoading,
    error: roomQuery.error?.message,
    queue: roomQuery.data?.queue || [],
    currentRoom: roomQuery.data,
    currentTrack: roomQuery.data?.currentTrack,
    user: roomQuery.data?.currentUser,
    room: roomQuery.data?.room
  });

  // Debug room data updates
  useEffect(() => {
    if (roomQuery.data) {
      debugRoom('Room data updated', {
        roomData: roomQuery.data,
        hasQueue: !!roomQuery.data.queue,
        queueLength: roomQuery.data.queue?.length || 0,
        hasCurrentTrack: !!roomQuery.data.currentTrack,
        hasUser: !!roomQuery.data.currentUser,
        hasName: !!roomQuery.data.name,
        hasRatings: {
          artist: !!roomQuery.data.artistRating,
          album: !!roomQuery.data.albumRating
        }
      });
    }
  }, [roomQuery.data]);

  return (
    <RoomContext.Provider value={value}>
      {children}
    </RoomContext.Provider>
  );
}
