import apiClient from '../apiClient';

/**
 * Queue API service
 * Handles all queue-related API calls
 * Preparing for React Query migration
 */
export const queueApi = {
  /**
   * Fetch queue data for current room
   * @returns {Promise<Object>} Queue data
   * @throws {Error} If request fails
   */
  fetchQueues: async () => {
    try {
      console.log('🏠 Fetching queues...');
      const data = await apiClient.get('/room/queue');

      console.log('🏠 Queue data received:', { itemCount: data?.items?.length || 0 });

      if (data?.items?.[0]) {
        const firstItem = data.items[0];
        console.log('🎵 First queue item structure:', {
          lid: firstItem.lid,
          trackId: firstItem.viewTrackID,
          title: firstItem.title,
          artist: firstItem.artist,
          position: firstItem.position,
          rating: firstItem.rating,
          user: firstItem.user
        });
      }
      
      return data;
    } catch (error) {
      console.error('❌ Queue fetch error:', error);
      throw error;
    }
  },

  /**
   * Perform a queue action
   * @param {string} action - Queue action (queueMoveUp, queueMoveDown, queueRemove, queueMoveAllUp)
   * @param {number} lid - Library ID
   * @returns {Promise<Object>} Updated room queue data
   * @throws {Error} If request fails
   */
  performQueueAction: async (action, lid) => {
    try {
      if (!lid) {
        console.error(`❌ Queue action ${action} failed: No library ID provided`);
        throw new Error('Missing library ID for queue operation');
      }

      console.log(`🔄 Performing queue action: ${action} on library ID: ${lid}`);
      
      // Ensure lid is a number
      const numericLid = parseInt(lid, 10);
      if (isNaN(numericLid)) {
        throw new Error('Invalid library ID: must be a number');
      }

      let params;
      if (action === 'queueAdd') {
        // For queueAdd, we need to use the track's library item ID
        params = { libraryItem: numericLid, messageUsers: true };
      } else {
        // For other actions (moveUp, moveDown, etc), use lid
        params = { lid: numericLid };
      }
      
      // Log the request details
      console.log('Making API request:', {
        action,
        endpoint: `/room/${action}`,
        params
      });

      const response = await apiClient.get(`/room/${action}`, { params });
      console.log('API response:', response);
      
      // The response is now a RoomQueue object containing all users' queues
      return response;
    } catch (error) {
      console.error(`❌ Queue action ${action} failed:`, error);
      throw error;
    }
  },

  /**
   * Add a track to the queue
   * @param {number} trackId - Track ID of the track to add (despite parameter name 'libraryItem')
   * @returns {Promise<Object>} Updated queue data
   * @throws {Error} If request fails
   */
  queueAdd: async (trackId) => {
    try {
      console.log('QueueApi - Adding track with ID:', trackId, 'Type:', typeof trackId);
      
      // Handle various input types
      if (trackId === undefined || trackId === null || trackId === '') {
        throw new Error('Missing track ID for queue add operation');
      }

      // Ensure trackId is a number
      const numericTrackId = parseInt(String(trackId).trim(), 10);
      console.log('QueueApi - Parsed numeric track ID:', numericTrackId);
      
      if (isNaN(numericTrackId)) {
        throw new Error(`Invalid track ID: must be a number, received: ${trackId}`);
      }

      // IMPORTANT: The parameter name is misleadingly called 'libraryItem'
      // but the backend uses it to find a Track: trackService.findById(libraryItem)
      console.log('🔄 Performing queue action: queueAdd with track ID:', numericTrackId);
      const data = await apiClient.get('/room/queueAdd', {
        params: {
          libraryItem: numericTrackId,
          messageUsers: true
        }
      });
      return data;
    } catch (error) {
      console.error('❌ Queue action queueAdd failed:', error);
      throw error;
    }
  }
};
