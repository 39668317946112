import React, { memo, useState, useCallback } from 'react';
import { logDebug } from '../services/debugService';
import { getNumericRating } from '../utils/ratingUtils';

const debugRatingStars = (message, data = {}) => {
  // logDebug('RatingStars', message, data);
};

const Star = ({ selected, onClick, char = '★', size, isPulsing }) => (
  <span
    onClick={onClick}
    style={{
      cursor: onClick ? 'pointer' : 'default',
      color: selected ? '#FFD700' : '#404040',
      transition: 'color 200ms ease-in-out, transform 200ms ease-in-out',
      padding: size >= 32 ? '0 3px' : '0 2px',
      userSelect: 'none', 
      WebkitTapHighlightColor: 'transparent',
      display: 'inline-block',
      width: size >= 32 ? `${size * 1.1}px` : `${size}px`,
      textAlign: 'center',
      fontSize: `${size}px`,
      transform: isPulsing ? 'scale(1.2)' : 'scale(1)',
      animation: isPulsing ? 'starPulse 400ms ease-in-out' : 'none'
    }}
  >
    {char}
    <style>
      {`
        @keyframes starPulse {
          0% { transform: scale(1); }
          50% { transform: scale(1.2); }
          100% { transform: scale(1); }
        }
      `}
    </style>
  </span>
);

const StarRating = ({
  value = 0,
  onChange,
  count = 5,
  size = 24,
  readonly = false,
  char = '★',
  activeColor = '#FFD700'
}) => {
  const [pulsingStars, setPulsingStars] = useState(new Set());
  const [optimisticValue, setOptimisticValue] = useState(null);

  const handleClick = useCallback((starCount) => {
    debugRatingStars('Star clicked', { starCount, readonly });
    if (!readonly && onChange) {
      // Set optimistic value
      setOptimisticValue(starCount);
      
      // Create pulsing effect
      const newPulsingStars = new Set();
      for (let i = 1; i <= starCount; i++) {
        newPulsingStars.add(i);
      }
      setPulsingStars(newPulsingStars);

      // Clear pulsing effect after animation
      setTimeout(() => {
        setPulsingStars(new Set());
      }, 400);

      // Convert star count to numeric rating using our scale
      const numericRating = getNumericRating(starCount);
      debugRatingStars('Converting star rating to numeric', { starCount, numericRating });

      // Call the actual onChange handler with numeric rating
      onChange(numericRating).catch(() => {
        // If there's an error, revert the optimistic value
        setOptimisticValue(null);
      });
    }
  }, [onChange, readonly]);

  // Use optimistic value if available, otherwise use the prop value
  const displayValue = optimisticValue !== null ? optimisticValue : value;
  const validValue = Math.max(0, Math.min(5, Number(displayValue) || 0));

  debugRatingStars('Rendering stars', { 
    rawValue: value,
    displayValue,
    validValue,
    isOptimistic: optimisticValue !== null
  });

  const stars = Array.from({ length: count }, (_, index) => index + 1);

  return (
    <div 
      style={{ 
        lineHeight: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: size >= 32 ? '8px 0' : '6px 0',
        minHeight: `${size * 1.3}px`
      }}
    >
      {stars.map((star) => (
        <Star
          key={star}
          selected={star <= validValue}
          onClick={readonly ? undefined : () => handleClick(star)}
          char={char}
          size={size}
          isPulsing={pulsingStars.has(star)}
        />
      ))}
    </div>
  );
};

export const RatingStars = memo(({
  rating = 0,
  onChange,
  size = 24,
  readonly = false
}) => {
  debugRatingStars('Rendering with props', { rating, size, readonly });
  
  return (
    <StarRating
      value={rating}
      onChange={onChange}
      size={size}
      readonly={readonly}
    />
  );
});
