import { useState, useCallback } from 'react';
import { searchApi } from '../../services/api/searchApi';

export function useArtistDetails() {
  const [selectedArtist, setSelectedArtist] = useState(null);
  const [albums, setAlbums] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const loadArtistDetails = useCallback(async (artist) => {
    if (!artist) return;
    
    console.log('Loading artist details:', artist);
    
    // If it's the same artist, don't reload
    if (selectedArtist?.name === artist.name) {
      console.log('Artist already selected, skipping reload');
      return;
    }
    
    setIsLoading(true);
    setError(null);

    try {
      const artistDetails = await searchApi.searchArtist(artist.name || artist.artist);
      console.log('Artist details:', artistDetails);
      
      if (artistDetails) {
        setSelectedArtist({
          type: 'artist',
          name: artistDetails.artist,
          artist: artistDetails.artist,
          icon: artistDetails.icon,
          baseicon: artistDetails.icon,
          artistRating: artistDetails.artistRating
        });
        
        // Ensure we have albums array and process metadata
        const artistAlbums = artistDetails.albums || [];
        console.log('Raw album data:', artistAlbums);
        
        // Map albums to include all metadata
        const processedAlbums = artistAlbums.map(album => ({
          ...album,
          type: 'album',
          id: album.id,
          name: album.name,
          artist: album.artist,
          baseicon: album.baseicon,
          releasedate: album.releasedate,
          displaydate: album.displaydate,
          isexplicit: album.isexplicit,
          isclean: album.isclean,
          iscompliation: album.isCompilation,
          length: album.length,
          album_rating: album.album_rating,
          albumKey: album.rdioKey
        }));
        
        console.log('Processed albums with metadata:', processedAlbums);
        setAlbums(processedAlbums);
      }
    } catch (err) {
      console.error('Failed to fetch artist details:', err);
      setError(err.message || 'Failed to fetch artist details. Please try again.');
      setAlbums([]);
    } finally {
      setIsLoading(false);
    }
  }, [selectedArtist]);

  const reset = useCallback(() => {
    setSelectedArtist(null);
    setAlbums([]);
    setError(null);
  }, []);

  return {
    selectedArtist,
    albums,
    isLoading,
    error,
    loadArtistDetails,
    reset
  };
}
