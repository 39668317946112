import { useState } from 'react';
import { useRoom } from '../../../queries/useRoom';
import { BACKEND_URL } from '../../../services/authService';
import { roomApi } from '../../../services/api/roomApi';

export default function QueueSettings() {
  const { refetchRoom } = useRoom();
  const [isClearing, setIsClearing] = useState(false);
  const [isResetting, setIsResetting] = useState(false);

  const handleClearQueue = async () => {
    if (isClearing) return;
    
    setIsClearing(true);
    try {
      // Clear the queue
      const clearResponse = await fetch(`${BACKEND_URL}/room/clearall`, {
        method: 'POST',
        credentials: 'include'
      });
      if (!clearResponse.ok) {
        throw new Error('Failed to clear queue');
      }

      // Fetch updated room state
      await refetchRoom();
    } catch (error) {
      console.error('Error clearing queue:', error);
    } finally {
      setIsClearing(false);
    }
  };

  const handleResetQueue = async () => {
    if (isResetting) return;
    
    setIsResetting(true);
    try {
      const response = await fetch(`${BACKEND_URL}/room/clearallreset`, {
        method: 'POST',
        credentials: 'include'
      });
      if (!response.ok) {
        throw new Error('Failed to reset queue');
      }
      // Force refresh room state
      await refetchRoom();
    } catch (error) {
      console.error('Error resetting queue:', error);
    } finally {
      setIsResetting(false);
    }
  };

  return (
    <div className="space-y-4">
      <h3 className="text-lg font-medium text-white">Queue Management</h3>
      <div className="space-y-4">
        <div className="space-y-2">
          <button
            className="inline-flex items-center justify-center rounded px-4 py-2 text-sm font-medium bg-red-900 text-white hover:bg-red-800 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed"
            onClick={handleClearQueue}
            disabled={isClearing}
          >
            {isClearing ? 'Clearing...' : 'Clear Queue'}
          </button>
          <p className="text-xs text-gray-400">
            Remove all songs from the current queue
          </p>
        </div>

        <div className="space-y-2">
          <button
            className="inline-flex items-center justify-center rounded px-4 py-2 text-sm font-medium bg-yellow-900 text-white hover:bg-yellow-800 focus:outline-none focus-visible:ring-2 focus-visible:ring-yellow-500 focus-visible:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed"
            onClick={handleResetQueue}
            disabled={isResetting}
          >
            {isResetting ? 'Resetting...' : 'Clear All Q RST'}
          </button>
          <p className="text-xs text-gray-400">
            Clear queue and reseed with the currently playing track
          </p>
        </div>
      </div>
    </div>
  );
}
