import { useState, useEffect } from 'react';
import * as RadioGroup from '@radix-ui/react-radio-group';
import { useRoom as useRoomQuery } from '../../../queries/useRoom';
import { FRONTEND_URL, BACKEND_URL } from '../../../services/authService';

// Environment-specific configuration
const isDevEnvironment = import.meta.env.DEV || import.meta.env.MODE === 'development' || (FRONTEND_URL && FRONTEND_URL.includes('localhost'));

const ROOM_MODES = {
  REPUBLIC: 'REPUBLIC',
  DEMOCRACY: 'DEMOCRACY',
  MONARCHY: 'MONARCHY',
  DICTATORSHIP: 'DICTATORSHIP',
  RANDOM: 'RANDOM'
};

const ROOM_MODE_LABELS = {
  [ROOM_MODES.REPUBLIC]: 'Republic',
  [ROOM_MODES.DEMOCRACY]: 'Democracy',
  [ROOM_MODES.MONARCHY]: 'Monarchy',
  [ROOM_MODES.DICTATORSHIP]: 'Dictatorship',
  [ROOM_MODES.RANDOM]: 'Random'
};

// Map from string mode to numeric mode for the UI
const MODE_TO_NUMBER = {
  [ROOM_MODES.REPUBLIC]: 0,
  [ROOM_MODES.DEMOCRACY]: 1,
  [ROOM_MODES.MONARCHY]: 2,
  [ROOM_MODES.DICTATORSHIP]: 3,
  [ROOM_MODES.RANDOM]: 4
};

// Map from numeric mode to string mode for the API
const NUMBER_TO_MODE = {
  0: ROOM_MODES.REPUBLIC,
  1: ROOM_MODES.DEMOCRACY,
  2: ROOM_MODES.MONARCHY,
  3: ROOM_MODES.DICTATORSHIP,
  4: ROOM_MODES.RANDOM
};

export default function RoomModeSettings() {
  const { currentRoom, toggleRoomType } = useRoomQuery();
  const [roomMode, setRoomMode] = useState(MODE_TO_NUMBER[ROOM_MODES.DEMOCRACY]); // Set default value directly

  useEffect(() => {
    // Only update if currentRoom exists and has a roomtype
    if (currentRoom?.roomtype !== undefined) {
      console.log('Setting room mode to:', currentRoom.roomtype, 'roomTypeAsString:', currentRoom.roomTypeAsString);
      // Convert from backend's numeric type to our string enum
      const currentRoomType = currentRoom.roomTypeAsString?.toUpperCase() || ROOM_MODES.DEMOCRACY;
      setRoomMode(MODE_TO_NUMBER[currentRoomType] ?? MODE_TO_NUMBER[ROOM_MODES.DEMOCRACY]);
    }
  }, [currentRoom]);

  const handleRoomModeChange = async (value) => {
    const numericValue = parseInt(value);
    const stringMode = NUMBER_TO_MODE[numericValue];
    console.log('Changing room mode to:', stringMode);
    try {
      // Toggle the room type using the mutation
      await toggleRoomType();
    } catch (error) {
      console.error('Error updating room mode:', error);
      throw error;
    }
  };

  return (
    <div className="space-y-4">
      <h3 className="text-lg font-medium text-white">Room Mode</h3>
      <div className="p-4 rounded-lg bg-[#2E2E2E] border border-gray-700">
        <RadioGroup.Root
          className="flex flex-col gap-3"
          value={roomMode?.toString() ?? MODE_TO_NUMBER[ROOM_MODES.DEMOCRACY].toString()}
          onValueChange={handleRoomModeChange}
          defaultValue={MODE_TO_NUMBER[ROOM_MODES.DEMOCRACY].toString()}
        >
          {Object.entries(MODE_TO_NUMBER).map(([mode, value]) => (
            <div className="flex items-center" key={mode}>
              <RadioGroup.Item
                className="w-[20px] h-[20px] rounded-full border border-gray-600 hover:border-gray-400 bg-[#3E3E3E] data-[state=checked]:border-white data-[state=checked]:bg-white"
                value={value.toString()}
                id={`r${value}`}
              >
                <RadioGroup.Indicator className="flex items-center justify-center w-full h-full relative after:content-[''] after:block after:w-[8px] after:h-[8px] after:rounded-full after:bg-[#3E3E3E]" />
              </RadioGroup.Item>
              <label className="pl-2 text-sm text-gray-300 cursor-pointer select-none" htmlFor={`r${value}`}>
                {ROOM_MODE_LABELS[mode]} Mode
              </label>
            </div>
          ))}
        </RadioGroup.Root>
        <p className="mt-2 text-xs text-gray-400">Choose how songs are selected from the queue</p>
      </div>
    </div>
  );
}
