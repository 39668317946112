export const BACKEND_URL = import.meta.env.VITE_BACKEND_URL;

// List of components that should never send logs to backend
const LOCAL_ONLY_COMPONENTS = [
  'App',
  'AuthWrapper',
  'AuthContext'
];

/**
 * Send debug information to the backend and console
 * @param {string} component - Component or service name
 * @param {string} message - Debug message
 * @param {Object} data - Additional debug data
 */
export const logDebug = async (component, message, data = {}) => {
  try {
    // Convert message to string if it's not already
    const messageStr = typeof message === 'string' ? message : JSON.stringify(message);

    // Always log to console - using console.log instead of console.debug for production consistency
    console.log(`[${component}] ${messageStr}`, data);

    // Don't send to backend if component is in local-only list
    if (LOCAL_ONLY_COMPONENTS.includes(component)) {
      return;
    }

    // Only send errors to backend to avoid overloading
    if (!messageStr.toLowerCase().includes('error')) {
      return;
    }

    const debugInfo = {
      component,
      message: messageStr,
      data,
      timestamp: new Date().toISOString(),
      url: window.location.href
    };

    await fetch(`${BACKEND_URL}/api/debug/client-log`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(debugInfo),
      credentials: 'include'
    });
  } catch (error) {
    console.error('Failed to send debug info:', error);
  }
};
