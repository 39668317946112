import React from 'react';

export function ErrorDisplay({ error }) {
  if (!error) return null;

  // Convert error object to string message
  let errorMessage = error;
  if (error instanceof Error) {
    errorMessage = error.message;
  } else if (typeof error === 'object') {
    errorMessage = 'An error occurred. Please try again.';
  }

  // Make network error messages more user-friendly
  if (errorMessage.includes('NetworkError') || errorMessage.includes('Failed to fetch')) {
    errorMessage = 'Unable to connect to server. Please check your connection and try again.';
  }

  return (
    <div className="bg-red-500 text-white p-4 rounded-lg mb-4">
      <p className="font-medium">{errorMessage}</p>
    </div>
  );
}
