import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { RatingStars } from './RatingStars';
import apiClient from '../services/apiClient';
import { getStarCount } from '../utils/ratingUtils';

interface ArtistRating {
  artistname: string;
  rating: number;
  numOfRatingStars: number;
  timerated: string;
}

interface ArtistRatingResultSet {
  recommendedArtists: ArtistRating[];
}

export const ArtistRecommendations = () => {
  const { data, isLoading, error, refetch } = useQuery({
    queryKey: ['artistRecommendations'],
    queryFn: async () => {
      console.log('Fetching artist recommendations...');
      const response = await apiClient.get<ArtistRatingResultSet>('/room/retrieveArtistRecommendationsRaw');
      console.log('Received artist recommendations:', response.data);
      return response.data;
    }
  });

  const handleArtistClick = async (artistName: string) => {
    try {
      await apiClient.get('/room/artistjson', {
        params: { artistName }
      });
    } catch (error) {
      console.error('Error fetching artist details:', error);
    }
  };

  const handleRatingChange = async (artistName: string, rating: number) => {
    try {
      // Rating from RatingStars is already in numeric form (0-100)
      console.log('Rating artist:', artistName, 'with rating:', rating);
      await apiClient.get('/room/rateArtist', {
        params: {
          rate: rating,
          artist_name: artistName
        }
      });
      // Refetch recommendations to get updated ratings
      await refetch();
    } catch (error) {
      console.error('Error updating artist rating:', error);
    }
  };

  if (isLoading) {
    return <div className="text-base font-medium text-gray-400 animate-pulse">Loading artist recommendations...</div>;
  }

  if (error) {
    console.error('Error loading artist recommendations:', error);
    return <div className="text-base font-medium text-red-500">Error loading artist recommendations</div>;
  }

  if (!data?.recommendedArtists?.length) {
    return <div className="text-base font-medium text-gray-400">No artist recommendations available</div>;
  }

  return (
    <div className="bg-[#1A1A1A] w-full">
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-6 gap-4 p-6">
        {data.recommendedArtists.slice(0, 24).map((artist) => (
          <div
            key={artist.artistname}
            className="flex flex-col gap-2 px-3 py-3 bg-black/60 rounded-lg hover:bg-black/80"
          >
            <span
              onClick={() => handleArtistClick(artist.artistname)}
              className="text-base font-medium text-center w-full cursor-pointer hover:text-blue-400 transition-colors"
              title={artist.artistname}
            >
              {artist.artistname}
            </span>
            <div className="flex justify-center w-full">
              <RatingStars
                rating={getStarCount(artist.rating)}
                onChange={(newRating) => handleRatingChange(artist.artistname, newRating)}
                size={28}
                // @ts-ignore - RatingStars is a JavaScript component
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
