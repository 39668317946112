import SockJS from 'sockjs-client';
import { Client } from '@stomp/stompjs';
import { BACKEND_URL } from './authService';
import { logDebug } from './debugService';

// Constants
const HEARTBEAT_INTERVAL = 20000; // 20 seconds
const SUBSCRIPTION_DELAY = 1000; // 1 second
const MAX_RECONNECT_ATTEMPTS = 10;
const RECONNECT_BASE_DELAY = 2000; // 2 seconds
const RECONNECT_MAX_DELAY = 30000; // 30 seconds
const ACTIVITY_CHECK_INTERVAL = 60000; // 1 minute
const INACTIVITY_THRESHOLD = 5 * 60 * 1000; // 5 minutes
const ROOM_INACTIVITY_TIMEOUT = 30 * 60 * 1000; // 30 minutes in milliseconds
const MOBILE_BACKGROUND_GRACE_PERIOD = 5 * 60 * 1000; // 5 minutes grace period for mobile background

// Debug utility for WebSocket-related logs
const debugWebSocket = (message, data = {}) => {
  // Always log to console immediately for debugging
  console.log(`[WebSocket] ${message}`, {
    timestamp: new Date().toISOString(),
    ...data
  });
  // Also log through debug service
  logDebug('WebSocket', message, data);
};

let stompClient = null;
let currentUsername = null;
let reconnectAttempts = 0;
let reconnectTimeout = null;
let subscriptions = new Map();
let lastActivityTime = Date.now();
let lastBackgroundTime = null;
let activityCheckInterval = null;
let isMobileDevice = false;
let usernameChangeCallbacks = [];

const notifyUsernameChange = (username) => {
  usernameChangeCallbacks.forEach(callback => callback(username));
};

// Detect if user is on a mobile device
const detectMobileDevice = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  isMobileDevice = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent.toLowerCase());
  debugWebSocket('Device type detection', { isMobileDevice });
  return isMobileDevice;
};

const updateActivity = () => {
  lastActivityTime = Date.now();
  // Clear background time if it was set
  if (lastBackgroundTime) {
    lastBackgroundTime = null;
  }
};

const handleVisibilityChange = (roomId, onUpdate) => {
  if (document.hidden) {
    lastBackgroundTime = Date.now();
    debugWebSocket('Application went to background', { 
      timestamp: lastBackgroundTime,
      isMobileDevice 
    });
  } else {
    const wasInBackground = lastBackgroundTime !== null;
    const backgroundDuration = wasInBackground ? Date.now() - lastBackgroundTime : 0;
    
    debugWebSocket('Application returned from background', {
      backgroundDuration,
      wasInBackground,
      isMobileDevice
    });

    // For mobile devices, if the background duration was less than the grace period,
    // we consider this a temporary interruption (like a phone call or notification)
    if (isMobileDevice && backgroundDuration < MOBILE_BACKGROUND_GRACE_PERIOD) {
      updateActivity(); // Reset inactivity timer
    }

    // Always refresh room state when returning to foreground
    onUpdate({ 
      type: 'refresh',
      metadata: {
        backgroundDuration,
        wasInBackground,
        isMobileDevice
      }
    });
    
    lastBackgroundTime = null;
  }
};

const startActivityCheck = (roomId) => {
  if (activityCheckInterval) {
    clearInterval(activityCheckInterval);
  }
  
  activityCheckInterval = setInterval(() => {
    const inactiveTime = Date.now() - lastActivityTime;
    const isInBackground = document.hidden;
    
    // For mobile devices in background, we're more lenient with the timeout
    // Only disconnect if we've been in background longer than the grace period
    if (isInBackground && isMobileDevice && lastBackgroundTime) {
      const backgroundTime = Date.now() - lastBackgroundTime;
      if (backgroundTime < MOBILE_BACKGROUND_GRACE_PERIOD) {
        return; // Still within grace period
      }
    }
    
    if (inactiveTime >= ROOM_INACTIVITY_TIMEOUT) {
      debugWebSocket('User inactive timeout reached', { 
        roomId,
        inactiveTime,
        isInBackground,
        isMobileDevice
      });
      unsubscribeFromRoom(roomId);
      clearInterval(activityCheckInterval);
    }
  }, ACTIVITY_CHECK_INTERVAL); // Check every minute
};

const getReconnectDelay = () => {
  // Exponential backoff with jitter and max delay
  const baseDelay = Math.min(
    RECONNECT_BASE_DELAY * Math.pow(2, reconnectAttempts),
    RECONNECT_MAX_DELAY
  );
  return baseDelay + (Math.random() * 1000); // Add up to 1 second of jitter
};

const cleanupWebSocket = () => {
  if (!stompClient) {
    debugWebSocket('No STOMP client to clean up');
    return;
  }

  debugWebSocket('Cleaning up WebSocket connection', { 
    connected: stompClient?.connected,
    subscriptionCount: subscriptions.size
  });

  // Clear any pending reconnect timeout
  if (reconnectTimeout) {
    clearTimeout(reconnectTimeout);
    reconnectTimeout = null;
  }

  // Store existing subscriptions and callbacks
  const activeSubscriptions = new Map();
  
  // Clean up current subscriptions while preserving active ones
  subscriptions.forEach((subscription, topic) => {
    try {
      if (subscription.subscription) {
        // Only unsubscribe if the subscription is pending or disconnected
        if (subscription.pending || !subscription.subscription.connected) {
          subscription.subscription.unsubscribe();
          // Store the callback for reconnection
          if (subscription.callback) {
            activeSubscriptions.set(topic, { 
              callback: subscription.callback,
              pending: true 
            });
          }
        } else {
          // Keep active subscriptions as is
          activeSubscriptions.set(topic, subscription);
        }
      } else if (subscription.callback) {
        // Store callbacks for pending subscriptions
        activeSubscriptions.set(topic, { 
          callback: subscription.callback,
          pending: true 
        });
      }
    } catch (error) {
      debugWebSocket('Error unsubscribing', { error });
      // On error, preserve the subscription state
      if (subscription.callback) {
        activeSubscriptions.set(topic, subscription);
      }
    }
  });

  // Disconnect client
  try {
    if (stompClient?.connected) {
      stompClient.deactivate();
    }
  } catch (error) {
    debugWebSocket('Error cleaning up WebSocket', { error });
  }
  stompClient = null;

  // Restore subscriptions with preserved callbacks
  subscriptions = activeSubscriptions;
  
  debugWebSocket('Cleanup complete', { 
    preservedCallbacks: activeSubscriptions.size
  });
};

const setupSubscriptions = async () => {
  if (!stompClient?.connected) {
    debugWebSocket('Cannot setup subscriptions - no connected STOMP client');
    return;
  }

  debugWebSocket('Setting up subscriptions', { 
    subscriptionCount: subscriptions.size,
    topics: Array.from(subscriptions.keys())
  });

  // Create a copy of the subscriptions to avoid modification during iteration
  const currentSubscriptions = new Map(subscriptions);
  
  for (const [topic, subscription] of currentSubscriptions) {
    try {
      await new Promise(resolve => setTimeout(resolve, SUBSCRIPTION_DELAY));
      
      if (!subscription.callback) {
        debugWebSocket('No callback found for subscription', { topic });
        continue;
      }

      debugWebSocket('Setting up subscription', { 
        topic,
        hasCallback: !!subscription.callback
      });

      const newSubscription = stompClient.subscribe(topic, (message) => {
        try {
          const messageBody = message.body ? JSON.parse(message.body) : null;
          debugWebSocket('Received message', { 
            topic,
            headers: message.headers,
            body: messageBody,
            rawBody: message.body // Log the raw message body
          });
          
          if (subscription.callback) {
            subscription.callback({
              topic,
              headers: message.headers,
              body: messageBody,
              rawBody: message.body // Pass the full message object to the callback
            });
          } else {
            debugWebSocket('No callback available for message', { topic });
          }
        } catch (error) {
          debugWebSocket('Error processing message', { topic, error, rawBody: message.body });
        }
      });
      
      // Update the subscription in the main map
      subscriptions.set(topic, {
        ...subscription,
        subscription: newSubscription,
        pending: false
      });

      debugWebSocket('Subscription created', { 
        topic,
        subscriptionId: newSubscription.id,
        hasCallback: !!subscription.callback
      });
    } catch (error) {
      debugWebSocket('Error setting up subscription', { topic, error });
    }
  }
};

const isAuthenticated = () => {
  try {
    debugWebSocket('Checking auth status');
    return true; // Always return true since we're using session-based auth
  } catch (error) {
    debugWebSocket('Error checking auth status', { error });
    return false;
  }
};

const getUsernameFromStorage = () => {
  try {
    // First try localStorage
    const localData = localStorage.getItem('userData');
    if (localData) {
      const user = JSON.parse(localData);
      return user.username || user.login;
    }

    // Fallback to sessionStorage
    const sessionData = sessionStorage.getItem('userData');
    if (sessionData) {
      const user = JSON.parse(sessionData);
      return user.username || user.login;
    }
  } catch (error) {
    debugWebSocket('Error getting username from storage', { error });
  }
  return null;
};

export const connectWebSocket = () => {
  const authStatus = isAuthenticated();
  const username = getUsernameFromStorage();
  
  debugWebSocket('Checking auth status before connect', { 
    authenticated: authStatus,
    username
  });

  if (!authStatus) {
    debugWebSocket('Cannot connect - not authenticated');
    return;
  }

  if (!username) {
    debugWebSocket('Cannot connect - no username found');
    return;
  }

  if (stompClient?.connected) {
    debugWebSocket('WebSocket already connected');
    setupSubscriptions(); // Try to setup subscriptions if already connected
    return;
  }

  // If we're already trying to connect, don't start another connection
  if (stompClient) {
    debugWebSocket('Connection attempt already in progress');
    return;
  }

  debugWebSocket('Initializing WebSocket connection', {
    backendUrl: BACKEND_URL,
    endpoint: `${BACKEND_URL}/websocket/auricle-websocket`,
    username
  });

  try {
    // Create a new SockJS instance with error handling
    const socket = new SockJS(`${BACKEND_URL}/websocket/auricle-websocket`);
    
    socket.onopen = () => {
      debugWebSocket('SockJS connection opened');
      updateActivity(); // Track connection as activity
    };
    
    socket.onclose = (event) => {
      debugWebSocket('SockJS connection closed', { 
        code: event.code,
        reason: event.reason,
        wasClean: event.wasClean
      });
    };
    
    socket.onerror = (error) => {
      debugWebSocket('SockJS connection error', { error });
    };

    stompClient = new Client({
      webSocketFactory: () => socket,
      connectHeaders: {
        'user-name': username
      },
      debug: (str) => {
        // Log all STOMP debug messages
        debugWebSocket('STOMP Debug', { message: str });
      },
      reconnectDelay: getReconnectDelay,
      heartbeatIncoming: HEARTBEAT_INTERVAL,
      heartbeatOutgoing: HEARTBEAT_INTERVAL,
      onConnect: (frame) => {
        debugWebSocket('WebSocket connected successfully', { 
          connected: true,
          subscriptionCount: subscriptions.size,
          frame
        });
        
        updateActivity(); // Track successful connection as activity
        
        // Set username immediately
        currentUsername = username;
        debugWebSocket('Setting WebSocket username', { username: currentUsername });
        notifyUsernameChange(username);
        
        reconnectAttempts = 0;
        
        // Wait a bit longer for the connection to fully establish
        setTimeout(() => {
          if (stompClient?.connected) {
            setupSubscriptions();
          } else {
            debugWebSocket('Connection not ready for subscriptions');
          }
        }, SUBSCRIPTION_DELAY * 2);
      },
      onStompError: (frame) => {
        debugWebSocket('STOMP error', { 
          frame,
          connected: stompClient?.connected,
          subscriptionCount: subscriptions.size
        });
        cleanupWebSocket();
      },
      onWebSocketClose: () => {
        debugWebSocket('WebSocket closed', {
          wasConnected: stompClient?.connected,
          attempts: reconnectAttempts,
          subscriptionCount: subscriptions.size
        });
        
        // Always try to reconnect, no max attempts
        debugWebSocket('Attempting to reconnect', {
          attempts: reconnectAttempts
        });
        reconnectTimeout = setTimeout(connectWebSocket, getReconnectDelay());
      }
    });

    debugWebSocket('Activating STOMP client');
    stompClient.activate();
  } catch (error) {
    debugWebSocket('Error activating STOMP client', { 
      error,
      errorMessage: error.message,
      stack: error.stack
    });
    cleanupWebSocket();
  }
};

export const subscribeToRoom = (roomId, onUpdate) => {
  if (!isAuthenticated()) {
    debugWebSocket('Cannot subscribe - not authenticated');
    return;
  }

  // Detect mobile device on subscription
  detectMobileDevice();

  const roomTopic = `/topic/${roomId}`;
  
  debugWebSocket('Setting up room subscription', { 
    roomId,
    topic: roomTopic,
    connected: stompClient?.connected,
    hasClient: !!stompClient,
    hasCallback: !!onUpdate,
    isMobileDevice
  });

  if (!onUpdate) {
    debugWebSocket('No callback provided for room subscription');
    return;
  }

  // Always update the callback and mark as pending
  const existingSubscription = subscriptions.get(roomTopic) || {};
  
  // Only update if not already subscribed or subscription is pending
  if (!existingSubscription.subscription || existingSubscription.pending) {
    subscriptions.set(roomTopic, {
      ...existingSubscription,
      callback: onUpdate,
      pending: true
    });

    // Create new connection if needed
    if (!stompClient) {
      debugWebSocket('No STOMP client - creating new connection');
      connectWebSocket();
      return; // setupSubscriptions will be called after connection
    }

    // If client is connected, set up subscription immediately
    if (stompClient?.connected) {
      setupSubscriptions();
    } else {
      debugWebSocket('Client not connected - subscription will be set up on connect');
      // Try to reconnect if not connected
      if (!stompClient?.active) {
        connectWebSocket();
      }
    }
  } else {
    debugWebSocket('Room already subscribed', {
      topic: roomTopic,
      subscriptionId: existingSubscription.subscription.id
    });
  }

  // Start activity monitoring
  startActivityCheck(roomId);
  
  // Add activity listeners
  window.addEventListener('mousemove', updateActivity);
  window.addEventListener('keydown', updateActivity);
  window.addEventListener('click', updateActivity);
  window.addEventListener('scroll', updateActivity);
  window.addEventListener('touchstart', updateActivity);
  window.addEventListener('touchmove', updateActivity);
  
  // Add mobile-specific events
  if (isMobileDevice) {
    // Handle orientation changes as potential activity
    window.addEventListener('orientationchange', updateActivity);
    // Handle mobile-specific focus events
    window.addEventListener('focus', updateActivity);
    window.addEventListener('blur', () => {
      lastBackgroundTime = Date.now();
    });
  }

  // Enhanced visibility change handler
  window.addEventListener('visibilitychange', () => handleVisibilityChange(roomId, onUpdate));
};

export const unsubscribeFromRoom = (roomId) => {
  const roomTopic = `/topic/${roomId}`;
  const subscription = subscriptions.get(roomTopic);

  debugWebSocket('Unsubscribing from room', { 
    roomId,
    topic: roomTopic,
    hasSubscription: !!subscription
  });

  if (subscription?.subscription) {
    try {
      subscription.subscription.unsubscribe();
      debugWebSocket('Successfully unsubscribed', {
        topic: roomTopic,
        subscriptionId: subscription.subscription.id
      });
    } catch (error) {
      debugWebSocket('Error unsubscribing', { error });
    }
  }

  subscriptions.delete(roomTopic);
  
  // Clean up activity monitoring
  if (activityCheckInterval) {
    clearInterval(activityCheckInterval);
    activityCheckInterval = null;
  }
  
  // Remove activity listeners
  window.removeEventListener('mousemove', updateActivity);
  window.removeEventListener('keydown', updateActivity);
  window.removeEventListener('click', updateActivity);
  window.removeEventListener('scroll', updateActivity);
  window.removeEventListener('touchstart', updateActivity);
  window.removeEventListener('touchmove', updateActivity);
  
  // Remove mobile-specific events if they were added
  if (isMobileDevice) {
    window.removeEventListener('orientationchange', updateActivity);
    window.removeEventListener('focus', updateActivity);
    window.removeEventListener('blur', () => {});
  }
};

export const disconnectWebSocket = () => {
  cleanupWebSocket();
};

export const isConnected = () => {
  return stompClient?.connected || false;
};

export const getStompClient = () => stompClient;

export const getCurrentUsername = () => {
  debugWebSocket('Getting current username', { username: currentUsername });
  return currentUsername;
};

export const onUsernameChange = (callback) => {
  usernameChangeCallbacks.push(callback);
  return () => usernameChangeCallbacks.splice(usernameChangeCallbacks.indexOf(callback), 1);
};
