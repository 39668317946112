import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { roomApi } from '../services/api/roomApi';
import { logDebug } from '../services/debugService';
import { useEffect, useCallback } from 'react';
import { subscribeToRoom, unsubscribeFromRoom } from '../services/websocketService';
import { getStarCount } from '../utils/ratingUtils';

const debugUseRoom = (message, data = {}) => {
  logDebug('useRoom', message, data);
};

/**
 * Custom hook for managing room state using react-query and websockets
 */
export function useRoom() {
  const queryClient = useQueryClient();

  // Join room mutation
  const joinRoomMutation = useMutation({
    mutationFn: roomApi.joinRoom,
    onSuccess: (data) => {
      debugUseRoom('Successfully joined room', { data });
      
      // Update room data immediately
      queryClient.setQueryData(['room'], data);
      
      // Trigger a background refetch to ensure we have the latest data
      queryClient.invalidateQueries(['room']);
    },
    onError: (error) => {
      debugUseRoom('Failed to join room', { error });
    }
  });

  // Fetch room data
  const roomQuery = useQuery({
    queryKey: ['room'],
    queryFn: async () => {
      const data = await roomApi.fetchRoomData();
      debugUseRoom('Received room data from API', { 
        rawData: JSON.stringify(data, null, 2),
        hasData: !!data,
        hasRoom: !!data?.room,
        hasUser: !!data?.user,
        hasQueue: !!data?.room?.queue?.length
      });
      
      // Validate data structure
      if (!data) {
        debugUseRoom('No data received from API');
        throw new Error('No room data received');
      }

      // Transform UserRoomView into the expected structure
      const transformedData = {
        // Core room properties
        name: data.room?.name,
        roomType: data.room?.roomTypeAsString,
        roomtype: data.room?.roomtype, // Numeric room type for backend
        banner: data.room?.banner,
        minimumRating: data.room?.roomminimumrating,
        position: data.room?.position,
        isOwner: data.room?.isowner,
        tokens: data.room?.tokens,
        numOfUsersInRoom: data.room?.numOfUsersInRoom,
        roomMode: data.room?.roommode,
        trackStartTime: data.room?.trackstarttime,
        christmasMode: data.room?.christmasMode,

        // Room settings
        roomAgeAdjustment: data.room?.roomageadjustment ?? 50,
        roomFlowAdjustment: data.room?.roomflowadjustment ?? 50,
        roomRatingAdjustment: data.room?.roomratingadjustment ?? 50,
        roomTypeAsString: data.room?.roomTypeAsString,

        // User and ratings
        currentUser: data.user,
        artistRating: (() => {
          const stars = data.artistRating?.rating ? getStarCount(data.artistRating.rating) : 0;
          debugUseRoom('Calculated artist rating stars', {
            rating: data.artistRating?.rating,
            stars,
            artistRating: data.artistRating
          });
          return {
            ...data.artistRating,
            rating: stars
          };
        })(),
        albumRating: (() => {
          const stars = data.albumRating?.rating ? getStarCount(data.albumRating.rating) : 0;
          debugUseRoom('Calculated album rating stars', {
            rating: data.albumRating?.rating,
            stars,
            albumRating: data.albumRating
          });
          return {
            ...data.albumRating,
            rating: stars
          };
        })(),
        roomState: data.roomState,
        
        // Queue data - ensure we handle both direct queue and items array
        queue: data.room?.queue?.items || data.room?.queue || [],

        // Current track transformation
        currentTrack: data.room?.currentplayinglibrary ? (() => {
          const trackRating = data.room.currentplayinglibrary.rating;
          const trackStars = getStarCount(trackRating);
          const albumStars = data.albumRating?.rating ? getStarCount(data.albumRating.rating) : 0;
          const artistStars = data.artistRating?.rating ? getStarCount(data.artistRating.rating) : 0;

          debugUseRoom('Calculated current track ratings', {
            trackRating,
            trackStars,
            albumRating: data.albumRating?.rating,
            albumStars,
            artistRating: data.artistRating?.rating,
            artistStars,
            currentplayinglibrary: data.room.currentplayinglibrary
          });

          return {
            id: data.room.currentplayinglibrary.lid,
            userId: data.room.currentplayinglibrary.userId,
            user: data.room.currentplayinglibrary.user,
            track: data.room.currentplayinglibrary.track,
            viewTrackID: data.room.currentplayinglibrary.viewTrackID,
            libraryItem: data.room.currentplayinglibrary.track ? {
              id: data.room.currentplayinglibrary.track.id,
              title: data.room.currentplayinglibrary.title || data.room.currentplayinglibrary.track.title,
              artist: data.room.currentplayinglibrary.artist || data.room.currentplayinglibrary.track.artist,
              album: data.room.currentplayinglibrary.album || data.room.currentplayinglibrary.track.album_name,
              coverart: data.room.currentplayinglibrary.track.coverart,
              duration: data.room.currentplayinglibrary.track.duration,
              youtube_id: data.room.currentplayinglibrary.track.youtube_id,
              youtube_duration: data.room.currentplayinglibrary.track.youtube_duration,
              isrc: data.room.currentplayinglibrary.track.isrc,
              spotifyuri: data.room.currentplayinglibrary.track.spotifyuri,
              trackRating: trackStars,
              albumRating: albumStars,
              artistRating: artistStars,
              albumkey: data.room.currentplayinglibrary.track.albumkey,
              artistkey: data.room.currentplayinglibrary.track.artistkey
            } : null,
            rating: trackRating,
            position: data.room.currentplayinglibrary.position,
            roomrating: data.room.currentplayinglibrary.roomrating,
            votes: data.room.currentplayinglibrary.votes || [],
            userVoted: data.room.currentplayinglibrary.userVoted,
            numberOfvotes: data.room.currentplayinglibrary.numberOfvotes || 0,
            lastplayed: data.room.currentplayinglibrary.lastplayed,
            timesplayed: data.room.currentplayinglibrary.timesplayed
          };
        })() : null,

        // Additional room properties
        usersInRoom: data.room?.usersInRoomModel || [],
        roomRatingAdjustment: data.room?.roomRatingAdjustment,
        roomAgeAdjustment: data.room?.roomAgeAdjustment,
        roomFlowAdjustment: data.room?.roomFlowAdjustment
      };

      debugUseRoom('Final transformed data', { 
        transformedData: JSON.stringify(transformedData, null, 2),
        hasQueue: !!transformedData.queue,
        queueLength: transformedData.queue?.length || 0,
        hasCurrentTrack: !!transformedData.currentTrack?.libraryItem,
        hasUser: !!transformedData.currentUser,
        hasName: !!transformedData.name,
        hasRatings: {
          artist: !!transformedData.artistRating,
          album: !!transformedData.albumRating
        },
        currentTrackDetails: transformedData.currentTrack ? {
          hasId: !!transformedData.currentTrack.id,
          hasLibraryItem: !!transformedData.currentTrack.libraryItem,
          trackTitle: transformedData.currentTrack.libraryItem?.title
        } : null
      });

      return transformedData;
    },
    // Remove polling since we'll use websockets
    staleTime: Infinity, // Keep data fresh unless invalidated
    cacheTime: Infinity, // Keep data in cache unless invalidated
    enabled: !joinRoomMutation.isLoading, // Only fetch room data when not joining a room
    select: (data) => {
      debugUseRoom('Select function received data', { 
        hasRoom: !!data?.room,
        roomName: data?.name,
        hasUser: !!data?.currentUser,
        hasRatings: {
          artist: !!data?.artistRating,
          album: !!data?.albumRating
        },
        roomState: data?.roomState,
        currentTrack: data?.currentTrack ? {
          id: data?.currentTrack?.id,
          hasLibraryItem: !!data?.currentTrack?.libraryItem
        } : null,
        queueLength: data?.queue?.length
      });

      return data; // Return data as-is, already transformed in queryFn
    },
    onError: (error) => {
      debugUseRoom('Error fetching room data', { 
        error,
        message: error.message 
      });
    }
  });

  // Setup websocket subscription
  useEffect(() => {
    const handleRoomUpdate = (message) => {
      debugUseRoom('Received room update via websocket', { message });
      
      // Parse the message if it's a string
      const data = typeof message === 'string' ? JSON.parse(message) : message;
      
      // Update room data immediately using setQueryData
      queryClient.setQueryData(['room'], oldData => {
        if (!oldData) return data;
        
        // Merge the new data with existing data
        const updatedData = {
          ...oldData,
          ...data,
          // Preserve queue if not in update
          queue: data.queue || oldData.queue || [],
          // Preserve current track if not in update
          currentTrack: data.currentTrack || oldData.currentTrack,
          // Preserve user data if not in update
          currentUser: data.currentUser || oldData.currentUser
        };

        debugUseRoom('Updated room data', { 
          oldData,
          newData: data,
          mergedData: updatedData
        });

        return updatedData;
      });

      // Then trigger a background refetch to ensure consistency
      queryClient.invalidateQueries(['room']);
    };

    // Subscribe to room updates if we have room data
    const roomData = roomQuery.data;
    if (roomData?.name) {
      debugUseRoom('Subscribing to room updates', { roomName: roomData.name });
      subscribeToRoom(roomData.name, handleRoomUpdate);
    }

    // Cleanup subscription on unmount or room change
    return () => {
      if (roomData?.name) {
        unsubscribeFromRoom(roomData.name);
      }
    };
  }, [queryClient, roomQuery.data?.name]);

  // Add to queue mutation
  const addToQueueMutation = useMutation({
    mutationFn: (item) => {
      // The backend expects a track ID, not a library ID
      // Even though the parameter is called 'libraryItem', it's used to lookup a Track
      const trackId = typeof item === 'object' 
        ? (item.track?.id || item.viewTrackID || item.id) 
        : item;
        
      debugUseRoom('Preparing to add track to queue', { original: item, resolvedId: trackId });
      
      if (!trackId) {
        throw new Error(`Invalid track ID (${trackId}) for queue add operation`);
      }
      
      return roomApi.addToQueue(trackId);
    },
    onMutate: async (item) => {
      // Extract the track ID whether it's an object or direct ID
      const trackId = typeof item === 'object' 
        ? (item.track?.id || item.viewTrackID || item.id) 
        : item;
      
      debugUseRoom('Adding track to queue', { trackId, originalItem: item });
      await queryClient.cancelQueries(['room']);
      const previousRoom = queryClient.getQueryData(['room']);

      // Optimistically update
      queryClient.setQueryData(['room'], oldData => {
        if (!oldData) return oldData;
        return {
          ...oldData,
          queue: [...(oldData.queue || []), { lid: Number(trackId), pending: true }]
        };
      });

      return { previousRoom };
    },
    onError: (err, trackId, context) => {
      debugUseRoom('Failed to add track to queue', { err });
      // Revert to previous state on error
      queryClient.setQueryData(['room'], context.previousRoom);
    },
    onSettled: () => {
      // Always refetch after error or success
      queryClient.invalidateQueries(['room']);
    }
  });

  // Remove from queue mutation
  const removeFromQueueMutation = useMutation({
    mutationFn: roomApi.removeFromQueue,
    onSuccess: () => {
      roomQuery.refetch();
    }
  });

  // Skip song mutation
  const skipSongMutation = useMutation({
    mutationFn: roomApi.skipSong,
    onSuccess: () => {
      roomQuery.refetch();
    }
  });

  // Rate track mutation
  const rateTrackMutation = useMutation({
    mutationFn: ({ trackId, rating }) => roomApi.rateTrack(trackId, rating),
    onMutate: async ({ trackId, rating }) => {
      debugUseRoom('Rating track', { trackId, rating });
      await queryClient.cancelQueries(['room']);
      const previousRoom = queryClient.getQueryData(['room']);

      // Optimistically update
      queryClient.setQueryData(['room'], oldData => {
        if (!oldData) return oldData;

        // Update current track if it matches
        const updatedCurrentTrack = oldData.currentTrack?.viewTrackID === trackId ? {
          ...oldData.currentTrack,
          libraryItem: {
            ...oldData.currentTrack.libraryItem,
            trackRating: getStarCount(rating)
          }
        } : oldData.currentTrack;

        return {
          ...oldData,
          currentTrack: updatedCurrentTrack
        };
      });

      return { previousRoom };
    },
    onError: (err, { trackId, rating }, context) => {
      debugUseRoom('Failed to rate track', { err, trackId, rating });
      if (context?.previousRoom) {
        queryClient.setQueryData(['room'], context.previousRoom);
      }
    },
    onSuccess: (data, { trackId, rating }) => {
      debugUseRoom('Track rated successfully', { trackId, rating, data });
      // Don't invalidate the whole room query, just update the track rating
      queryClient.setQueryData(['room'], oldData => {
        if (!oldData) return oldData;
        return {
          ...oldData,
          currentTrack: oldData.currentTrack?.viewTrackID === trackId ? {
            ...oldData.currentTrack,
            libraryItem: {
              ...oldData.currentTrack.libraryItem,
              trackRating: getStarCount(rating)
            }
          } : oldData.currentTrack
        };
      });
    }
  });

  // Rate album mutation
  const rateAlbumMutation = useMutation({
    mutationFn: ({ albumId, rating }) => roomApi.rateAlbum(albumId, rating),
    onMutate: async ({ albumId, rating }) => {
      debugUseRoom('Rating album', { albumId, rating });
      await queryClient.cancelQueries(['room']);
      const previousRoom = queryClient.getQueryData(['room']);

      // Optimistically update
      queryClient.setQueryData(['room'], oldData => {
        if (!oldData) return oldData;

        // Update current track if it matches
        const updatedCurrentTrack = oldData.currentTrack?.libraryItem?.albumkey === albumId ? {
          ...oldData.currentTrack,
          libraryItem: {
            ...oldData.currentTrack.libraryItem,
            albumRating: getStarCount(rating)
          }
        } : oldData.currentTrack;

        return {
          ...oldData,
          currentTrack: updatedCurrentTrack,
          albumRating: {
            ...oldData.albumRating,
            rating: getStarCount(rating)
          }
        };
      });

      return { previousRoom };
    },
    onError: (err, { albumId, rating }, context) => {
      debugUseRoom('Failed to rate album', { err, albumId, rating });
      if (context?.previousRoom) {
        queryClient.setQueryData(['room'], context.previousRoom);
      }
    },
    onSuccess: (data, { albumId, rating }) => {
      debugUseRoom('Album rated successfully', { albumId, rating, data });
      // Don't invalidate the whole room query, just update the album rating
      queryClient.setQueryData(['room'], oldData => {
        if (!oldData) return oldData;
        return {
          ...oldData,
          currentTrack: oldData.currentTrack?.libraryItem?.albumkey === albumId ? {
            ...oldData.currentTrack,
            libraryItem: {
              ...oldData.currentTrack.libraryItem,
              albumRating: getStarCount(rating)
            }
          } : oldData.currentTrack,
          albumRating: {
            ...oldData.albumRating,
            rating: getStarCount(rating)
          }
        };
      });
    }
  });

  // Rate artist mutation
  const rateArtistMutation = useMutation({
    mutationFn: ({ artistName, rating }) => roomApi.rateArtist(artistName, rating),
    onMutate: async ({ artistName, rating }) => {
      debugUseRoom('Rating artist', { artistName, rating });
      await queryClient.cancelQueries(['room']);
      const previousRoom = queryClient.getQueryData(['room']);

      // Optimistically update
      queryClient.setQueryData(['room'], oldData => {
        if (!oldData) return oldData;

        // Update current track if it matches
        const updatedCurrentTrack = oldData.currentTrack?.libraryItem?.artist === artistName ? {
          ...oldData.currentTrack,
          libraryItem: {
            ...oldData.currentTrack.libraryItem,
            artistRating: getStarCount(rating)
          }
        } : oldData.currentTrack;

        return {
          ...oldData,
          currentTrack: updatedCurrentTrack,
          artistRating: {
            ...oldData.artistRating,
            rating: getStarCount(rating)
          }
        };
      });

      return { previousRoom };
    },
    onError: (err, { artistName, rating }, context) => {
      debugUseRoom('Failed to rate artist', { err, artistName, rating });
      if (context?.previousRoom) {
        queryClient.setQueryData(['room'], context.previousRoom);
      }
    },
    onSuccess: (data, { artistName, rating }) => {
      debugUseRoom('Artist rated successfully', { artistName, rating, data });
      // Don't invalidate the whole room query, just update the artist rating
      queryClient.setQueryData(['room'], oldData => {
        if (!oldData) return oldData;
        return {
          ...oldData,
          currentTrack: oldData.currentTrack?.libraryItem?.artist === artistName ? {
            ...oldData.currentTrack,
            libraryItem: {
              ...oldData.currentTrack.libraryItem,
              artistRating: getStarCount(rating)
            }
          } : oldData.currentTrack,
          artistRating: {
            ...oldData.artistRating,
            rating: getStarCount(rating)
          }
        };
      });
    }
  });

  // Vote on track mutation
  const voteOnTrackMutation = useMutation({
    mutationFn: ({ trackId, voteType }) => roomApi.voteOnTrack(trackId, voteType),
    onSuccess: () => {
      roomQuery.refetch();
    }
  });

  // Toggle room mode mutation
  const toggleRoomModeMutation = useMutation({
    mutationFn: roomApi.toggleRoomMode,
    onSuccess: () => {
      roomQuery.refetch();
    }
  });

  // Toggle room type mutation
  const toggleRoomTypeMutation = useMutation({
    mutationFn: roomApi.toggleRoomType,
    onSuccess: () => {
      roomQuery.refetch();
    }
  });

  // Move track up mutation
  const moveTrackUpMutation = useMutation({
    mutationFn: (trackId) => roomApi.moveUpInQueue(trackId),
    onMutate: async (trackId) => {
      debugUseRoom('Moving track up', { trackId });
      await queryClient.cancelQueries(['room']);
      const previousRoom = queryClient.getQueryData(['room']);

      // Optimistically update
      queryClient.setQueryData(['room'], oldData => {
        if (!oldData?.queue) return oldData;

        const queue = [...oldData.queue];
        const index = queue.findIndex(item => item.lid === trackId);
        if (index > 0) {
          [queue[index - 1], queue[index]] = [queue[index], queue[index - 1]];
        }

        return {
          ...oldData,
          queue
        };
      });

      return { previousRoom };
    },
    onError: (err, trackId, context) => {
      debugUseRoom('Failed to move track up', { err, trackId });
      if (context?.previousRoom) {
        queryClient.setQueryData(['room'], context.previousRoom);
      }
    },
    onSuccess: (data, trackId) => {
      debugUseRoom('Track moved up successfully', { trackId, data });
      queryClient.invalidateQueries(['room']);
    }
  });

  // Move track down mutation
  const moveTrackDownMutation = useMutation({
    mutationFn: (trackId) => roomApi.moveDownInQueue(trackId),
    onMutate: async (trackId) => {
      debugUseRoom('Moving track down', { trackId });
      await queryClient.cancelQueries(['room']);
      const previousRoom = queryClient.getQueryData(['room']);

      // Optimistically update
      queryClient.setQueryData(['room'], oldData => {
        if (!oldData?.queue) return oldData;

        const queue = [...oldData.queue];
        const index = queue.findIndex(item => item.lid === trackId);
        if (index < queue.length - 1) {
          [queue[index], queue[index + 1]] = [queue[index + 1], queue[index]];
        }

        return {
          ...oldData,
          queue
        };
      });

      return { previousRoom };
    },
    onError: (err, trackId, context) => {
      debugUseRoom('Failed to move track down', { err, trackId });
      if (context?.previousRoom) {
        queryClient.setQueryData(['room'], context.previousRoom);
      }
    },
    onSuccess: (data, trackId) => {
      debugUseRoom('Track moved down successfully', { trackId, data });
      queryClient.invalidateQueries(['room']);
    }
  });

  // Move track to top mutation
  const moveTrackToTopMutation = useMutation({
    mutationFn: (trackId) => roomApi.moveToTopInQueue(trackId),
    onMutate: async (trackId) => {
      debugUseRoom('Moving track to top', { trackId });
      await queryClient.cancelQueries(['room']);
      const previousRoom = queryClient.getQueryData(['room']);

      // Optimistically update
      queryClient.setQueryData(['room'], oldData => {
        if (!oldData?.queue) return oldData;

        const queue = [...oldData.queue];
        const index = queue.findIndex(item => item.lid === trackId);
        if (index > 0) {
          const item = queue.splice(index, 1)[0];
          queue.unshift(item);
        }

        return {
          ...oldData,
          queue
        };
      });

      return { previousRoom };
    },
    onError: (err, trackId, context) => {
      debugUseRoom('Failed to move track to top', { err, trackId });
      if (context?.previousRoom) {
        queryClient.setQueryData(['room'], context.previousRoom);
      }
    },
    onSuccess: (data, trackId) => {
      debugUseRoom('Track moved to top successfully', { trackId, data });
      queryClient.invalidateQueries(['room']);
    }
  });

  // Queue action wrapper functions
  const moveTrackUpWrapper = useCallback((trackId) => {
    return moveTrackUpMutation.mutate(trackId);
  }, [moveTrackUpMutation]);

  const moveTrackDownWrapper = useCallback((trackId) => {
    return moveTrackDownMutation.mutate(trackId);
  }, [moveTrackDownMutation]);

  const moveTrackToTopWrapper = useCallback((trackId) => {
    return moveTrackToTopMutation.mutate(trackId);
  }, [moveTrackToTopMutation]);

  // Helper functions to trigger mutations
  const handleRating = (trackId, rating, type = 'track') => {
    debugUseRoom('Handling rating', { trackId, rating, type });
    return rateTrackMutation.mutate({ trackId, rating });
  };

  const handleAlbumRating = (albumId, rating, type = 'album') => {
    debugUseRoom('Handling album rating', { albumId, rating, type });
    return rateAlbumMutation.mutate({ albumId, rating });
  };

  const handleArtistRating = (artistName, rating, type = 'artist') => {
    debugUseRoom('Handling artist rating', { artistName, rating, type });
    return rateArtistMutation.mutate({ artistName, rating });
  };

  // Update room data
  const updateRoomData = (data) => {
    queryClient.setQueryData(['room'], data);
  };

  const returnValue = {
    // Data
    data: roomQuery.data,  // Return the full transformed data
    currentRoom: roomQuery.data,  // For backward compatibility
    queue: roomQuery.data?.queue || [],
    isLoading: roomQuery.isLoading,
    error: roomQuery.error,

    // Mutations
    joinRoom: joinRoomMutation.mutate,
    addToQueue: addToQueueMutation.mutate,
    removeFromQueue: removeFromQueueMutation.mutate,
    skipSong: skipSongMutation.mutate,
    voteOnTrack: voteOnTrackMutation.mutate,
    toggleRoomMode: toggleRoomModeMutation.mutate,
    toggleRoomType: toggleRoomTypeMutation.mutate,
    rateTrack: rateTrackMutation.mutate,
    rateAlbum: rateAlbumMutation.mutate,
    rateArtist: rateArtistMutation.mutate,
    handleRating,
    updateRoom: updateRoomData,
    refetchRoom: roomQuery.refetch
  };

  // Debug the return value
  debugUseRoom('Returning room data', { 
    data: roomQuery.data,
    isLoading: roomQuery.isLoading,
    error: roomQuery.error?.message
  });

  return returnValue;
}
