import React, { useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import MusicLobby from './MusicLobby';
import { logDebug } from '../services/debugService';
import { useRoom } from '../contexts/RoomContext';
import { useAuth } from '../contexts/AuthContext';

const debugRoom = (message, data = {}) => {
  logDebug('Room', message, data);
};

function Room() {
  const { roomId } = useParams();
  const isHomePage = !roomId;
  const location = useLocation();
  const { handleRating, joinRoomByName } = useRoom();
  const { user } = useAuth(); // Get the user object from AuthContext
  
  // Try to join room directly when accessed via direct URL
  useEffect(() => {
    // Don't attempt any room joins if not authenticated
    if (!user?.authenticated) {
      debugRoom('User not authenticated, skipping room join attempt', { roomId });
      return;
    }
    
    // Log the room parameters for debugging
    debugRoom('Room component mounted', { 
      roomId, 
      isHomePage,
      pathname: location.pathname,
      isAuthenticated: user?.authenticated
    });
    
    // If we have a room ID and it's from a direct URL (not /room/:roomId), try to join it
    const isDirectUrl = roomId && !location.pathname.startsWith('/room/');
    
    if (isDirectUrl) {
      // Expanded list of reserved paths that should not be treated as rooms
      const reservedPaths = ['auth', 'login', 'settings', 'callback', 'twitch'];
      
      // Don't try to join for reserved paths or paths that contain reserved segments
      const isReservedPath = reservedPaths.some(path => 
        roomId === path || location.pathname.includes(`/${path}/`)
      );
      
      if (!isReservedPath) {
        debugRoom('Attempting to join room from direct URL', { roomId, isReservedPath });
        joinRoomByName(roomId).catch(error => {
          debugRoom('Failed to join room from direct URL', { error: error.message, roomId });
        });
      } else {
        debugRoom('Skipping room join for reserved path', { roomId, pathname: location.pathname });
      }
    }
  }, [roomId, isHomePage, location.pathname, joinRoomByName, user?.authenticated]);

  return (
    <div className="flex flex-col h-full">
      <MusicLobby
        roomId={roomId}
        onRating={handleRating}
      />
    </div>
  );
}

export default Room;
