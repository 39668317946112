import React from 'react';
import { RatingStars } from '../../RatingStars';
import { logDebug } from '../../../services/debugService';
import { getStarCount } from '../../../utils/ratingUtils';

const debugRating = (message, data = {}) => {
  logDebug('TrackRatingControls', message, data);
};

export function TrackRatingControls({
  trackId,
  trackRating = 0,
  onRating,
  size = 20
}) {
  debugRating('Rendering with raw props', { 
    trackId, 
    trackRating,
    starCount: getStarCount(trackRating)
  });

  const handleTrackRating = (numericRating) => {
    debugRating('Track rating clicked', { 
      trackId, 
      numericRating,
      currentRating: trackRating
    });
    // RatingStars already gives us the numeric rating (0-100)
    onRating?.(trackId, numericRating, 'track');
  };

  return (
    <div className="flex items-center">
      <RatingStars
        key={`track-${trackRating}`}
        rating={getStarCount(trackRating)}
        onChange={handleTrackRating}
        size={size}
        activeColor="#FFD700"
      />
    </div>
  );
}
