import React, { useState, useEffect, useRef } from 'react';
import { useRoom } from '../contexts/RoomContext';
import { DefaultAlbumArt } from './common/DefaultAlbumArt';
import { RatingStars } from './RatingStars';

export const NowPlaying = ({ 
  currentTrack, 
  isYoutubeMode, 
  onToggleYoutube, 
  position, 
  startedAt, 
  onAlbumClick,
  onArtistClick,
  onRating
}) => {
  const { skipSong, playOnSpotify } = useRoom();
  const [playerReady, setPlayerReady] = useState(false);
  const youtubePlayerRef = useRef(null);
  const playerContainerRef = useRef(null);
  const pausedAtRef = useRef(null);
  const totalPausedTimeRef = useRef(0);

  // Calculate current position based on startedAt timestamp
  const getCurrentPosition = () => {
    if (!startedAt) {
      console.log('Missing startedAt:', { position, startedAt });
      return position || 0;
    }
    
    // startedAt is already in milliseconds from the backend
    const now = Date.now();
    const elapsedTime = (now - startedAt) / 1000; // Convert elapsed time to seconds
    const currentPosition = (position || 0) + elapsedTime;
    
    console.log('Position calculation:', {
      startedAt,
      position,
      elapsedTime,
      currentPosition,
      now,
      isYoutubeMode,
      trackDuration: currentTrack?.libraryItem?.duration,
      youtubeDuration: currentTrack?.youtube_duration,
      usingDuration: isYoutubeMode ? currentTrack?.youtube_duration : currentTrack?.libraryItem?.duration
    });
    
    return currentPosition;
  };

  // Helper to safely seek to a position
  const safeSeekTo = (player, targetPosition) => {
    const validPosition = Math.max(0, targetPosition);
    console.log('Safe seeking to position:', validPosition);
    player.seekTo(validPosition, true);
  };

  useEffect(() => {
    // Load YouTube API if not already loaded
    if (!window.YT) {
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      const firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    }

    // Initialize YouTube player when in YouTube mode and we have a video ID
    if (isYoutubeMode && currentTrack?.youtubeId) {
      const initPlayer = () => {
        // Create a new div for the player
        const playerDiv = document.createElement('div');
        playerDiv.id = 'youtube-player';
        if (playerContainerRef.current) {
          playerContainerRef.current.innerHTML = '';
          playerContainerRef.current.appendChild(playerDiv);
        }

        const currentPosition = getCurrentPosition();
        console.log('Initializing player at position:', currentPosition);

        youtubePlayerRef.current = new window.YT.Player('youtube-player', {
          height: '200',
          width: '200',
          videoId: currentTrack.youtubeId,
          playerVars: {
            autoplay: 1,
            controls: 1,
            origin: window.location.origin,
            start: Math.floor(currentPosition || 0)
          },
          events: {
            onReady: (event) => {
              setPlayerReady(true);
              const syncedPosition = getCurrentPosition();
              console.log('Player ready, seeking to:', syncedPosition);
              safeSeekTo(event.target, syncedPosition);
              event.target.playVideo();
            },
            onStateChange: (event) => {
              const stateMap = {
                '-1': 'UNSTARTED',
                '0': 'ENDED',
                '1': 'PLAYING',
                '2': 'PAUSED',
                '3': 'BUFFERING',
                '5': 'CUED'
              };
              console.log('Player state changed:', event.data, `(${stateMap[event.data]})`);
              
              // Only sync if we're playing or buffering
              if (event.data === window.YT.PlayerState.PLAYING || 
                  event.data === window.YT.PlayerState.BUFFERING) {
                const syncedPosition = getCurrentPosition();
                const currentTime = event.target.getCurrentTime();
                const positionDiff = Math.abs(currentTime - syncedPosition);
                console.log('Position check - Current:', currentTime, 'Expected:', syncedPosition, 'Diff:', positionDiff);
                // Only seek if we're more than 2 seconds out of sync
                if (positionDiff > 2) {
                  console.log('Syncing to position:', syncedPosition);
                  safeSeekTo(event.target, syncedPosition);
                }
              }
            },
            onError: (event) => {
              console.error('YouTube player error:', event.data);
            }
          }
        });
      };

      // If YT API is ready, initialize player
      if (window.YT && window.YT.Player) {
        initPlayer();
      } else {
        // Wait for API to be ready
        window.onYouTubeIframeAPIReady = initPlayer;
      }

      // Cleanup function
      return () => {
        if (youtubePlayerRef.current) {
          youtubePlayerRef.current.destroy();
          youtubePlayerRef.current = null;
        }
        if (playerContainerRef.current) {
          playerContainerRef.current.innerHTML = '';
        }
      };
    }
  }, [isYoutubeMode, currentTrack?.youtubeId, position, startedAt]);

  // Effect to handle position updates while playing
  useEffect(() => {
    if (isYoutubeMode && youtubePlayerRef.current && playerReady) {
      try {
        const player = youtubePlayerRef.current;
        // Make sure the player is fully initialized with all methods
        if (player && typeof player.getCurrentTime === 'function') {
          const currentTime = player.getCurrentTime();
          const syncedPosition = getCurrentPosition();
          if (currentTime !== undefined && currentTime !== null) {
            const positionDiff = Math.abs(currentTime - syncedPosition);
            console.log('Position update - Current:', currentTime, 'Expected:', syncedPosition, 'Diff:', positionDiff);
            // Only seek if we're more than 2 seconds out of sync
            if (positionDiff > 2) {
              console.log('Syncing to position:', syncedPosition);
              safeSeekTo(player, syncedPosition);
            }
          }
        }
      } catch (error) {
        console.error('Error syncing YouTube player position:', error);
      }
    }
  }, [position, isYoutubeMode, playerReady]);

  // Add logging when currentTrack changes
  useEffect(() => {
    if (currentTrack) {
      console.log('NowPlaying - Current track updated:', {
        viewTrackID: currentTrack.viewTrackID,
        libraryItem: currentTrack.libraryItem,
        trackRating: currentTrack.libraryItem?.trackRating,
        albumRating: currentTrack.libraryItem?.albumRating,
        artistRating: currentTrack.libraryItem?.artistRating,
        hasLibraryItem: !!currentTrack.libraryItem,
        track: currentTrack.track,
        rawCurrentTrack: currentTrack
      });
    }
  }, [currentTrack]);

  const handleAlbumClick = () => {
    console.log('Album clicked, currentTrack structure:', {
      fullTrack: currentTrack,
      trackObject: currentTrack?.track,
      libraryItem: currentTrack?.libraryItem,
      album: currentTrack?.track?.album,
      album_name: currentTrack?.track?.album_name,
      album_musicbrainz_id: currentTrack?.track?.album_musicbrainz_id,
      hasAlbumClick: !!onAlbumClick
    });
    
    if (onAlbumClick && currentTrack?.track) {
      // Get the MusicBrainz ID
      const mbid = currentTrack.track?.album_musicbrainz_id;
      
      // Validate UUID format
      const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
      const isValidMbid = mbid && uuidRegex.test(mbid);
      
      // Create a structured album object matching what SearchDialog expects
      const albumItem = {
        type: 'album',
        title: currentTrack.track?.album_name || currentTrack.track?.album || currentTrack.libraryItem?.album,
        album: currentTrack.track?.album_name || currentTrack.track?.album || currentTrack.libraryItem?.album,
        artist: currentTrack.track?.album_artist || currentTrack.track?.artist || currentTrack.libraryItem?.artist,
        // This is the key property SearchDialog checks for
        musicbrainzId: isValidMbid ? mbid : null
      };

      console.log('NowPlaying - Album clicked, created albumItem:', albumItem, 'Valid UUID:', isValidMbid);
      
      // Only trigger if we have a valid musicbrainzId
      if (isValidMbid) {
        onAlbumClick(albumItem);
      } else {
        console.error('No valid musicbrainzId found for album - must be UUID format:', {
          mbid,
          track: currentTrack.track
        });
      }
    }
  };

  const handleArtistClick = () => {
    if (onArtistClick && currentTrack?.track?.artist) {
      onArtistClick(currentTrack.track.artist);
    }
  };
  
  const handleTrackRating = (rating) => {
    console.log('Track rating clicked:', {
      viewTrackID: currentTrack.viewTrackID,
      rating,
      currentRating: currentTrack.libraryItem?.trackRating,
      libraryItem: currentTrack.libraryItem
    });
    onRating?.(currentTrack.viewTrackID, rating, 'track');
  };

  const handleAlbumRating = (rating) => {
    console.log('Album rating clicked:', {
      albumKey: currentTrack.track?.album_musicbrainz_id,
      rating,
      currentRating: currentTrack.libraryItem?.albumRating,
      libraryItem: currentTrack.libraryItem
    });
    onRating?.(currentTrack.track?.album_musicbrainz_id, rating, 'album');
  };

  const handleArtistRating = (rating) => {
    console.log('Artist rating clicked:', {
      artistName: currentTrack.track?.artist,
      rating,
      currentRating: currentTrack.libraryItem?.artistRating,
      libraryItem: currentTrack.libraryItem
    });
    onRating?.(currentTrack.track?.artist, rating, 'artist');
  };

  if (!currentTrack) {
    return (
      <div className="p-4 bg-[#1A1A1A] rounded-lg">
        <div className="w-[200px] h-[200px] flex items-center justify-center">
          <DefaultAlbumArt.Component className="w-full h-full object-cover rounded-md" />
        </div>
        <p className="text-gray-400 text-center mt-4">No track playing</p>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center w-full">
      <div className="relative w-full max-w-xs">
        {/* Album Art or YouTube Player */}
        <div 
          ref={playerContainerRef}
          className={`aspect-square w-full bg-gray-900 overflow-hidden rounded-lg ${isYoutubeMode ? '' : 'flex items-center justify-center'}`}
        >
          {isYoutubeMode ? (
            // YouTube Player
            <div id="youtube-player" className="w-full h-full"></div>
          ) : (
            <img 
              src={currentTrack.track?.album?.baseicon || 
                (currentTrack.track?.album_musicbrainz_id 
                  ? `https://coverartarchive.org/release/${currentTrack.track.album_musicbrainz_id}/front`
                  : DefaultAlbumArt.src)}
              alt={`${currentTrack.libraryItem?.album || 'Album'} cover`}
              className="w-full h-full object-cover rounded-md hover:opacity-80 transition-opacity cursor-pointer"
              crossOrigin="anonymous"
              onClick={handleAlbumClick}
              onError={DefaultAlbumArt.onError}
              onLoad={() => {
                console.log('Now playing album art loaded:', {
                  track: currentTrack.track,
                  album: currentTrack.track?.album,
                  album_musicbrainz_id: currentTrack.track?.album_musicbrainz_id,
                  baseicon: currentTrack.track?.album?.baseicon
                });
              }}
            />
          )}
        </div>
        <div className="flex flex-col items-center mt-4 w-full">
          <div className="text-center mb-4 w-full">
            {/* Song Name and Rating */}
            <h2 className="text-xl font-bold">{currentTrack.libraryItem?.title || 'Unknown Title'}</h2>
            <div className="flex justify-center mt-1 mb-3">
              <RatingStars
                key={`track-${currentTrack.libraryItem?.trackRating || 0}`}
                rating={currentTrack.libraryItem?.trackRating || 0}
                onChange={handleTrackRating}
                size={24}
                activeColor="#FFD700"
              />
            </div>
            
            {/* Album Name and Rating */}
            <h3 
              className="text-gray-300 hover:text-white cursor-pointer"
              onClick={handleAlbumClick}
            >
              {currentTrack.libraryItem?.album || 'Unknown Album'}
            </h3>
            <div className="flex justify-center mt-1 mb-3">
              <RatingStars
                key={`album-${currentTrack.libraryItem?.albumRating || 0}`}
                rating={currentTrack.libraryItem?.albumRating || 0}
                onChange={handleAlbumRating}
                size={24}
                activeColor="#FFD700"
              />
            </div>
            
            {/* Artist Name and Rating */}
            <h3 
              className="text-gray-300 hover:text-white cursor-pointer"
              onClick={handleArtistClick}
            >
              {currentTrack.libraryItem?.artist || 'Unknown Artist'}
            </h3>
            <div className="flex justify-center mt-1 mb-3">
              <RatingStars
                key={`artist-${currentTrack.libraryItem?.artistRating || 0}`}
                rating={currentTrack.libraryItem?.artistRating || 0}
                onChange={handleArtistRating}
                size={24}
                activeColor="#FFD700"
              />
            </div>
            
            <p className="text-xs text-gray-500 mt-1">
              Queued by {currentTrack.user?.username || 'System'}
            </p>
          </div>
          <div className="flex justify-center space-x-2 mt-2">
            <button 
              onClick={skipSong}
              className="px-4 py-2 text-sm font-medium bg-red-600 hover:bg-red-700 text-white rounded-md"
            >
              Skip Song
            </button>
            <button 
              onClick={playOnSpotify}
              className="px-4 py-2 text-sm font-medium bg-green-600 hover:bg-green-700 text-white rounded-md"
            >
              Play on Spotify
            </button>
            <button 
              onClick={onToggleYoutube}
              className="px-4 py-2 text-sm font-medium bg-blue-600 hover:bg-blue-700 text-white rounded-md"
            >
              {isYoutubeMode ? 'Show Album Art' : 'Show Video'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
