// Environment-specific configuration
const ensureProtocol = (url) => {
  if (!url) return url;
  return url.startsWith('http://') || url.startsWith('https://') ? url : `https://${url}`;
};

// Get URLs dynamically from window.location
const getBaseUrl = () => {
  const { protocol, host } = window.location;
  return `${protocol}//${host}`;
};

export const FRONTEND_URL = getBaseUrl();
export const BACKEND_URL = ensureProtocol(import.meta.env.VITE_BACKEND_URL);

import { logDebug } from './debugService';

// Storage debug helper
const debugStorage = (operation, key, value) => {
  try {
    logDebug('AuthService', 'Storage Operation', {
      operation,
      key,
      value: value ? JSON.stringify(value).slice(0, 100) : 'null',
      storageType: 'localStorage',
      browser: navigator.userAgent
    });
  } catch (e) {
    logDebug('AuthService', 'Error in debugStorage', { error: e.message });
  }
};

// Log environment configuration
console.log('[Auth] Environment configuration:', {
  backendUrl: BACKEND_URL,
  frontendUrl: FRONTEND_URL
});

// Frontend callback URLs
const FRONTEND_OAUTH_CALLBACK = `${FRONTEND_URL}/auth/twitch`;
const FRONTEND_SUCCESS_CALLBACK = `${FRONTEND_URL}/auth/callback`;

// Log callback URLs
console.debug('[Auth] URLs configured:', {
  backendConfigured: !!BACKEND_URL,
  frontendConfigured: !!FRONTEND_URL
});

export class AuthError extends Error {
  constructor(message, code, originalError = null) {
    super(message);
    this.name = 'AuthError';
    this.code = code;
    this.originalError = originalError;
  }
}

// Token management
export const TOKEN_KEY = 'jwt_token';

export const setToken = (token) => {
  try {
    if (!token) {
      console.warn('[Auth] Attempted to set null/undefined token');
      return;
    }
    
    // Store token without Bearer prefix
    const tokenValue = token.replace('Bearer ', '');
    localStorage.setItem(TOKEN_KEY, tokenValue);
    console.debug('[Auth] Token stored successfully');
  } catch (error) {
    console.error('[Auth] Error setting token');
  }
};

export const getToken = () => {
  try {
    const token = localStorage.getItem(TOKEN_KEY);
    if (!token) {
      console.debug('[Auth] No token found in local storage');
      return null;
    }
    return `Bearer ${token}`;
  } catch (error) {
    console.error('[Auth] Error getting token');
    return null;
  }
};

export const clearToken = () => {
  try {
    console.debug('[Auth] Clearing auth data from storage');
    localStorage.removeItem(TOKEN_KEY);
    console.debug('[Auth] Auth data cleared successfully');
  } catch (error) {
    console.error('[Auth] Error clearing auth data');
  }
};

export const refreshToken = async (currentToken) => {
  try {
    const response = await fetch(`${BACKEND_URL}/auth/refresh`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${currentToken}`,
        'Content-Type': 'application/json'
      }
    });

    if (!response.ok) {
      console.error('[Auth] Failed to refresh token:', response.status);
      throw new Error('Failed to refresh token');
    }

    const { token } = await response.json();
    setToken(token);
    return token;
  } catch (error) {
    console.error('[Auth] Error refreshing token:', error);
    return null;
  }
};

export const isAuthenticated = () => {
  try {
    const token = getToken();
    if (!token) return false;
    
    const payload = parseJwt(token);
    if (!payload) return false;
    
    const isValid = payload.exp * 1000 > Date.now();
    console.log('[Auth] Token validation:', isValid ? 'valid' : 'expired');
    return isValid;
  } catch (error) {
    console.error('[Auth] Error validating token:', error);
    return false;
  }
};

const parseJwt = (token) => {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(c => 
      '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
    ).join(''));
    return JSON.parse(jsonPayload);
  } catch (error) {
    console.error('[Auth] Error parsing JWT:', error);
    return null;
  }
};

// Generate state for OAuth flow
export const generateState = () => {
  const state = {
    token: Math.random().toString(36).substring(2),
    redirect_uri: FRONTEND_SUCCESS_CALLBACK,
    timestamp: Date.now(),
    intended_path: window.location.pathname
  };
  // Use URL-safe base64 encoding
  const stateStr = btoa(JSON.stringify(state))
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=+$/, '');
  
  // Debug storage operation
  debugStorage('localStorage.setItem', 'oauth_state', stateStr);
  
  // Store state in localStorage for validation
  try {
    localStorage.setItem('oauth_state', stateStr);
  } catch (e) {
    logDebug('AuthService', 'Failed to write to localStorage', { error: e.message });
  }
  return stateStr;
};

const validateState = (returnedState) => {
  try {
    debugStorage('localStorage.getItem', 'oauth_state');
    const storedState = localStorage.getItem('oauth_state');
    
    if (!storedState || !returnedState || storedState !== returnedState) {
      logDebug('AuthService', 'State validation failed', {
        storedState,
        receivedState: returnedState,
        timeDiff: null
      });
      return false;
    }

    // Parse the state (handle URL-safe base64)
    const stateStr = returnedState
      .replace(/-/g, '+')
      .replace(/_/g, '/');
    const paddedStateStr = stateStr + '='.repeat((4 - stateStr.length % 4) % 4);
    const state = JSON.parse(atob(paddedStateStr));

    // Validate timestamp (10 minute expiry)
    const now = Date.now();
    if (now - state.timestamp > 10 * 60 * 1000) {
      logDebug('AuthService', 'State expired', { timeDiff: (now - state.timestamp) / 1000 });
      return false;
    }

    // Store intended path for redirect after login
    if (state.intended_path) {
      debugStorage('localStorage.setItem', 'intended_path', state.intended_path);
      try {
        localStorage.setItem('intended_path', state.intended_path);
      } catch (e) {
        logDebug('AuthService', 'Failed to write intended_path', { error: e.message });
      }
    }

    return true;
  } catch (error) {
    logDebug('AuthService', 'Error validating state', { error: error.message });
    return false;
  }
};

export const checkAuthStatus = async () => {
  const maxRetries = 10; // Turns out to be 34 minutes and 7 seconds with 1 sec exponential retries
  const baseDelay = 1000; // Start with 1 second delay
  let currentTry = 0;

  while (currentTry < maxRetries) {
    try {
      const token = localStorage.getItem('jwt_token');
      if (!token) {
        logDebug('authService', 'No token found in localStorage');
        return { authenticated: false };
      }

      const response = await fetch(`${import.meta.env.VITE_BACKEND_URL}/api/auth/status`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        credentials: 'include'
      });

      logDebug('authService', 'Auth status response', { 
        status: response.status,
        statusText: response.statusText,
        attempt: currentTry + 1,
        headers: Object.fromEntries(response.headers.entries())
      });

      // Handle server errors
      if (!response.ok) {
        // Only clear auth state on explicit 401 Unauthorized
        if (response.status === 401) {
          localStorage.removeItem('jwt_token');
          localStorage.removeItem('userData');
          return { authenticated: false };
        }
        
        // For 503 Service Unavailable or other server errors, keep retrying
        if (response.status === 503 || response.status >= 500) {
          throw new Error(`Server error: ${response.status}`);
        }

        // For other client errors (400, 403, etc), stop retrying
        return { authenticated: false };
      }

      const data = await response.json();
      if (!data) {
        logDebug('authService', 'Empty response from server');
        return { authenticated: false, emptyResponse: true };
      }

      if (data.authenticated) {
        logDebug('authService', 'Auth status check successful', { 
          authenticated: true,
          userData: data
        });
        return { 
          authenticated: true, 
          userData: {
            ...data,
            authenticated: true
          }
        };
      }

      logDebug('authService', 'User not authenticated');
      return { authenticated: false };
    } catch (error) {
      logDebug('authService', 'Auth check failed', { 
        error: error.message,
        attempt: currentTry + 1
      });

      // If this was our last retry, return server unavailable
      if (currentTry === maxRetries - 1) {
        // Don't clear auth state, just return server unavailable
        return { authenticated: false, serverUnavailable: true };
      }

      // Exponential backoff for retries
      const delay = baseDelay * Math.pow(2, currentTry);
      await new Promise(resolve => setTimeout(resolve, delay));
      currentTry++;
    }
  }

  // If we exhausted all retries, return server unavailable
  return { authenticated: false, serverUnavailable: true };
};

export const handleOAuthCallback = async (code) => {
  try {
    const response = await fetch(`${BACKEND_URL}/api/auth/callback?code=${code}`, {
      method: 'GET',
      credentials: 'include'
    });

    logDebug('AuthService', 'OAuth callback response', {
      status: response.status,
      statusText: response.statusText
    });

    if (!response.ok) {
      throw new Error(`OAuth callback failed: ${response.status}`);
    }

    const data = await response.json();
    if (!data || !data.token) {
      throw new Error('Invalid response from OAuth callback');
    }

    // Store the token
    localStorage.setItem(TOKEN_KEY, data.token);
    localStorage.setItem('userData', JSON.stringify(data.user));

    return data;
  } catch (error) {
    logDebug('AuthService', 'OAuth callback failed', { error: error.message });
    // Clear any partial auth state on error
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem('userData');
    throw error;
  }
};

export const performLogout = async () => {
  try {
    const token = localStorage.getItem(TOKEN_KEY);
    if (!token) {
      return;
    }

    const response = await fetch(`${BACKEND_URL}/api/auth/logout`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    });

    if (!response.ok) {
      throw new Error(`Logout failed: ${response.status}`);
    }
  } catch (error) {
    logDebug('AuthService', 'Logout failed', { error: error.message });
    throw error;
  } finally {
    // Always clear local storage on logout
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem('userData');
  }
};

export const initiateOAuth = async () => {
  try {
    console.debug('[Auth] Initiating OAuth flow...');
    const state = generateState();
    const authUrl = `${BACKEND_URL}/oauth2/authorization/twitch?state=${encodeURIComponent(state)}`;
    console.debug('[Auth] Redirecting to:', authUrl);
    window.location.href = authUrl;
  } catch (error) {
    console.error('[Auth] Error initiating OAuth:', error);
    throw error;
  }
};

export const handleCallback = async () => {
  console.debug('[Auth] Handling OAuth callback');
  try {
    const urlParams = new URLSearchParams(window.location.search);
    const success = urlParams.get('success');
    const error = urlParams.get('error');
    const code = urlParams.get('code');
    const state = urlParams.get('state');
    const id = urlParams.get('id');
    const login = urlParams.get('login');
    const name = urlParams.get('name');
    const email = urlParams.get('email');
    const token = urlParams.get('token');
    
    // Log the received parameters (excluding sensitive data)
    console.debug('[Auth] Received callback parameters:', {
      hasCode: !!code,
      hasState: !!state,
      hasError: !!error,
      hasToken: !!token
    });
    
    // Validate state parameter first
    if (!validateState(state)) {
      console.error('[Auth] OAuth error: Invalid state parameter');
      throw new AuthError('Invalid state parameter', 'invalid_state');
    }
    
    if (success !== 'true') {
      console.error('[Auth] OAuth error: Authentication failed');
      throw new AuthError('Authentication failed', 'auth_failed');
    }

    // Store the JWT token in local storage
    if (token) {
      console.debug('[Auth] Setting token in local storage');
      setToken(token);
      
      // Verify token is stored correctly
      const storedToken = getToken();
      if (!storedToken) {
        console.error('[Auth] Failed to store token');
        throw new AuthError('Failed to store token', 'token_storage_failed');
      }
      
      if (storedToken !== token) {
        console.error('[Auth] Stored token does not match received token');
        throw new AuthError('Token storage mismatch', 'token_mismatch');
      }
    } else {
      console.error('[Auth] OAuth error: No token received');
      throw new AuthError('No token received', 'no_token');
    }
    
    console.debug('[Auth] Successfully authenticated');
    
    // Get the intended path if it exists
    const intendedPath = localStorage.getItem('intended_path');
    console.debug('[Auth] Retrieved intended path:', intendedPath);
    
    // Clear the intended path from storage
    localStorage.removeItem('intended_path');
    
    // Return the user info and intended path
    return {
      id,
      login,
      name,
      email,
      authenticated: true,
      intendedPath: intendedPath || '/'
    };

  } catch (error) {
    console.error('[Auth] Error in callback');
    clearToken(); // Clear any partially stored data
    throw error;
  }
};

export const fetchUser = async () => {
  try {
    const token = getToken();
    if (!token) {
      return null;
    }

    const response = await fetch(`${BACKEND_URL}/greeting/userdump`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });
    
    if (!response.ok) {
      if (response.status === 401) {
        // Clear invalid token
        clearToken();
        return null;
      }
      throw new Error('Failed to fetch user data');
    }
    
    return await response.json();
  } catch (error) {
    console.error('Error fetching user data');
    throw error;
  }
};
