import { useQuery } from '@tanstack/react-query';
import * as Dialog from '@radix-ui/react-dialog';
import { Cross2Icon, DoubleArrowLeftIcon } from '@radix-ui/react-icons';
import apiClient from '../services/apiClient';
import { SearchResultItem } from './MusicLobby/SearchResults/SearchResultItem';
import { LibraryItem } from '../types';

interface TrackRecommendationsProps {
  onRate?: (trackId: number, rating: number) => void;
  onAddToQueue?: (trackId: number) => void;
}

export const TrackRecommendations = ({ onRate, onAddToQueue }: TrackRecommendationsProps) => {
  const { data, isLoading, error, refetch } = useQuery({
    queryKey: ['trackRecommendations'],
    queryFn: async () => {
      try {
        console.log('Fetching recommendations...');
        const response = await apiClient.get<LibraryItem[]>('/room/retrieveRecommendationsRaw', {
          params: { size: 50 }
        });
        console.log('Raw response data:', response.data);
        console.log('Recommendations details:', response.data?.map(item => ({
          id: item.id,
          title: item.track?.title,
          artist: item.track?.artist,
          album: item.track?.album_name,
          albumObject: item.track?.album,
          coverart: item.track?.coverart,
          albumBaseicon: item.track?.album_baseicon,
          musicbrainzId: item.track?.musicbrainzId,
          albumMusicbrainzId: item.track?.album?.musicbrainzId
        })));
        return response.data;
      } catch (err) {
        console.error('Error fetching recommendations:', err);
        throw err;
      }
    },
    refetchInterval: 30000 // Refetch every 30 seconds to keep recommendations fresh
  });

  return (
    <Dialog.Root>
      <Dialog.Trigger asChild>
        <button 
          className="fixed right-0 top-1/2 -translate-y-1/2 bg-white/5 hover:bg-white/10 text-white/60 hover:text-white/90 p-1.5 rounded-l transition-all group"
          title="View Recommendations"
        >
          <DoubleArrowLeftIcon className="w-4 h-4 transition-transform group-hover:-translate-x-0.5" />
        </button>
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 bg-black/50 backdrop-blur-sm" />
        <Dialog.Content className="fixed right-0 top-0 h-full w-[400px] bg-[#121212] shadow-xl p-6 focus:outline-none">
          <div className="flex items-center justify-between mb-6">
            <Dialog.Title className="text-xl font-semibold">
              Recommended Tracks
            </Dialog.Title>
            <Dialog.Close className="rounded-full p-1.5 hover:bg-white/10 transition-colors">
              <Cross2Icon className="w-4 h-4" />
            </Dialog.Close>
          </div>

          {isLoading && (
            <div className="animate-pulse">
              <div className="h-4 bg-white/10 rounded w-3/4 mb-4"></div>
              <div className="space-y-3">
                {[...Array(5)].map((_, i) => (
                  <div key={i} className="h-16 bg-white/5 rounded"></div>
                ))}
              </div>
            </div>
          )}

          {error && (
            <div className="text-red-400">
              Failed to load recommendations
            </div>
          )}

          {!data?.length && !isLoading && (
            <div className="text-gray-400">
              No recommendations available
            </div>
          )}

          {data && data.length > 0 && (
            <div className="space-y-2 overflow-y-auto max-h-[calc(100vh-120px)] pr-2 scrollbar-thin scrollbar-track-transparent scrollbar-thumb-white/10 hover:scrollbar-thumb-white/20">
              {data.map((item) => {
                
                return (
                  <SearchResultItem
                    key={item.viewTrackID || item.lid || item.id}
                    result={{
                      ...item,
                      type: 'track' // Ensure type is set for add button visibility
                    }}
                    onRate={(rating) => onRate?.(item.id, rating)}
                    onAddToQueue={() => {
                      const trackId = item.track?.id;
                      if (trackId) {
                        onAddToQueue?.(trackId);
                      } else {
                        console.error('No track ID found for recommendation item:', item);
                      }
                    }}
                    showRating={true}
                    showAddButton={true}
                  />
                );
              })}
            </div>
          )}
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
