import apiClient from '../apiClient';
import { logDebug } from '../debugService';

const debugMusicBrainzApi = (message, data = {}) => {
  logDebug('MusicBrainzApi', message, data);
};

/**
 * MusicBrainz API service
 * Handles all MusicBrainz replication-related API calls
 */
export const musicbrainzApi = {
  /**
   * Get current replication status
   * @returns {Promise<Object>} Replication status data
   */
  getReplicationStatus: async () => {
    try {
      debugMusicBrainzApi('Fetching replication status');
      const response = await apiClient.get('/api/musicbrainz/replication/status');
      debugMusicBrainzApi('Replication status response:', response.data);
      return response.data;
    } catch (error) {
      debugMusicBrainzApi('Error fetching replication status:', error);
      throw error;
    }
  },

  /**
   * Trigger replication update
   * @returns {Promise<Object>} Update response
   */
  triggerReplicationUpdate: async () => {
    try {
      debugMusicBrainzApi('Triggering replication update');
      const response = await apiClient.post('/api/musicbrainz/replication/check');
      debugMusicBrainzApi('Replication update response:', response.data);
      return response.data;
    } catch (error) {
      debugMusicBrainzApi('Error triggering replication update:', error);
      throw error;
    }
  }
};
