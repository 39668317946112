import React from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import { Cross2Icon } from '@radix-ui/react-icons';
import { formatDistanceToNow } from 'date-fns';
import { TrackRatingControls } from './MainContent/TrackRatingControls';
import { DefaultAlbumArt } from '../common/DefaultAlbumArt';

export function RoomInfoDialog({ isOpen, onClose, roomInfo, onRating, onAddToQueue, isLoading, isError, error }) {
  console.log('RoomInfoDialog render:', { isOpen, roomInfo, isLoading, isError, error });

  if (isLoading) {
    return (
      <Dialog.Root open={isOpen} onOpenChange={onClose}>
        <Dialog.Portal>
          <Dialog.Overlay className="fixed inset-0 bg-black/50" />
          <Dialog.Content 
            className="fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-[#1A1A1A] p-4 sm:p-6 rounded-lg shadow-xl w-[95vw] sm:w-[600px] max-h-[90vh] sm:max-h-[80vh] overflow-y-auto"
            aria-describedby="loading-description"
          >
            <Dialog.Title className="sr-only">Room Information</Dialog.Title>
            <div id="loading-description" className="flex justify-center items-center h-32">
              <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-white"></div>
              <div className="ml-3 text-white">Loading room information...</div>
            </div>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    );
  }

  if (isError) {
    return (
      <Dialog.Root open={isOpen} onOpenChange={onClose}>
        <Dialog.Portal>
          <Dialog.Overlay className="fixed inset-0 bg-black/50" />
          <Dialog.Content 
            className="fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-[#1A1A1A] p-4 sm:p-6 rounded-lg shadow-xl w-[95vw] sm:w-[600px]"
            aria-describedby="error-description"
          >
            <Dialog.Title className="text-2xl font-bold mb-4 text-white">Room Information</Dialog.Title>
            <div id="error-description" className="text-red-500 text-center">
              Unable to load room information. Please try again.
              {error && (
                <div className="mt-2 text-sm text-gray-400">
                  Error: {error.message || 'Unknown error'}
                </div>
              )}
            </div>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    );
  }

  const hasRecentTracks = roomInfo?.recentTracks?.length > 0;
  console.log('Recent tracks:', roomInfo?.recentTracks);
  
  // Debug first track cover art details
  if (hasRecentTracks && roomInfo.recentTracks[0]) {
    const firstTrack = roomInfo.recentTracks[0];
    console.log('First track cover art debug:', {
      track: firstTrack.track,
      album_baseicon: firstTrack.track?.album_baseicon,
      album_musicbrainz_id: firstTrack.track?.album_musicbrainz_id,
      coverart: firstTrack.track?.coverart
    });
  }

  return (
    <Dialog.Root open={isOpen} onOpenChange={onClose}>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 bg-black/50" />
        <Dialog.Content 
          className="fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-[#1A1A1A] p-4 sm:p-6 rounded-lg shadow-xl w-[95vw] sm:w-[600px] max-h-[90vh] sm:max-h-[80vh] overflow-y-auto"
          aria-describedby="room-description"
        >
          <Dialog.Title className="text-xl sm:text-2xl font-bold mb-4 text-white pr-10">
            {roomInfo?.room?.name || 'Room Information'}
          </Dialog.Title>

          <div id="room-description" className="space-y-4 sm:space-y-6">
            <div>
              <h3 className="text-base sm:text-lg font-semibold mb-2 text-white">Recent Tracks</h3>
              {hasRecentTracks ? (
                <div className="space-y-3 sm:space-y-4">
                  {roomInfo.recentTracks.map((track) => (
                    <div key={`${track.id}-${track.playedat}`} className="bg-[#282828] p-3 sm:p-4 rounded-lg">
                      <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-3">
                        <div className="flex items-start space-x-3 sm:space-x-4">
                          <img 
                            src={track.track?.album_baseicon || 
                              (track.track?.album_musicbrainz_id 
                                ? `https://coverartarchive.org/release/${track.track.album_musicbrainz_id}/front`
                                : (track.track?.coverart || DefaultAlbumArt.dataUrl))}
                            alt={`${track.track.title} album art`}
                            className="w-14 h-14 sm:w-16 sm:h-16 rounded-md object-cover shrink-0"
                            onError={DefaultAlbumArt.onError}
                          />
                          <div className="min-w-0 flex-1">
                            <div className="text-sm sm:text-base text-white font-medium truncate">{track.track.title}</div>
                            <div className="text-sm text-gray-400 truncate">{track.track.artist}</div>
                            <div className="text-xs sm:text-sm text-gray-500">
                              Played {formatDistanceToNow(new Date(track.playedat))} ago
                            </div>
                          </div>
                        </div>
                        
                        <div className="flex flex-col sm:items-end gap-2">
                          <div className="flex items-center gap-3 pl-[3.5rem] sm:pl-0">
                            <span className="text-sm text-gray-400">Your Rating:</span>
                            <TrackRatingControls
                              trackId={track.track.id}
                              trackRating={track.userRating || 0}
                              onRating={onRating}
                              size={22}
                            />
                          </div>
                          <div className="flex items-center gap-3 pl-[3.5rem] sm:pl-0 sm:justify-end">
                            <span className="text-sm text-gray-400">Room:</span>
                            <span className={`text-sm font-medium whitespace-nowrap ${
                              track.roomrating >= 80 ? 'text-green-400' :
                              track.roomrating >= (roomInfo?.roomminimumrating || 40) ? 'text-yellow-400' :
                              'text-red-400'
                            }`}>
                              {Math.max(0, Math.round(track.roomrating || 0))}%
                              <span className="text-xs ml-1">
                                {track.roomrating >= 80 ? '(Great)' :
                                 track.roomrating >= (roomInfo?.roomminimumrating || 40) ? '(OK)' :
                                 '(Poor)'}
                              </span>
                            </span>
                          </div>
                          <button
                            onClick={() => onAddToQueue(track.track.id)}
                            className="hidden sm:block px-4 py-1.5 text-sm bg-[#3E3E3E] hover:bg-[#4E4E4E] active:bg-[#5E5E5E] rounded-full text-white transition-colors"
                          >
                            Add
                          </button>
                        </div>
                      </div>
                      <button
                        onClick={() => onAddToQueue(track.track.id)}
                        className="sm:hidden w-full mt-2 px-4 py-2 text-sm bg-[#3E3E3E] hover:bg-[#4E4E4E] active:bg-[#5E5E5E] rounded-full text-white transition-colors"
                      >
                        Add to Queue
                      </button>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="text-gray-400 text-center py-4">No recent tracks found</div>
              )}
            </div>
          </div>

          <Dialog.Close asChild>
            <button
              className="absolute top-3 sm:top-4 right-3 sm:right-4 p-2 rounded-full hover:bg-[#3E3E3E] active:bg-[#4E4E4E] focus:outline-none transition-colors"
              aria-label="Close"
            >
              <Cross2Icon className="h-5 w-5 text-gray-400" />
            </button>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
