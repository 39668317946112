import React from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import { Cross2Icon } from '@radix-ui/react-icons';

export const RecommendationDocsDialog = ({ open, onOpenChange }) => {
  return (
    <Dialog.Root open={open} onOpenChange={onOpenChange}>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 bg-black/50 data-[state=open]:animate-overlayShow" />
        <Dialog.Content className="fixed top-[50%] left-[50%] max-h-[85vh] w-[90vw] max-w-[800px] translate-x-[-50%] translate-y-[-50%] rounded-[6px] bg-[#282828] p-[25px] shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none data-[state=open]:animate-contentShow overflow-y-auto text-white">
          <Dialog.Title className="text-2xl font-bold mb-4">
            How Song Selection Works
          </Dialog.Title>
          
          <div className="space-y-6">
            <section>
              <h3 className="text-lg font-semibold mb-2">The Basics</h3>
              <p className="text-gray-300">
                Songs are selected using a combination of two main factors:
              </p>
              <ul className="list-disc pl-6 mt-2 space-y-1 text-gray-300">
                <li>Rating: How well-liked the song is (based on user and room ratings)</li>
                <li>Variety: How long it's been since the song was last played</li>
              </ul>
            </section>

            <section>
              <h3 className="text-lg font-semibold mb-2">The Selection Balance</h3>
              <p className="text-gray-300">
                The slider lets you control how these factors influence song selection:
              </p>
              <div className="bg-[#2E2E2E] p-4 rounded-lg mt-2 space-y-2 border border-gray-700">
                <div className="flex justify-between items-center text-gray-300">
                  <span>Favor Rating</span>
                  <span>Favor Variety</span>
                </div>
                <div className="h-2 bg-gradient-to-r from-blue-500 to-green-500 rounded-full" />
              </div>
            </section>

            <section>
              <h3 className="text-lg font-semibold mb-2">How It Works</h3>
              <div className="space-y-4">
                <div className="bg-[#2E2E2E] p-4 rounded-lg border border-gray-700">
                  <h4 className="font-medium text-gray-200">Favor Rating (Low %)</h4>
                  <p className="text-gray-300">Prioritizes highly rated songs, with minimal consideration for play history.</p>
                  <p className="text-sm text-gray-400 mt-1">Best for: When you want to stick to crowd favorites</p>
                </div>

                <div className="bg-[#2E2E2E] p-4 rounded-lg border border-gray-700">
                  <h4 className="font-medium text-gray-200">Middle Ground (50%)</h4>
                  <p className="text-gray-300">Balances between ratings and variety, giving both factors significant weight.</p>
                  <p className="text-sm text-gray-400 mt-1">Best for: Most situations, providing a mix of favorites and variety</p>
                </div>

                <div className="bg-[#2E2E2E] p-4 rounded-lg border border-gray-700">
                  <h4 className="font-medium text-gray-200">Favor Variety (High %)</h4>
                  <p className="text-gray-300">Gives more weight to songs that haven't been played in a while, even if they have lower ratings.</p>
                  <p className="text-sm text-gray-400 mt-1">Best for: When you want to explore more of your library</p>
                </div>
              </div>
            </section>

            <section>
              <h3 className="text-lg font-semibold mb-2">Smart Selection</h3>
              <p className="text-gray-300">
                The system uses logarithmic scaling to make selections more natural:
              </p>
              <ul className="list-disc pl-6 mt-2 space-y-1 text-gray-300">
                <li>Rating differences matter less at higher ratings (e.g., 100 vs 99 is treated similarly)</li>
                <li>Time since last play is scaled to prevent very old songs from dominating</li>
                <li>Both factors are always considered, just in different proportions based on the slider</li>
              </ul>
            </section>
          </div>

          <Dialog.Close asChild>
            <button
              className="absolute top-[10px] right-[10px] px-3 py-1.5 flex items-center gap-1 text-sm text-white/80 hover:text-white bg-white/5 hover:bg-white/10 rounded-md"
              aria-label="Close"
            >
              <svg className="w-4 h-4" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                <path d="M6 18L18 6M6 6l12 12" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
              Close
            </button>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
