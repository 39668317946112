import React from 'react';
import { RatingStars } from '../../../RatingStars';
import { Button } from '../../../ui/Button';
import { DefaultAlbumArt } from '../../../common/DefaultAlbumArt';

export function AlbumDetailView({ 
  album, 
  tracks = [],
  onTrackRate, 
  onAlbumRate, 
  onAddToQueue 
}) {
  console.log('AlbumDetailView album:', album);
  console.log('AlbumDetailView tracks:', tracks);

  const getTrackInfo = (item) => {
    console.log('Getting track info for:', item);
    // If it's a library item, use its properties
    if (item.id && item.isrc) {
      return {
        id: item.id,
        viewTrackID: item.id,  // For queueing
        lid: item.id,  // For queueing
        title: item.title,
        artist: item.artist,
        rating: item.rating
      };
    }
    // Otherwise treat it as a track
    return {
      id: item.viewTrackID,  // For React key
      viewTrackID: item.viewTrackID,  // For queueing
      lid: item.lid,  // For queueing
      title: item.title,
      artist: item.artist,
      rating: item.rating
    };
  };

  // Function to format UUID with hyphens for MusicBrainz
  const formatMusicBrainzId = (id) => {
    if (!id) return null;
    // If it already has hyphens, return as is
    if (id.includes('-')) return id;
    // Add hyphens in UUID format: 8-4-4-4-12
    return id.replace(
      /^(.{8})(.{4})(.{4})(.{4})(.{12})$/,
      '$1-$2-$3-$4-$5'
    );
  };

  const handleAlbumRate = (rating) => {
    if (album.musicbrainzId) {
      console.log('Rating album with MusicBrainz ID:', album.musicbrainzId, 'with rating:', rating);
      onAlbumRate?.(album.musicbrainzId, rating);
    } else {
      console.error('No MusicBrainz ID available for album rating:', album);
    }
  };

  return (
    <div className="flex flex-col gap-6">
      <div className="flex gap-6">
        <div className="w-48 h-48 bg-[#282828] rounded overflow-hidden shrink-0">
          <img
            src={album.baseicon || (album.musicbrainzId 
              ? `https://coverartarchive.org/release/${formatMusicBrainzId(album.musicbrainzId)}/front` 
              : (album.coverart || DefaultAlbumArt.dataUrl))}
            alt={`${album.album || 'Album'} cover`}
            className="w-full h-full object-cover"
            crossOrigin="anonymous"
            onError={(e) => {
              // If the image fails to load, use default
              console.log('Cover art failed to load, using default');
              DefaultAlbumArt.onError(e);
            }}
          />
        </div>
        <div className="flex-1">
          <h2 className="text-xl font-semibold text-white">
            {(typeof album.album === 'string' ? album.album : album.name) || 'Unknown Album'}
          </h2>
          <h3 className="text-lg text-gray-300">
            {typeof album.artist === 'string' ? album.artist : 'Unknown Artist'}
          </h3>
          
          {/* Album Rating Stars */}
          {onAlbumRate && album.musicbrainzId && (
            <div className="mt-2 flex items-center">
              <span className="mr-2 text-sm text-gray-400">Rate Album:</span>
              <RatingStars
                rating={(album.rating || 0) / 20} // Convert from 0-100 to 0-5 scale
                onChange={handleAlbumRate}
                size={20}
              />
            </div>
          )}
          
          <div className="mt-4 space-y-2 text-sm text-gray-400">
            {album.displaydate && (
              <div>
                <span className="font-medium">Release Date:</span> {album.displaydate}
              </div>
            )}
            {album.length > 0 && (
              <div>
                <span className="font-medium">Tracks:</span> {album.length}
              </div>
            )}
            {album.releaseStatus && (
              <div>
                <span className="font-medium">Release Status:</span>{' '}
                {album.releaseStatus === 1 && 'Official'}
                {album.releaseStatus === 2 && 'Promotional'}
                {album.releaseStatus === 3 && 'Bootleg'}
              </div>
            )}
            {album.releaseGroupType && (
              <div>
                <span className="font-medium">Release Type:</span>{' '}
                {album.releaseGroupType === 1 && 'Album'}
                {album.releaseGroupType === 2 && 'Single'}
                {album.releaseGroupType === 3 && 'EP'}
                {album.releaseGroupType === 4 && 'Compilation'}
                {album.releaseGroupType === 5 && 'Soundtrack'}
                {album.releaseGroupType === 6 && 'Live'}
                {album.releaseGroupType === 7 && 'Remix'}
              </div>
            )}
            {album.mediumFormat && (
              <div>
                <span className="font-medium">Format:</span>{' '}
                {album.mediumFormat === 1 && 'CD'}
                {album.mediumFormat === 2 && 'Digital'}
                {album.mediumFormat === 3 && 'Vinyl'}
                {album.mediumFormat === 4 && 'Cassette'}
                {album.mediumFormat === 5 && 'DVD'}
                {album.mediumFormat === 6 && 'Blu-ray'}
              </div>
            )}
            {album.firstReleaseDate && (
              <div>
                <span className="font-medium">First Released:</span> {' '}
                {new Date(album.firstReleaseDate).toLocaleDateString()}
              </div>
            )}
            {album.upc && (
              <div>
                <span className="font-medium">UPC:</span> {album.upc}
              </div>
            )}
            {album.musicbrainzId && (
              <div>
                <span className="font-medium">MusicBrainz ID:</span> {album.musicbrainzId}
              </div>
            )}
            {album.isCompilation && (
              <div className="inline-block px-2 py-1 bg-gray-700 rounded text-xs">
                Compilation Album
              </div>
            )}
            {album.isExplicit && (
              <div className="inline-block px-2 py-1 bg-red-900 rounded text-xs">
                Explicit
              </div>
            )}
            {album.isClean && (
              <div className="inline-block px-2 py-1 bg-green-900 rounded text-xs">
                Clean
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="space-y-2">
        {!tracks?.length ? (
          <div className="text-center text-gray-400">No tracks found</div>
        ) : (
          tracks.map((item) => {
            const track = getTrackInfo(item);
            return (
              <div 
                key={`album-track-${track.id}`}
                className="flex items-center justify-between p-3 bg-white/5 hover:bg-white/10 rounded-lg transition-colors"
              >
                <div className="flex-1 min-w-0 mr-4">
                  <div className="font-medium text-white truncate">
                    {track.title}
                  </div>
                  <div className="text-sm text-gray-400 truncate">
                    {track.artist}
                  </div>
                </div>
                <div className="flex items-center space-x-4">
                  <RatingStars
                    rating={(track.rating || 0) / 20}
                    onChange={(rating) => {
                      // Always use viewTrackID for rating as that's what the backend expects
                      const trackId = track.viewTrackID;
                      if (trackId) {
                        // Pass the rating directly since useRating will convert it to 0-100 scale
                        onTrackRate?.(trackId, rating);
                      } else {
                        console.error('No valid track ID for rating:', track);
                      }
                    }}
                  />
                  <Button
                    size="sm"
                    onClick={() => {
                      console.log('Adding track to queue:', track);
                      // Use viewTrackID for queueing since that's what works in the search dialog
                      onAddToQueue?.(track.viewTrackID);
                    }}
                  >
                    Add
                  </Button>
                </div>
              </div>
            );
          })
        )}
      </div>
    </div>
  );
}
