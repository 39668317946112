import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { Dialog, DialogTrigger, DialogContent, DialogOverlay, DialogClose } from '@radix-ui/react-dialog';
import { Cross2Icon } from '@radix-ui/react-icons';
import { useNavigate } from 'react-router-dom';
import { roomApi } from '../services/api/roomApi';
import { DefaultAlbumArt } from './common/DefaultAlbumArt';

const debugRoomSelector = (message, data = {}) => {
  console.log('RoomSelector', message, data);
};

export function RoomSelector({ trigger }) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [showOffline, setShowOffline] = React.useState(false);
  const navigate = useNavigate();

  const { data: onlineRooms = [], isLoading: isLoadingOnline } = useQuery({
    queryKey: ['onlineRooms'],
    queryFn: () => roomApi.getRooms(),
    enabled: isOpen, // Only fetch when dialog is open
  });

  const { data: offlineRooms = [], isLoading: isLoadingOffline } = useQuery({
    queryKey: ['offlineRooms'],
    queryFn: () => roomApi.getOfflineRooms(),
    enabled: isOpen && showOffline, // Only fetch when dialog is open and offline rooms are shown
  });

  // Combine rooms and mark offline ones
  const rooms = React.useMemo(() => {
    // Filter out reserved room names
    const reservedNames = ['room', 'login', 'settings', 'auth'];
    const filterReservedRooms = (room) => !reservedNames.includes(room.name.toLowerCase());

    // Start with filtered online rooms
    const combinedRooms = onlineRooms.filter(filterReservedRooms);
    
    if (showOffline) {
      // Add offline rooms that aren't in the online list
      offlineRooms
        .filter(filterReservedRooms)
        .forEach(room => {
          if (!combinedRooms.some(r => r.name === room.name)) {
            combinedRooms.push({
              ...room,
              isOffline: true
            });
          }
        });
    }
    return combinedRooms;
  }, [onlineRooms, offlineRooms, showOffline]);

  // For each room, fetch its current state to get the playing song
  const { data: roomStates = {}, isLoading: isLoadingStates } = useQuery({
    queryKey: ['roomStates', onlineRooms.map(r => r.name)],
    enabled: isOpen && onlineRooms.length > 0,
    queryFn: async () => {
      debugRoomSelector('Fetching room states');
      const states = {};
      await Promise.all(onlineRooms.map(async (room) => {
        try {
          const response = await roomApi.getRoomState(room.name);
          states[room.name] = response;
        } catch (error) {
          debugRoomSelector('Error fetching room state', { room: room.name, error: error.message });
        }
      }));
      return states;
    },
  });

  const handleRoomSelect = async (roomName) => {
    try {
      debugRoomSelector('Joining room', { roomName });
      const response = await roomApi.joinRoom(roomName);
      debugRoomSelector('Join room response', { 
        response,
        hasRoom: !!response?.room,
        roomName: response?.room?.name
      });

      // Validate room response
      if (!response?.room?.name) {
        console.error('Invalid room response:', response);
        // TODO: Show error toast to user
        return;
      }

      // Close dialog before navigation
      setIsOpen(false);
      
      // Ensure we encode the room name for the URL
      const encodedRoomName = encodeURIComponent(response.room.name);
      debugRoomSelector('Navigating to room', { encodedRoomName });
      navigate(`/room/${encodedRoomName}`);
    } catch (error) {
      console.error('Failed to join room:', {
        error,
        message: error.message,
        status: error?.response?.status
      });
      // Keep dialog open on error
      // TODO: Show error toast to user
    }
  };

  const isLoading = isLoadingOnline || (showOffline && isLoadingOffline) || isLoadingStates;

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        {trigger}
      </DialogTrigger>
      <DialogOverlay className="fixed inset-0 bg-black/80 z-50" />
      <DialogContent className="fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-[#282828] rounded-lg p-6 shadow-xl w-[90vw] max-w-md z-50">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold text-white">Available Rooms</h2>
          <div className="flex items-center gap-2">
            <label className="text-sm text-gray-400">
              <input
                type="checkbox"
                checked={showOffline}
                onChange={(e) => setShowOffline(e.target.checked)}
                className="mr-2 rounded bg-[#3E3E3E] border-[#4E4E4E]"
              />
              Show Offline
            </label>
          </div>
        </div>
        {isLoading && <p className="text-center py-4 text-gray-400">Loading rooms...</p>}
        {!isLoading && rooms.length === 0 && (
          <p className="text-center py-4 text-gray-400">No rooms available</p>
        )}
        <div className="max-h-[60vh] overflow-y-auto space-y-2">
          {rooms.map((room) => {
            const state = roomStates[room.name];
            const currentTrack = state?.currentTrack;
            const isOffline = room.isOffline;
            
            return (
              <button
                key={room.name}
                onClick={() => {
                  console.log('Room button clicked:', room.name);
                  handleRoomSelect(room.name);
                }}
                className={`w-full text-left p-3 rounded transition-colors ${
                  isOffline 
                    ? 'bg-[#2E2E2E] hover:bg-[#3E3E3E] opacity-75' 
                    : 'bg-[#3E3E3E] hover:bg-[#4E4E4E]'
                }`}
                type="button"
                title={isOffline ? "Click to bring this room online" : `Join ${room.name}`}
              >
                <div className="flex items-start gap-3">
                  {currentTrack?.albumArtUrl ? (
                    <img 
                      src={currentTrack.albumArtUrl} 
                      alt={`${currentTrack.album || 'Album'} cover`}
                      className="w-12 h-12 rounded object-cover"
                      onError={DefaultAlbumArt.onError}
                    />
                  ) : (
                    <DefaultAlbumArt.Component className="w-12 h-12 rounded" />
                  )}
                  <div className="flex-1 min-w-0">
                    <div className="font-medium text-white truncate">{room.name}</div>
                    <div className="text-sm text-gray-400">
                      {room.numOfUsersInRoom} users • {room.roomTypeAsString}
                      {isOffline && ' • Offline'}
                    </div>
                    {currentTrack && !isOffline && (
                      <div className="text-sm text-gray-400 mt-1">
                        <div className="truncate">Now Playing: {currentTrack.title}</div>
                        <div className="truncate">{currentTrack.artist}</div>
                      </div>
                    )}
                  </div>
                </div>
              </button>
            );
          })}
        </div>
        <DialogClose asChild>
          <button
            className="absolute top-4 right-4 p-1 rounded-full hover:bg-[#3E3E3E] text-gray-400 hover:text-white"
            aria-label="Close"
          >
            <Cross2Icon />
          </button>
        </DialogClose>
      </DialogContent>
    </Dialog>
  );
}
