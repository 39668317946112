import React from 'react'
import { createRoot } from 'react-dom/client'
import { RouterProvider } from 'react-router-dom'
import './index.css'
import { router } from './router'

// Immediately unregister ALL service workers
if ('serviceWorker' in navigator) {
  // Unregister all existing service workers
  navigator.serviceWorker.getRegistrations().then(function(registrations) {
    registrations.forEach(registration => {
      registration.unregister().then(() => {
        console.log('[ServiceWorker] Unregistered:', registration.scope);
      });
    });
  });

  // Prevent future service worker registrations
  navigator.serviceWorker.register = () => {
    console.log('[ServiceWorker] Blocked new registration attempt');
    return Promise.reject('Service Worker registration is disabled');
  };
}

// Also clear any service worker caches
if ('caches' in window) {
  caches.keys().then(function(names) {
    names.forEach(name => {
      caches.delete(name).then(() => {
        console.log('[Cache] Deleted:', name);
      });
    });
  });
}

const root = createRoot(document.getElementById('root'))

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
)

// Enable HMR
if (import.meta.hot) {
  import.meta.hot.accept()
}
