import { createContext, useContext, useState, useEffect } from 'react';
import { performLogout, checkAuthStatus } from '../services/authService';
import { logDebug } from '../services/debugService';
import { Flex, Spinner } from '@radix-ui/themes';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(() => {
    // Initialize user state from localStorage
    try {
      const storedUser = localStorage.getItem('userData');
      const storedToken = localStorage.getItem('jwt_token');
      if (storedUser && storedToken) {
        return JSON.parse(storedUser);
      }
    } catch (error) {
      logDebug('AuthContext', 'Error reading from localStorage', { error: error.message });
    }
    return null;
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const updateUser = (userData) => {
    if (userData) {
      logDebug('AuthContext', 'Updating user', { 
        authenticated: !!userData,
        hasData: !!userData
      });
      setUser(userData);
      const userDataStr = JSON.stringify(userData);
      // Write to both storages
      localStorage.setItem('userData', userDataStr);
      sessionStorage.setItem('userData', userDataStr);
    } else {
      logDebug('AuthContext', 'Clearing user', {
        hadPreviousUser: !!user
      });
      setUser(null);
      // Clear from both storages
      localStorage.removeItem('userData');
      localStorage.removeItem('jwt_token');
      sessionStorage.removeItem('userData');
      sessionStorage.removeItem('jwt_token');
    }
  };

  const logout = async () => {
    try {
      await performLogout();
      updateUser(null);
    } catch (err) {
      setError(err.message);
      // Still clear user data on logout error
      updateUser(null);
    }
  };

  const initAuth = async () => {
    logDebug('AuthContext', 'Initializing auth', {
      path: window.location.pathname,
      hasStoredData: !!localStorage.getItem('userData')
    });

    // Skip auth check on auth callback pages
    if (window.location.pathname.startsWith('/auth/')) {
      setLoading(false);
      return;
    }

    try {
      // Check if we have a valid token and verify with server
      const { authenticated, userData, emptyResponse, parseError, serverUnavailable } = await checkAuthStatus();
      
      if (authenticated && userData) {
        logDebug('AuthContext', 'Valid auth session found', { 
          authenticated: true
        });
        updateUser({ ...userData, authenticated: true });
      } else {
        // Handle specific error cases
        if (emptyResponse) {
          logDebug('AuthContext', 'Server returned empty response');
          setError('Server returned empty response. Please try again.');
        } else if (parseError) {
          logDebug('AuthContext', 'Failed to parse server response');
          setError('Server returned invalid data. Please try again.');
        } else if (serverUnavailable) {
          logDebug('AuthContext', 'Server is temporarily unavailable');
          setError('Server is temporarily unavailable. Retrying...');
          // Don't clear user data, just show error
          return;
        }
        
        // Only clear auth state if server explicitly says we're not authenticated
        if (!serverUnavailable) {
          logDebug('AuthContext', 'Invalid auth state');
          updateUser(null);
        }
      }
    } catch (err) {
      logDebug('AuthContext', 'Auth check failed', { 
        errorType: err.name 
      });
      setError(err.message);
      // Don't clear user data on network errors
      if (err.name !== 'TypeError' && err.name !== 'NetworkError') {
        updateUser(null);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    initAuth();
  }, []);

  // Add an interval to periodically check auth status
  useEffect(() => {
    if (!user?.authenticated) return;

    const interval = setInterval(() => {
      initAuth();
    }, 5 * 60 * 1000); // Check every 5 minutes

    return () => clearInterval(interval);
  }, [user?.authenticated]);

  if (loading) {
    return (
      <Flex align="center" justify="center" style={{ height: '100vh' }}>
        <Spinner size="large" />
      </Flex>
    );
  }

  return (
    <AuthContext.Provider value={{ user, updateUser, logout, error }}>
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}
