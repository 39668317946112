import React, { useEffect, useState } from 'react';
import { GaugeComponent } from 'react-gauge-component';
import { BACKEND_URL } from '../services/authService';
import { roomApi } from '../services/api/roomApi';
import { useAuth } from '../contexts/AuthContext';

export function RoomInfo({ roomInfo }) {
  const [roomRating, setRoomRating] = useState(0);
  const [pointerColor, setPointerColor] = useState('#ff0000');
  const { user } = useAuth();

  const fetchRoomRating = async () => {
    try {
      console.log('Fetching room rating...');
      const rating = await roomApi.fetchRoomRating();
      console.log('Room rating:', rating);
      // Ensure rating is a number and between 0-100
      const validRating = Math.min(Math.max(Number(rating) || 0, 0), 100);
      setRoomRating(validRating);
    } catch (error) {
      console.error('Failed to fetch room rating:', error);
      setRoomRating(0); // Set to 0 on error
    }
  };

  useEffect(() => {
    if (!roomInfo) return;
    fetchRoomRating();
  }, [roomInfo, roomInfo?.roomRatingUpdateTime]);

  useEffect(() => {
    // Update pointer color based on rating
    const minRating = roomInfo?.roomminimumrating || 40;
    if (roomRating >= 80) {
      setPointerColor('#00ff00');
    } else if (roomRating >= minRating) {
      setPointerColor('#ffff00');
    } else {
      setPointerColor('#ff0000');
    }
  }, [roomRating, roomInfo?.roomminimumrating]);

  if (!roomInfo) return null;

  const minRating = roomInfo?.roomminimumrating || 40;

  return (
    <div className="bg-[#1A1A1A] rounded-lg">
      <h2 className="text-2xl font-bold p-6 text-center">Room Info</h2>
      <div className="p-6 pt-0">
        <div className="flex flex-col space-y-4">
          <div className="grid grid-cols-2 gap-4">
            <div className="text-center">
              <div className="text-gray-400">Type</div>
              <div className="text-white">{roomInfo?.roomTypeAsString}</div>
            </div>
            <div className="text-center">
              <div className="text-gray-400">Mode</div>
              <div className="text-white">{roomInfo?.roommode === 0 ? 'Normal' : 'Special'}</div>
            </div>
            <div className="text-center">
              <div className="text-gray-400">Users</div>
              <div className="text-white">{roomInfo?.numOfUsersInRoom || '0'}</div>
            </div>
            <div className="text-center">
              <div className="text-gray-400">Tokens</div>
              <div className="text-white">{user?.tokens || '0'}</div>
            </div>
          </div>

          <div className="w-full max-w-xs mx-auto">
            <div className="text-center mb-2">Room Rating</div>
            <div className="text-sm text-red-400 mb-2 text-center">
              Songs below {minRating}% are skipped
            </div>
            <div className="w-full h-32">
              {(() => {
                if (roomRating === -1) {
                  return (
                    <GaugeComponent
                      type="semicircle"
                      arc={{
                        width: 0.4,
                        padding: 0,
                        cornerRadius: 0,
                        gradient: false,
                        subArcs: [
                          { limit: minRating, color: '#ff0000' },
                          { limit: 80, color: '#ffff00' },
                          { color: '#00ff00' }
                        ]
                      }}
                      pointer={{
                        type: 'arrow',
                        length: 0.85,
                        width: 15,
                        color: 'transparent',
                        baseColor: 'transparent',
                        elastic: false,
                        hide: true,
                        baseWidth: 25,
                        baseLength: 0.15,
                        stroke: 'transparent',
                        strokeWidth: 3
                      }}
                      labels={{
                        valueLabel: {
                          formatTextValue: value => "No ratings",
                          style: { 
                            fontSize: '28px',
                            fill: '#ffffff',
                            textShadow: '1px 1px 3px rgba(0,0,0,0.5)'
                          }
                        },
                        tickLabels: {
                          type: 'outer',
                          hideMinMax: true,
                          ticks: [
                            { 
                              value: minRating,
                              valueConfig: {
                                formatTextValue: value => `${value}%`,
                                style: { 
                                  fontSize: '16px',
                                  fill: '#ff6666',
                                  textShadow: '1px 1px 3px rgba(0,0,0,0.5)'
                                }
                              },
                              lineConfig: {
                                color: '#ff6666',
                                width: 2,
                                length: 8
                              }
                            }
                          ]
                        }
                      }}
                      value={0}
                      minValue={0}
                      maxValue={100}
                      style={{ width: '100%', height: '100%' }}
                    />
                  );
                }
                
                return (
                  <GaugeComponent
                    type="semicircle"
                    arc={{
                      width: 0.4,
                      padding: 0,
                      cornerRadius: 0,
                      gradient: false,
                      subArcs: [
                        { limit: minRating, color: '#ff0000' },
                        { limit: 80, color: '#ffff00' },
                        { color: '#00ff00' }
                      ]
                    }}
                    pointer={{
                      type: 'arrow',
                      length: 0.85,
                      width: 15,
                      color: pointerColor,
                      baseColor: '#888888',
                      elastic: false,
                      hide: false,
                      baseWidth: 25,
                      baseLength: 0.15,
                      stroke: '#ffffff',
                      strokeWidth: 3
                    }}
                    labels={{
                      valueLabel: {
                        formatTextValue: value => `${value}%`,
                        style: { 
                          fontSize: '24px',
                          fill: '#ffffff',
                          textShadow: '1px 1px 3px rgba(0,0,0,0.5)'
                        }
                      },
                      tickLabels: {
                        type: 'outer',
                        hideMinMax: true,
                        ticks: [
                          { 
                            value: minRating,
                            valueConfig: {
                              formatTextValue: value => `${value}%`,
                              style: { 
                                fontSize: '16px',
                                fill: '#ff6666',
                                textShadow: '1px 1px 3px rgba(0,0,0,0.5)'
                              }
                            },
                            lineConfig: {
                              color: '#ff6666',
                              width: 2,
                              length: 8
                            }
                          }
                        ]
                      }
                    }}
                    value={roomRating}
                    minValue={0}
                    maxValue={100}
                    style={{ width: '100%', height: '100%' }}
                  />
                );
              })()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
