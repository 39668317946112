import { createBrowserRouter } from 'react-router-dom';
import App from './App';
import Room from './components/Room';
import { Login } from './components/Login';
import { AuthCallback } from './components/AuthCallback';
import { AuthWrapper } from './components/AuthWrapper';
import { logDebug } from './services/debugService';

const debugRouter = (message, data = {}) => {
  logDebug('Router', message, data);
};

export const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      // Auth routes - must come before room routes
      {
        path: 'auth',
        children: [
          {
            path: 'callback',
            element: <AuthCallback />,
          },
          {
            path: 'twitch',
            element: <AuthCallback />,
          },
          {
            path: 'login',
            element: <Login />,
          },
        ],
      },
      // Room routes
      {
        index: true,
        element: <AuthWrapper><Room /></AuthWrapper>,
      },
      {
        path: 'room/:roomId',
        element: <AuthWrapper><Room /></AuthWrapper>,
      },
      // Direct room URL route (like /test) - handle direct room URLs
      {
        path: ':roomId',
        element: <AuthWrapper><Room /></AuthWrapper>,
      },
      // Catch-all redirect to home
      {
        path: '*',
        element: <AuthWrapper><Room /></AuthWrapper>,
      },
    ],
  },
]);

// Log route changes
router.subscribe((state) => {
  debugRouter('Route changed', {
    location: state.location,
    matches: state.matches
  });
});
