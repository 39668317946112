import { BACKEND_URL } from '../authService';
import apiClient from '../apiClient';

/**
 * Rating API service
 * Handles all rating-related API calls
 * Preparing for React Query migration
 */
export const ratingApi = {
  /**
   * Rate a track
   * @param {number} trackId - Track ID
   * @param {number} rating - Rating value (0-100)
   * @returns {Promise<Object>} Response data
   * @throws {Error} If request fails
   */
  rateTrack: async (trackId, rating) => {
    return await apiClient.get(`/room/rate?trackid=${trackId}&rating=${rating}`);
  },

  /**
   * Rate an artist
   * @param {string} artistName - Artist name
   * @param {number} rating - Rating value (0-100)
   * @returns {Promise<Object>} Response data
   * @throws {Error} If request fails
   */
  rateArtist: async (artistName, rating) => {
    return await apiClient.get(
      `/room/rateArtist?artist_name=${encodeURIComponent(artistName)}&rate=${rating}`
    );
  },

  /**
   * Rate an album
   * @param {Object} params - Album parameters
   * @param {string} params.albumKey - Album MusicBrainz ID
   * @param {string} params.albumName - Album name (not used by API)
   * @param {string} params.artistName - Artist name (not used by API)
   * @param {number} params.rating - Rating value (0-100)
   * @returns {Promise<Object>} Response data
   * @throws {Error} If request fails
   */
  rateAlbum: async ({ albumKey, albumName, artistName, rating }) => {
    // Log parameters before encoding
    console.log('Rating album with params:', { albumKey, rating });

    // Build URL with properly encoded parameters
    const url = new URLSearchParams();
    url.append('musicbrainz_id', albumKey);
    url.append('rate', rating);

    console.log('Album rating URL params:', url.toString());

    return await apiClient.get(`/room/rateAlbum?${url.toString()}`);
  }
};
