import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { useRoom } from '../../contexts/RoomContext';
import { useQueryClient } from '@tanstack/react-query';
import RoomSettingsDrawer from '../RoomSettings/RoomSettingsDrawer';
import { Header } from './Header';
import { ErrorDisplay } from './ErrorDisplay';
import { LoadingOverlay } from './LoadingOverlay';
import { MainContent } from './MainContent';
import { logDebug } from '../../services/debugService';
import { roomApi } from '../../services/api/roomApi';
import { ArtistRecommendations } from '../ArtistRecommendations';

const debugMusicLobby = (message, data = {}) => {
  logDebug('MusicLobby', message, data);
};

function MusicLobby({ roomId }) {
  const { user, logout } = useAuth();
  const { 
    currentRoom: roomInfo,
    queue: queueData,
    loading,
    error,
    joinRoomByName,
    updateRoom,
    skipSong,
    addToQueue,
    removeFromQueue,
    voteOnTrack,
    handleRating,
    moveTrackUp,
    moveTrackDown,
    moveTrackToTop,
    refetchRoom
  } = useRoom();
  
  const queryClient = useQueryClient();
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [isYoutubeMode, setIsYoutubeMode] = useState(false);

  // Join room if we have a roomId
  React.useEffect(() => {
    if (roomId && user?.authenticated) {
      debugMusicLobby('Attempting to join room', { roomId });
      joinRoomByName(roomId).catch(error => {
        debugMusicLobby('Error joining room', { error: error.message });
      });
    }
  }, [roomId, user?.authenticated, joinRoomByName]);

  const handleToggleYoutube = () => {
    setIsYoutubeMode(!isYoutubeMode);
  };

  const handleQueueAction = useCallback(async (lid, action) => {
    if (!lid) {
      console.error('Invalid or missing library ID provided for queue action:', action);
      return;
    }

    if (!action || typeof action !== 'string' || action.trim() === '') {
      console.error('Invalid or missing action provided for library ID:', lid);
      return;
    }

    try {
      // Convert lid to number if it's a string
      const numericLid = typeof lid === 'string' ? parseInt(lid, 10) : lid;
      if (isNaN(numericLid)) {
        console.error('Invalid library ID:', lid);
        return;
      }

      switch (action.toLowerCase()) {
        case 'top':
          await roomApi.moveToTopInQueue(numericLid);
          break;
        case 'up':
          await roomApi.moveUpInQueue(numericLid);
          break;
        case 'down':
          await roomApi.moveDownInQueue(numericLid);
          break;
        case 'remove':
          await roomApi.removeFromQueue(numericLid);
          break;
        default:
          console.error('Unknown queue action:', action);
          return;
      }
      await refetchRoom();
    } catch (error) {
      console.error('Error handling queue action:', error);
    }
  }, [refetchRoom]);

  if (!user?.authenticated) {
    return null; // ProtectedRoute will handle the auth check
  }

  return (
    <div className="min-h-screen bg-[#121212] text-white">
      <Header
        roomName={roomInfo?.name}
        userName={user?.name}
        onLogout={logout}
        onSettingsOpen={() => setIsSettingsOpen(true)}
        onRating={handleRating}
        onAddToQueue={addToQueue}
      />
      {loading && <LoadingOverlay />}
      {error && <ErrorDisplay error={error} />}

      <div className="w-full">
        <MainContent
          roomInfo={roomInfo}
          queue={queueData}
          isYoutubeMode={isYoutubeMode}
          onToggleYoutube={handleToggleYoutube}
          onQueueAction={handleQueueAction}
          onAddToQueue={addToQueue}
          onRating={handleRating}
          showRelatedArtists
        />
      </div>

      <RoomSettingsDrawer
        isOpen={isSettingsOpen}
        onClose={() => setIsSettingsOpen(false)}
        roomInfo={roomInfo}
        onUpdateRoom={updateRoom}
      />
    </div>
  );
}

MusicLobby.propTypes = {
  roomId: PropTypes.string
};

export default MusicLobby;
