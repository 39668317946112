import { BACKEND_URL } from '../authService';
import apiClient from '../apiClient';

/**
 * Make an API request with proper error handling
 * @param {string} url - URL to fetch
 * @param {Object} options - Fetch options
 * @returns {Promise<Object>} Response data
 * @throws {Error} If request fails
 */
async function makeRequest(url, options = {}) {
  try {
    const response = await fetch(url, {
      ...options,
      credentials: 'include',
      headers: {
        ...options.headers,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });

    if (!response.ok) {
      const errorText = await response.text();
      console.error('API Error:', errorText);
      throw new Error(errorText || `API Error: ${response.status}`);
    }

    if (response.status === 204) {
      return null; // No content
    }

    return await response.json();
  } catch (error) {
    console.error('Request failed:', error);
    throw error;
  }
}

/**
 * Score and sort search results for better quality
 * @param {Array} results - Search results to score and sort
 * @param {string} query - Original search query
 * @param {string} type - Result type to prioritize
 * @returns {Array} Scored and sorted results
 */
function scoreAndSortResults(results, query, type = 'tracks') {
  if (!results || !results.length) return [];
  
  // Normalize the query for scoring
  const normalizedQuery = query.toLowerCase().trim();
  const queryWords = normalizedQuery.split(/\s+/);
  
  // Calculate scores for each result
  const scoredResults = results.map(item => {
    let score = 0;
    let matchQuality = 0;
    
    // Ensure the item has all necessary fields for rendering
    const result = { ...item };
    
    // Make sure track structure is consistent - keep original structure but standardize key fields
    // This is critical for making Add buttons work
    if (result.track) {
      // Preserve original track data
      result.track = { ...result.track };
    } else if (result.type === 'track') {
      // Create track structure if it doesn't exist but is a track
      result.track = {
        id: result.id,
        title: result.title,
        artist: result.artist,
        album: result.album_name || result.album,
        coverart: result.coverart,
        viewTrackID: result.viewTrackID || result.lid || result.libraryId,
      };
    }
    
    // Ensure essential fields exist for queue operations
    result.lid = result.lid || result.libraryId || result.viewTrackID || (result.track ? result.track.id : null);
    result.viewTrackID = result.viewTrackID || result.lid || (result.track ? result.track.id : null);
    
    // Extract useful properties from the result for scoring
    const title = (result.track?.title || result.title || '').toLowerCase();
    const artist = (result.track?.artist || result.artist || '').toLowerCase();
    const album = (result.track?.album_name || result.track?.album || result.album || '').toLowerCase();
    const itemType = result.type || 'track';
    
    // Award points for type match
    if ((type === 'tracks' && itemType === 'track') ||
        (type === 'albums' && itemType === 'album') ||
        (type === 'artists' && itemType === 'artist')) {
      score += 50;
    }
    
    // Award points for exact matches
    if (title === normalizedQuery) score += 100;
    if (artist === normalizedQuery) score += 90;
    if (album === normalizedQuery) score += 80;
    
    // Award points for partial matches
    if (title.includes(normalizedQuery)) score += 30;
    if (artist.includes(normalizedQuery)) score += 25;
    if (album.includes(normalizedQuery)) score += 20;
    
    // Check for word matches
    queryWords.forEach(word => {
      if (word.length > 1) { // Only check meaningful words
        if (title.includes(word)) score += 5;
        if (artist.includes(word)) score += 4;
        if (album.includes(word)) score += 3;
      }
    });
    
    // Award points for item quality
    if (result.rating) score += Math.min(result.rating * 5, 25);
    if (result.coverart || result.track?.coverart) score += 10;
    
    // Calculate match quality (used for sorting ties)
    matchQuality = (
      (title.includes(normalizedQuery) ? 3 : 0) +
      (artist.includes(normalizedQuery) ? 2 : 0) +
      (album.includes(normalizedQuery) ? 1 : 0)
    );
    
    return { ...result, _score: score, _matchQuality: matchQuality };
  });
  
  // Sort by score and then by match quality
  return scoredResults.sort((a, b) => {
    // First compare scores
    const scoreDiff = b._score - a._score;
    if (scoreDiff !== 0) return scoreDiff;
    
    // If scores are tied, compare match quality
    const qualityDiff = b._matchQuality - a._matchQuality;
    if (qualityDiff !== 0) return qualityDiff;
    
    // If still tied, prioritize by type then alphabetically
    if (a.type !== b.type) {
      const typePriority = { track: 3, album: 2, artist: 1 };
      return (typePriority[b.type] || 0) - (typePriority[a.type] || 0);
    }
    
    // Alphabetical by title/name as last resort
    const aName = a.track?.title || a.title || a.name || '';
    const bName = b.track?.title || b.title || b.name || '';
    return aName.localeCompare(bName);
  });
}

export const searchApi = {
  /**
   * Search for tracks, albums, or artists
   * @param {string} query - Search query
   * @param {string} type - Type of results to prioritize ('tracks', 'albums', 'artists')
   * @returns {Promise<Object>} Search results
   * @throws {Error} If request fails
   */
  search: async (query, type = 'tracks') => {
    console.log(' Searching for:', query, 'with type:', type);
    // Ensure query is properly encoded
    const safeQuery = encodeURIComponent(query);
    
    try {
      // The backend doesn't support type filtering directly, so we'll fetch all results
      // and filter/sort them on the client side
      const response = await apiClient.get(
        `/room/jsonsearch?search=${safeQuery}`
      );
      
      if (!response.data || !Array.isArray(response.data)) {
        console.error('Invalid search response format:', response);
        return [];
      }
      
      // Process and sort results for better quality
      const sortedResults = scoreAndSortResults(response.data, query, type);
      console.log(' Search results processed:', sortedResults.length);
      
      return sortedResults;
    } catch (error) {
      console.error('Search failed:', error);
      throw error;
    }
  },

  /**
   * Search for an album
   * @param {string} albumKey - Unique album identifier
   * @returns {Promise<Object>} Album details with tracks
   * @throws {Error} If request fails
   */
  searchAlbum: async (albumKey) => {
    if (!albumKey) throw new Error('Album key is required');
    
    // Handle different album key formats
    let formattedKey = albumKey;
    if (!albumKey.startsWith('alb.')) {
      // Check if it's a URL or contains an album ID
      const match = albumKey.match(/albums\/(alb\.\d+)/i);
      if (match) {
        formattedKey = match[1];
      }
    }

    try {
      const response = await apiClient.get(
        `/room/albumjson?musicbrainz_id=${encodeURIComponent(formattedKey)}`
      );
      return response.data;
    } catch (error) {
      console.error('Album search failed:', error);
      throw new Error(`Failed to load album details: ${error.message}`);
    }
  },

  /**
   * Search for an artist and their albums
   * @param {string} artistName - Artist name
   * @returns {Promise<Object>} Artist search results with albums array
   * @throws {Error} If request fails
   */
  searchArtist: async (artistName) => {
    if (!artistName) throw new Error('Artist name is required');
    
    try {
      // First search for the artist
      const searchResponse = await apiClient.get(
        `/room/jsonsearch?search=${encodeURIComponent(artistName)}&type=artists`
      );
      
      const artists = searchResponse.data;
      if (!artists || artists.length === 0) {
        throw new Error('Artist not found');
      }
      
      const artist = artists.find(a => a.name.toLowerCase() === artistName.toLowerCase()) || artists[0];
      
      // Then get their albums
      const albumsResponse = await apiClient.get(
        `/room/artistAlbums?artistId=${encodeURIComponent(artist.id || artist.mbid || artist.name)}`
      );
      
      return {
        ...artist,
        albums: albumsResponse.data || []
      };
    } catch (error) {
      console.error('Artist search failed:', error);
      throw new Error(`Failed to load artist details: ${error.message}`);
    }
  },

  /**
   * Get typeahead suggestions based on user input
   * @param {string} query - User input
   * @param {string} type - Type of results to prioritize
   * @returns {Promise<Array>} Array of suggestion objects
   * @throws {Error} If request fails
   */
  getTypeaheadSuggestions: async (query, type = 'tracks') => {
    if (!query || query.length < 2) return [];
    
    try {
      const response = await apiClient.get(
        `/room/typeahead?query=${encodeURIComponent(query)}`
      );
      
      if (!Array.isArray(response.data)) return [];
      
      // Add fixed type property to each suggestion
      const suggestions = response.data.map(suggestion => ({
        ...suggestion,
        type: 'track', // Backend always returns tracks for typeahead
      }));
      
      // Log the complete data we're receiving from backend for debugging
      console.log('Typeahead raw data:', suggestions);
      
      return suggestions;
    } catch (error) {
      console.error('Typeahead request failed:', error);
      return []; // Return empty array on error for graceful degradation
    }
  }
};
