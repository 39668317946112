import { useState, useEffect } from 'react';
import * as Slider from '@radix-ui/react-slider';
import { QuestionMarkCircledIcon } from '@radix-ui/react-icons';
import { useRoom } from '../../../contexts/RoomContext';
import { BACKEND_URL } from '../../../services/authService';
import { RecommendationDocsDialog } from './RecommendationDocsDialog';

export default function RatingAdjustments() {
  const { currentRoom, updateRoom } = useRoom();
  const [balanceValue, setBalanceValue] = useState(currentRoom?.roomAgeAdjustment ?? 50);
  const [flowValue, setFlowValue] = useState(currentRoom?.roomFlowAdjustment ?? 50);
  const [showDocs, setShowDocs] = useState(false);

  // Initialize values from currentRoom
  useEffect(() => {
    if (currentRoom?.roomAgeAdjustment !== undefined) {
      console.log('Setting balance to:', currentRoom.roomAgeAdjustment);
      setBalanceValue(currentRoom.roomAgeAdjustment);
    }
    if (currentRoom?.roomFlowAdjustment !== undefined) {
      console.log('Setting flow to:', currentRoom.roomFlowAdjustment);
      setFlowValue(currentRoom.roomFlowAdjustment);
    }
  }, [currentRoom?.id, currentRoom?.roomAgeAdjustment, currentRoom?.roomFlowAdjustment]);

  const handleBalanceChange = async ([value]) => {
    setBalanceValue(value);
  };

  const handleBalanceCommit = async (value) => {
    try {
      const url = `${BACKEND_URL}/room/setRoomAgeAdjustment`;
      console.log('Sending request to:', url);
      
      const response = await fetch(url, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: `roomAgeAdjustment=${value}`
      });

      console.log('Response status:', response.status);
      const text = await response.text();
      console.log('Response text:', text);

      if (!response.ok) {
        throw new Error(`Failed to update balance: ${text}`);
      }

      try {
        const data = JSON.parse(text);
        console.log('Successfully parsed response:', data);
        updateRoom({
          ...currentRoom,
          roomAgeAdjustment: value
        });
      } catch (e) {
        console.error('Error parsing JSON:', e);
      }
    } catch (error) {
      console.error('Error updating balance:', error);
    }
  };

  const handleFlowChange = async ([value]) => {
    setFlowValue(value);
  };

  const handleFlowCommit = async (value) => {
    try {
      const url = `${BACKEND_URL}/room/setRoomFlowAdjustment`;
      console.log('Sending request to:', url);
      
      const response = await fetch(url, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: `roomFlowAdjustment=${value}`
      });

      console.log('Response status:', response.status);
      const text = await response.text();
      console.log('Response text:', text);

      if (!response.ok) {
        throw new Error(`Failed to update flow: ${text}`);
      }

      try {
        const data = JSON.parse(text);
        console.log('Successfully parsed response:', data);
        updateRoom({
          ...currentRoom,
          roomFlowAdjustment: value
        });
      } catch (e) {
        console.error('Error parsing JSON:', e);
      }
    } catch (error) {
      console.error('Error updating flow:', error);
    }
  };

  const getBalanceLabel = (value) => {
    if (value <= 40) return 'Rating Focus';
    if (value <= 60) return 'Balanced';
    return 'Variety Focus';
  };

  const getFlowLabel = (value) => {
    if (value <= 40) return 'Fresh Mix';
    if (value <= 60) return 'Balanced';
    return 'Flow Focus';
  };

  return (
    <div className="space-y-4">
      <div className="flex items-center justify-between">
        <h3 className="text-lg font-medium text-white">Song Selection Settings</h3>
        <button
          onClick={() => setShowDocs(true)}
          className="p-1 rounded-full hover:bg-[#3E3E3E] focus:outline-none focus:ring-2 focus:ring-white focus:ring-opacity-50"
          aria-label="View documentation"
        >
          <QuestionMarkCircledIcon className="h-5 w-5 text-gray-400" />
        </button>
      </div>

      <div className="p-4 rounded-lg bg-[#2E2E2E] border border-gray-700 space-y-6">
        {/* Balance Slider */}
        <div className="space-y-2">
          <div className="flex justify-between items-center">
            <label className="text-sm font-medium text-gray-300">
              Variety vs. Rating Balance - {getBalanceLabel(balanceValue)}
            </label>
            <span className="text-sm font-medium text-gray-300 w-12 text-right">
              {balanceValue}%
            </span>
          </div>
          
          <div className="flex items-center gap-4">
            <Slider.Root
              className="relative flex items-center select-none touch-none w-full h-5"
              value={[balanceValue]}
              onValueChange={handleBalanceChange}
              onValueCommit={([value]) => handleBalanceCommit(value)}
              min={0}
              max={100}
              step={1}
              aria-label="Balance"
            >
              <Slider.Track className="bg-[#1E1E1E] relative grow rounded-full h-[3px]">
                <Slider.Range className="absolute bg-gradient-to-r from-blue-500 via-purple-500 to-green-500 rounded-full h-full transition-all duration-300 ease-in-out" />
              </Slider.Track>
              <Slider.Thumb
                className="block w-5 h-5 bg-white rounded-full shadow-lg hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-white focus:ring-opacity-50 transition-transform duration-300 ease-in-out"
              />
            </Slider.Root>
          </div>
          <div className="flex justify-between text-xs text-gray-400">
            <span>Favor Ratings</span>
            <span>Favor Variety</span>
          </div>
        </div>

        {/* Flow Slider */}
        <div className="space-y-2">
          <div className="flex justify-between items-center">
            <label className="text-sm font-medium text-gray-300">
              Flow Setting - {getFlowLabel(flowValue)}
            </label>
            <span className="text-sm font-medium text-gray-300 w-12 text-right">
              {flowValue}%
            </span>
          </div>
          
          <div className="flex items-center gap-4">
            <Slider.Root
              className="relative flex items-center select-none touch-none w-full h-5"
              value={[flowValue]}
              onValueChange={handleFlowChange}
              onValueCommit={([value]) => handleFlowCommit(value)}
              min={0}
              max={100}
              step={1}
              aria-label="Flow"
            >
              <Slider.Track className="bg-[#1E1E1E] relative grow rounded-full h-[3px]">
                <Slider.Range className="absolute bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 rounded-full h-full transition-all duration-300 ease-in-out" />
              </Slider.Track>
              <Slider.Thumb
                className="block w-5 h-5 bg-white rounded-full shadow-lg hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-white focus:ring-opacity-50 transition-transform duration-300 ease-in-out"
              />
            </Slider.Root>
          </div>
          <div className="flex justify-between text-xs text-gray-400">
            <span>Fresh Mix</span>
            <span>Follow the Flow</span>
          </div>
        </div>
      </div>

      <RecommendationDocsDialog open={showDocs} onClose={() => setShowDocs(false)} />
    </div>
  );
}
