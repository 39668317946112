import React, { useState, useEffect, useCallback } from 'react';
import { RatingStars } from './RatingStars';
import { Button } from '../components/ui/Button';
import { SearchDialog } from './MusicLobby/SearchDialog';
import { queueApi } from '../services/api';
import { useAuth } from '../contexts/AuthContext';
import { QuestionMarkCircledIcon } from '@radix-ui/react-icons';
import { RecommendationDebug } from './RecommendationDebug';
import { DefaultAlbumArt } from './common/DefaultAlbumArt';
import { logDebug } from '../services/debugService';
import { getStarCount } from '../utils/ratingUtils';

export function QueueList({ queue = [], onVote, onRemove, onRate, onAlbumClick, onArtistClick, isUserSong = false, roomInfo }) {
  const { user } = useAuth();
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [debugLid, setDebugLid] = useState(null);
  const [currentUsername, setCurrentUsername] = useState(user?.username || user?.login);

  useEffect(() => {
    const username = user?.username || user?.login;
    if (username !== currentUsername) {
      setCurrentUsername(username);
    }
  }, [user]);

  useEffect(() => {
    console.log('QueueList props:', {
      isUserSong,
      isUserSongType: typeof isUserSong,
      roomInfo,
      hasRoomInfo: !!roomInfo,
      roomInfoIsUserSong: roomInfo?.isUserSong,
      currentUsername,
      queue: queue.map(item => ({
        id: item.id,
        lid: item.lid,
        track: {
          id: item.track?.id,
          lid: item.track?.lid
        }
      }))
    });
  }, [isUserSong, roomInfo, currentUsername, queue]);

  const checkUserPermissions = (itemUsername, connectedUsername, item) => {
    const normalizedItemUsername = itemUsername?.toLowerCase()?.trim();
    const normalizedConnectedUsername = connectedUsername?.toLowerCase()?.trim();
    return Boolean(normalizedItemUsername && normalizedConnectedUsername && normalizedItemUsername === normalizedConnectedUsername);
  };

  const canModifyItem = (item) => {
    const itemUsername = item.user?.username || item.username;
    const connectedUsername = user?.username || user?.login;
    const hasPermission = checkUserPermissions(itemUsername, connectedUsername, item);
    return isUserSong || hasPermission;
  };

  const isAtTopOfUserQueue = useCallback((item, index) => {
    if (!queue || !item) return false;
    
    const currentUser = user?.username || user?.login;
    if (!currentUser) return false;

    // Find the first item in the queue that belongs to the current user
    const firstUserItem = queue.find(qItem => 
      (qItem.user?.username || qItem.username)?.toLowerCase()?.trim() === currentUser.toLowerCase().trim()
    );

    // If this is the first user item, it's at the top of their queue
    return firstUserItem && (firstUserItem.lid === item.lid);
  }, [queue, user]);

  useEffect(() => {
    console.log('QueueList render:', {
      queueLength: queue?.length,
      hasQueue: Array.isArray(queue),
      isUserSong,
      hasHandlers: {
        onVote: !!onVote,
        onRemove: !!onRemove,
        onRate: !!onRate
      }
    });
  }, [queue, isUserSong, onVote, onRemove, onRate]);

  const handleAlbumClick = (item) => {
    if (onAlbumClick) {
      // Extract album info from track first, fall back to item properties
      const albumItem = {
        type: 'album',
        name: item.track?.album_name || item.album,
        album: item.track?.album_name || item.album,
        artist: item.track?.album_artist || item.track?.artist || item.artist,
        musicbrainzId: item.track?.album_musicbrainz_id || item.album_musicbrainz_id || item.track?.musicbrainzId,
        coverart: item.track?.album_coverart || item.coverart
      };
      
      // Only trigger if we have enough info to identify the album
      if (albumItem.musicbrainzId) {
        console.log('Creating album item:', albumItem);
        onAlbumClick(albumItem);
      } else {
        console.error('No musicbrainzId found for album:', item);
      }
    }
  };

  const handleArtistClick = (item) => {
    if (onArtistClick) {
      const artistItem = {
        type: 'artist',
        name: item.track?.artist,
        artist: item.track?.artist
      };
      console.log('Creating artist item:', artistItem);
      onArtistClick(artistItem);
    }
  };

  const handleRating = useCallback((rating, item) => {
    if (!item) {
      console.error('No item provided for rating');
      return;
    }
    
    const trackId = item.viewTrackID || item.track?.id;
    if (!trackId) {
      console.error('No track ID found for rating. Queue item:', item);
      return;
    }
    onRate?.(trackId, rating, 'track');
  }, [onRate]);

  if (!queue?.length) {
    return (
      <div className="bg-[#1A1A1A] rounded-lg p-6">
        <div className="flex items-center justify-between mb-4">
          <h2 className="text-2xl font-bold">Upcoming</h2>
          <Button onClick={() => setIsSearchOpen(true)}>Add Music</Button>
        </div>
        <p className="text-gray-400">No tracks in room queue</p>
        <SearchDialog 
          isOpen={isSearchOpen}
          onClose={() => setIsSearchOpen(false)}
          onRate={onRate}
          onAddToQueue={async (trackId) => {
            try {
              console.log('QueueList received trackId:', trackId, typeof trackId);
              // Handle both object and direct ID formats
              const libraryId = typeof trackId === 'object' ? trackId.lid : trackId;
              
              if (!libraryId) {
                console.error('Invalid track ID for queue add:', trackId);
                return;
              }
              
              console.log('QueueList calling queueAdd with:', libraryId);
              await queueApi.queueAdd(libraryId);
              setIsSearchOpen(false);
            } catch (error) {
              console.error('Failed to add track to queue:', error);
            }
          }}
        />
      </div>
    );
  }

  return (
    <div className="bg-[#1A1A1A] h-full flex flex-col">
      <div className="flex flex-col gap-4 p-4 flex-shrink-0">
        <div className="flex items-center justify-between">
          <h2 className="text-lg font-semibold">Upcoming</h2>
          <Button
            onClick={() => setIsSearchOpen(true)}
            className="px-3 py-1 text-sm bg-blue-600 hover:bg-blue-700"
          >
            Add Music
          </Button>
        </div>
      </div>
      {/* Desktop View - Only shown on extra large screens */}
      <div className="hidden xl:block px-4 overflow-y-auto flex-grow">
        <table className="w-full table-fixed">
          <thead className="sticky top-0 bg-[#1A1A1A] z-10">
            <tr className="text-left text-gray-400 border-b border-gray-800">
              <th className="p-2 w-[30%]">TITLE</th>
              <th className="p-2 w-[15%]">ARTIST</th>
              <th className="p-2 w-[15%]">ALBUM</th>
              <th className="p-2 text-center w-[10%]">RATING</th>
              <th className="hidden lg:table-cell p-2 text-center w-[10%]">SCORE</th>
              <th className="p-2 text-center w-[15%]">ACTIONS</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-800">
            {queue.map((item, index) => {
              const canModify = canModifyItem(item);
              
              return (
                <tr
                  key={item.lid || index}
                  className="hover:bg-[#282828] transition-colors"
                >
                  <td className="p-4">
                    <div className="flex flex-col md:flex-row gap-3">
                      <div className="md:hidden w-full">
                        <div className="text-white font-medium text-lg mb-2">
                          {item.track?.title}
                        </div>
                      </div>
                      <div className="flex items-center gap-3 w-full">
                        <div 
                          className="w-12 h-12 bg-[#282828] rounded overflow-hidden shrink-0 cursor-pointer hover:opacity-80 transition-opacity"
                          onClick={() => handleAlbumClick(item)}
                        >
                          <img
                            src={item.track?.album_baseicon || 
                              (item.track?.album_musicbrainz_id 
                                ? `https://coverartarchive.org/release/${item.track.album_musicbrainz_id}/front`
                                : DefaultAlbumArt.dataUrl)}
                            alt={`${item.track?.album || 'Album'} cover`}
                            className="w-full h-full object-cover"
                            crossOrigin="anonymous"
                            onError={(e) => {
                              console.log('Queue item cover art failed to load:', {
                                track: item.track,
                                album: item.track?.album,
                                album_baseicon: item.track?.album_baseicon,
                                album_musicbrainz_id: item.track?.album_musicbrainz_id
                              });
                              DefaultAlbumArt.onError(e);
                            }}
                          />
                        </div>
                        <div className="min-w-0">
                          <div className="hidden md:block text-white font-medium truncate">
                            {item.track?.title}
                          </div>
                          <div className="text-gray-400 text-sm italic">
                            Added by: {item.user?.firstname || item.firstname || item.user?.username || item.username || "Unknown User"}
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="p-4">
                    <div className="text-gray-400 truncate hover:text-white cursor-pointer" onClick={() => handleArtistClick(item)}>
                      {item.track?.artist}
                    </div>
                  </td>
                  <td className="hidden md:table-cell p-4">
                    <div className="text-gray-400 truncate hover:text-white cursor-pointer" onClick={() => handleAlbumClick(item)}>
                      {item.album || item.track?.album}
                    </div>
                  </td>
                  <td className="p-4">
                    <div className="flex justify-center items-center w-full">
                      <RatingStars
                        rating={item.rating === -1 ? 0 : getStarCount(item.rating)}
                        size={window.innerWidth < 768 ? 36 : 24}
                        readonly={false}
                        onChange={(newRating) => {
                          handleRating(newRating, item);
                        }}
                      />
                    </div>
                  </td>
                  <td className="hidden lg:table-cell p-4">
                    <div className="flex flex-col items-center justify-center w-full gap-1">
                      <div className="text-lg font-semibold">
                        {item.rating === -1 ? (
                          <span className="text-gray-400">Unrated</span>
                        ) : (
                          `${item.rating}%`
                        )}
                      </div>
                      {item.rating !== -1 && (
                        <div className={`text-sm ${
                          item.rating >= 80 ? 'text-green-400' :
                          item.rating >= (roomInfo?.roomminimumrating || 40) ? 'text-yellow-400' :
                          'text-red-400'
                        }`}>
                          {item.rating >= 80 ? 'Great' :
                           item.rating >= (roomInfo?.roomminimumrating || 40) ? 'OK' :
                           'Will Skip'}
                        </div>
                      )}
                    </div>
                  </td>
                  <td className="p-2 text-right">
                    <div className="inline-flex gap-1">
                      <div className="w-8 h-8">
                        {canModify && index > 0 && (
                          <button
                            onClick={() => {
                              const trackId = item.id || item.lid;
                              if (!trackId) {
                                console.error('No trackId found for queue action: top', item);
                                return;
                              }
                              onVote?.(trackId, 'top');
                            }}
                            className="w-8 h-8 inline-flex items-center justify-center text-white bg-[#333333] hover:bg-[#444444] rounded text-lg"
                            title="Move to Top"
                          >
                            ⤒
                          </button>
                        )}
                      </div>
                      <div className="w-8 h-8">
                        {canModify && !isAtTopOfUserQueue(item, index) && (
                          <button
                            onClick={() => {
                              const trackId = item.id || item.lid;
                              if (!trackId) {
                                console.error('No trackId found for queue action: up', item);
                                return;
                              }
                              onVote?.(trackId, 'up');
                            }}
                            className="w-8 h-8 inline-flex items-center justify-center text-white bg-[#333333] hover:bg-[#444444] rounded"
                            title="Move Up"
                          >
                            ↑
                          </button>
                        )}
                      </div>
                      <div className="w-8 h-8">
                        {canModify && index < queue.length - 1 && (
                          <button
                            onClick={() => {
                              const trackId = item.id || item.lid;
                              if (!trackId) {
                                console.error('No trackId found for queue action: down', item);
                                return;
                              }
                              onVote?.(trackId, 'down');
                            }}
                            className="w-8 h-8 inline-flex items-center justify-center text-white bg-[#333333] hover:bg-[#444444] rounded"
                            title="Move Down"
                          >
                            ↓
                          </button>
                        )}
                      </div>
                      <div className="w-8 h-8">
                        {canModify && (
                          <button
                            onClick={() => {
                              const lid = item.libraryItem?.lid || item.lid;
                              if (!lid) {
                                console.error('No library item ID found for queue action: remove', item);
                                return;
                              }
                              onRemove?.(lid.toString());
                            }}
                            className="w-8 h-8 inline-flex items-center justify-center text-white bg-red-600 hover:bg-red-700 rounded"
                            title="Remove"
                          >
                            ×
                          </button>
                        )}
                      </div>
                      <div className="w-8 h-8">
                        {(currentUsername?.toLowerCase() === 'eltmon' || canModify) && (
                          <a 
                            onClick={(e) => {
                              e.preventDefault();
                              setDebugLid(item.lid);
                            }}
                            href="#"
                            title="Debugging information for recommendation engine"
                            className="hidden md:inline-flex items-center justify-center w-8 h-8 text-gray-400 hover:text-white cursor-pointer"
                          >
                            <QuestionMarkCircledIcon className="w-4 h-4" />
                          </a>
                        )}
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      {/* Card View for Mobile, Tablet and Medium Desktop */}
      <div className="block xl:hidden divide-y divide-[#282828] overflow-y-auto flex-grow">
        {queue.map((item, index) => {
          const canModify = canModifyItem(item);
          
          return (
            <div 
              key={item.lid || index}
              className="flex flex-col px-4 py-3 hover:bg-[#282828] transition-colors"
            >
              <div className="text-white font-medium text-lg mb-2">
                {item.track?.title}
              </div>
              <div className="flex gap-3 mb-2">
                <div 
                  className="w-16 h-16 bg-[#282828] rounded overflow-hidden shrink-0 cursor-pointer hover:opacity-80 transition-opacity"
                  onClick={() => handleAlbumClick(item)}
                >
                  <img
                    src={item.track?.album_baseicon || 
                      (item.track?.album_musicbrainz_id 
                        ? `https://coverartarchive.org/release/${item.track.album_musicbrainz_id}/front`
                        : DefaultAlbumArt.dataUrl)}
                    alt={`${item.track?.album || 'Album'} cover`}
                    className="w-full h-full object-cover"
                    crossOrigin="anonymous"
                    onError={(e) => {
                      console.log('Queue item cover art failed to load:', {
                        track: item.track,
                        album: item.track?.album,
                        album_baseicon: item.track?.album_baseicon,
                        album_musicbrainz_id: item.track?.album_musicbrainz_id
                      });
                      DefaultAlbumArt.onError(e);
                    }}
                  />
                </div>
                <div className="flex flex-col min-w-0">
                  <div className="text-gray-400" onClick={() => handleArtistClick(item)}>
                    {item.track?.artist}
                  </div>
                  <div className="text-gray-400" onClick={() => handleAlbumClick(item)}>
                    {item.album || item.track?.album}
                  </div>
                  <div className="text-gray-400 text-sm italic">
                    Added by: {item.user?.firstname || item.firstname || item.user?.username || item.username || "Unknown User"}
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-2">
                <div className="flex justify-start items-center w-full">
                  <RatingStars
                    rating={item.rating === -1 ? 0 : getStarCount(item.rating)}
                    size={36}
                    readonly={false}
                    onChange={(rating) => handleRating(rating, item)}
                  />
                </div>
                {canModify && (
                  <div className="flex gap-1 touch-manipulation">
                    <div className="w-10 h-10">
                      {index > 0 ? (
                        <button
                          onClick={() => {
                            const trackId = item.id || item.lid;
                            if (!trackId) {
                              console.error('No trackId found for queue action: top', item);
                              return;
                            }
                            if (item.id) {
                              onVote?.(item.id, 'top');
                            } else {
                              onVote?.(item.lid, 'top');
                            }
                          }}
                          className="w-10 h-10 inline-flex items-center justify-center text-white bg-[#333333] hover:bg-[#444444] active:bg-[#555555] rounded text-lg font-bold"
                          title="Move to Top"
                        >
                          ⤒
                        </button>
                      ) : <div className="w-10 h-10" />}
                    </div>
                    <div className="w-10 h-10">
                      {index > 0 && !isAtTopOfUserQueue(item, index) ? (
                        <button
                          onClick={() => {
                            const trackId = item.id || item.lid;
                            if (!trackId) {
                              console.error('No trackId found for queue action: up', item);
                              return;
                            }
                            if (item.id) {
                              onVote?.(item.id, 'up');
                            } else {
                              onVote?.(item.lid, 'up');
                            }
                          }}
                          className="w-10 h-10 inline-flex items-center justify-center text-white bg-[#333333] hover:bg-[#444444] active:bg-[#555555] rounded text-lg font-bold"
                          title="Move Up"
                        >
                          ↑
                        </button>
                      ) : <div className="w-10 h-10" />}
                    </div>
                    <div className="w-10 h-10">
                      {index < queue.length - 1 ? (
                        <button
                          onClick={() => {
                            const trackId = item.id || item.lid;
                            if (!trackId) {
                              console.error('No trackId found for queue action: down', item);
                              return;
                            }
                            if (item.id) {
                              onVote?.(item.id, 'down');
                            } else {
                              onVote?.(item.lid, 'down');
                            }
                          }}
                          className="w-10 h-10 inline-flex items-center justify-center text-white bg-[#333333] hover:bg-[#444444] active:bg-[#555555] rounded text-lg font-bold"
                          title="Move Down"
                        >
                          ↓
                        </button>
                      ) : <div className="w-10 h-10" />}
                    </div>
                    <div className="w-10 h-10">
                      <button
                        onClick={() => {
                          const lid = item.libraryItem?.lid || item.lid;
                          if (!lid) {
                            console.error('No library item ID found for queue action: remove', item);
                            return;
                          }
                          onRemove?.(lid.toString());
                        }}
                        className="w-10 h-10 inline-flex items-center justify-center text-white bg-red-600 hover:bg-red-700 active:bg-red-800 rounded text-lg font-bold"
                        title="Remove"
                      >
                        ×
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
      <SearchDialog 
        isOpen={isSearchOpen}
        onClose={() => setIsSearchOpen(false)}
        onRate={onRate}
        onAddToQueue={async (trackId) => {
          try {
            console.log('QueueList received trackId:', trackId, typeof trackId);
            // Handle both object and direct ID formats
            const libraryId = typeof trackId === 'object' ? trackId.lid : trackId;
            
            if (!libraryId) {
              console.error('Invalid track ID for queue add:', trackId);
              return;
            }
            
            console.log('QueueList calling queueAdd with:', libraryId);
            await queueApi.queueAdd(libraryId);
            setIsSearchOpen(false);
          } catch (error) {
            console.error('Failed to add track to queue:', error);
          }
        }}
      />
      
      {debugLid && (
        <RecommendationDebug
          lid={debugLid}
          onClose={() => setDebugLid(null)}
        />
      )}
    </div>
  );
}
