import { useState, useCallback } from 'react';
import { searchApi } from '../../services/api/searchApi';

export function useAlbumDetails() {
  const [selectedAlbum, setSelectedAlbum] = useState(null);
  const [tracks, setTracks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const loadAlbumDetails = useCallback(async (album) => {
    if (!album) return;
    
    console.log('Loading album details:', album);
    
    // If it's the same album, don't reload
    if (selectedAlbum?.musicbrainzId === album.musicbrainzId && selectedAlbum?.artist === album.artist) {
      console.log('Album already selected, skipping reload');
      return;
    }
    
    setIsLoading(true);
    setError(null);

    try {
      let results;
      
      // First try direct album lookup if we have an ID
      if (album.musicbrainzId) {
        results = await searchApi.searchAlbum(album.musicbrainzId);
      }
      // Otherwise search by name and artist
      else if (album.album && album.artist) {
        console.log(`Searching for album: ${album.album} by ${album.artist}`);
        throw new Error('Album lookup requires a valid MusicBrainz UUID');
      }
      else {
        throw new Error('No valid album ID or search terms found');
      }

      if (!results || !results.libraryItems || results.libraryItems.length === 0) {
        throw new Error('No results found for album');
      }

      setSelectedAlbum({
        type: 'album',
        id: results.id,
        musicbrainzId: results.musicbrainzId,
        name: results.album?.name || album.name || album.album,
        album: results.album?.name || album.name || album.album,
        artist: results.artist,
        albumRating: results.albumRating || results.album?.album_rating,
        coverart: results.coverart || results.album?.baseicon
      });
      
      setTracks(results.libraryItems || []);
    } catch (err) {
      console.error('Failed to fetch album details:', err);
      setError(err.message || 'Failed to fetch album details. Please try again.');
      setTracks([]);
    } finally {
      setIsLoading(false);
    }
  }, [selectedAlbum]);

  const reset = useCallback(() => {
    setSelectedAlbum(null);
    setTracks([]);
    setError(null);
  }, []);

  return {
    selectedAlbum,
    tracks,
    isLoading,
    error,
    loadAlbumDetails,
    reset
  };
}
