import React, { useEffect, useState } from 'react';
import { BACKEND_URL } from '../services/authService';
import * as Dialog from '@radix-ui/react-dialog';
import { Box, Card, Flex, Grid, Heading, Text } from '@radix-ui/themes';

export function RecommendationDebug({ lid, onClose }) {
  const [debugInfo, setDebugInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchDebugInfo = async () => {
      try {
        const response = await fetch(`${BACKEND_URL}/api/debug/recommendation/${lid}`, {
          credentials: 'include'
        });
        
        if (response.ok) {
          const data = await response.json();
          console.log('Debug info:', data);
          setDebugInfo(data);
        } else {
          setError('Failed to fetch debug information');
        }
      } catch (error) {
        setError('Error fetching debug information: ' + error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchDebugInfo();
  }, [lid]);

  if (loading) {
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
        <div className="bg-[#1A1A1A] p-6 rounded-lg max-w-2xl w-full mx-4">
          <div className="text-center">Loading debug information...</div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
        <div className="bg-[#1A1A1A] p-6 rounded-lg max-w-2xl w-full mx-4">
          <div className="text-red-500">{error}</div>
          <button 
            onClick={onClose}
            className="mt-4 px-4 py-2 bg-blue-600 hover:bg-blue-700 rounded"
          >
            Close
          </button>
        </div>
      </div>
    );
  }

  if (!debugInfo || !debugInfo.recommendations || debugInfo.recommendations.length === 0) {
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
        <div className="bg-[#1A1A1A] p-6 rounded-lg max-w-2xl w-full mx-4">
          <div className="text-center">No debug information available</div>
          <button 
            onClick={onClose}
            className="mt-4 px-4 py-2 bg-blue-600 hover:bg-blue-700 rounded"
          >
            Close
          </button>
        </div>
      </div>
    );
  }

  return (
    <Dialog.Root open={true} onOpenChange={() => onClose()}>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 bg-black/50 backdrop-blur-sm" />
        <Dialog.Content className="fixed left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] bg-[#1A1A1A] text-white rounded-lg p-6 w-[95vw] max-h-[90vh] overflow-y-auto focus:outline-none border border-[#333] shadow-xl">
          <Flex justify="between" align="center" mb="6" className="border-b border-[#333] pb-4">
            <Dialog.Title className="text-2xl font-medium tracking-tight text-white">
              Recommendation Debug Info
            </Dialog.Title>
            <Dialog.Close className="text-gray-400 hover:text-white transition-colors">
              <span className="sr-only">Close</span>
              <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </Dialog.Close>
          </Flex>

          <div className="space-y-6">
            {/* Source Track Info */}
            <div className="bg-[#252525] p-4 rounded-md border border-[#333]">
              <Heading size="3" className="text-lg font-medium text-gray-200 mb-4">Source Track</Heading>
              <div className="grid grid-cols-1 gap-x-6 gap-y-2">
                <div className="flex items-baseline">
                  <Text className="text-sm font-medium text-gray-400 min-w-[140px]">Title:</Text>
                  <Text className="text-base font-medium text-white">{debugInfo.sourceTrackTitle}</Text>
                </div>
                <div className="flex items-baseline">
                  <Text className="text-sm font-medium text-gray-400 min-w-[140px]">Artist:</Text>
                  <Text className="text-base font-medium text-white">{debugInfo.sourceTrackArtist}</Text>
                </div>
                <div className="flex items-baseline">
                  <Text className="text-sm font-medium text-gray-400 min-w-[140px]">Album:</Text>
                  <Text className="text-base font-medium text-white">{debugInfo.sourceTrackAlbum}</Text>
                </div>
              </div>
            </div>

            {/* Recommendation Log Summary */}
            <div className="bg-[#252525] p-4 rounded-md border border-[#333]">
              <div className="grid grid-cols-2 gap-x-6 gap-y-2">
                <div className="flex items-baseline">
                  <Text className="text-sm font-medium text-gray-400 min-w-[140px]">Room:</Text>
                  <Text className="text-base font-medium text-white">{debugInfo.roomName}</Text>
                </div>
                <div className="flex items-baseline">
                  <Text className="text-sm font-medium text-gray-400 min-w-[140px]">Age Adjustment:</Text>
                  <Text className="text-base font-medium text-white">{debugInfo.roomAgeAdjustment}</Text>
                </div>
                <div className="flex items-baseline">
                  <Text className="text-sm font-medium text-gray-400 min-w-[140px]">Flow Adjustment:</Text>
                  <Text className="text-base font-medium text-white">{debugInfo.roomFlowAdjustment}</Text>
                </div>
                <div className="flex items-baseline">
                  <Text className="text-sm font-medium text-gray-400 min-w-[140px]">Environment:</Text>
                  <Text className="text-base font-medium text-white">{debugInfo.environment}</Text>
                </div>
                <div className="flex items-baseline">
                  <Text className="text-sm font-medium text-gray-400 min-w-[140px]">Processing Time:</Text>
                  <Text className="text-base font-medium text-white">
                    {debugInfo.timeToProcess}ms <span className="text-sm text-gray-400">({debugInfo.secondsToProcess}s)</span>
                  </Text>
                </div>
                <div className="flex items-baseline">
                  <Text className="text-sm font-medium text-gray-400 min-w-[140px]">Source Track:</Text>
                  <Text className="text-base font-medium text-white">
                    {debugInfo.sourceTrackTitle} by {debugInfo.sourceTrackArtist}
                  </Text>
                </div>
                <div className="flex items-baseline">
                  <Text className="text-sm font-medium text-gray-400 min-w-[140px]">Source Album:</Text>
                  <Text className="text-base font-medium text-white">{debugInfo.sourceTrackAlbum}</Text>
                </div>
                <div className="flex items-baseline">
                  <Text className="text-sm font-medium text-gray-400 min-w-[140px]">Source Ratings:</Text>
                  <Text className="text-base font-medium text-white">
                    User: {debugInfo.sourceTrackRating}, Room: {debugInfo.sourceTrackRoomRating}
                  </Text>
                </div>
                <div className="flex items-baseline">
                  <Text className="text-sm font-medium text-gray-400 min-w-[140px]">Source Last Played:</Text>
                  <Text className="text-base font-medium text-white">
                    {debugInfo.sourceTrackLastPlayed ? new Date(debugInfo.sourceTrackLastPlayed).toLocaleString() : 'Never'}
                  </Text>
                </div>
                <div className="flex items-baseline">
                  <Text className="text-sm font-medium text-gray-400 min-w-[140px]">Recommendations:</Text>
                  <Text className="text-base font-medium text-white">
                    {debugInfo.recommendationsProcessed} processed / {debugInfo.recommendationsAvailable} available
                  </Text>
                </div>
                <div className="flex items-baseline">
                  <Text className="text-sm font-medium text-gray-400 min-w-[140px]">DMCA + Playability:</Text>
                  <Text className="text-base font-medium text-white">
                    {debugInfo.recommendationsScanned} checks
                  </Text>
                </div>
                <div className="flex items-baseline col-span-2">
                  <Text className="text-sm font-medium text-gray-400 min-w-[140px]">Status:</Text>
                  <Text className="text-base font-medium text-emerald-400">
                    Found {debugInfo.recommendations.length} playable tracks from {debugInfo.recommendationsAvailable} recommendations
                  </Text>
                </div>
              </div>
            </div>

            {/* Recommendations Table */}
            <div>
              <Heading size="3" className="text-lg font-medium text-gray-200 mb-4">Recommendations</Heading>
              <div className="relative overflow-x-auto rounded-lg border border-[#333]">
                <table className="w-full text-sm text-left">
                  <thead className="text-xs uppercase bg-[#252525] text-gray-400 border-b border-[#333]">
                    <tr>
                      <th scope="col" className="px-4 py-3 font-medium">#</th>
                      <th scope="col" className="px-4 py-3 font-medium">Track</th>
                      <th scope="col" className="px-4 py-3 font-medium">Artist</th>
                      <th scope="col" className="px-4 py-3 font-medium">Album</th>
                      <th scope="col" className="px-4 py-3 font-medium text-right">User Rating</th>
                      <th scope="col" className="px-4 py-3 font-medium text-right">Room Rating</th>
                      <th scope="col" className="px-4 py-3 font-medium text-right">Artist Rating</th>
                      <th scope="col" className="px-4 py-3 font-medium text-right">Days Since</th>
                      <th scope="col" className="px-4 py-3 font-medium text-right">Times Rec.</th>
                      <th scope="col" className="px-4 py-3 font-medium text-right">Score</th>
                    </tr>
                  </thead>
                  <tbody>
                    {debugInfo.recommendations.map((rec, index) => (
                      <tr 
                        key={index} 
                        className={`border-b border-[#333] ${
                          rec.title.toLowerCase() === debugInfo.sourceTrackTitle.toLowerCase() && 
                          rec.artistName.toLowerCase() === debugInfo.sourceTrackArtist.toLowerCase() && 
                          rec.albumName.toLowerCase() === debugInfo.sourceTrackAlbum.toLowerCase()
                            ? 'bg-blue-900 hover:bg-blue-800'
                            : index % 2 === 0 
                              ? 'bg-[#1D1D1D] hover:bg-[#2A2A2A]' 
                              : 'bg-[#222] hover:bg-[#2A2A2A]'
                        }`}
                      >
                        <td className="px-4 py-3 font-medium text-gray-300">{index + 1}</td>
                        <td className="px-4 py-3 font-medium text-white">{rec.title}</td>
                        <td className="px-4 py-3 text-gray-300">{rec.artistName}</td>
                        <td className="px-4 py-3 text-gray-300">{rec.albumName}</td>
                        <td className="px-4 py-3 text-right text-gray-300">{rec.userRating}</td>
                        <td className="px-4 py-3 text-right text-gray-300">{rec.roomRating}</td>
                        <td className="px-4 py-3 text-right text-gray-300">{rec.userArtistRating}</td>
                        <td className="px-4 py-3 text-right text-gray-300">{rec.daysSinceLibraryItemPlayed}</td>
                        <td className="px-4 py-3 text-right text-gray-300">{rec.numberOfTimesRecommended}</td>
                        <td className="px-4 py-3 text-right font-medium text-white">{rec.calcScore.toFixed(2)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
