import React, { useState, useEffect, useCallback } from 'react';
import { RoomInfo } from '../../RoomInfo';
import { NowPlaying } from '../../NowPlaying';
import { QueueList } from '../../QueueList';
import { SearchDialog } from '../SearchDialog';
import { DefaultAlbumArt } from '../../common/DefaultAlbumArt';
import { logDebug } from '../../../services/debugService';
import { TrackRecommendations } from '../../TrackRecommendations';
import { ArtistRecommendations } from '../../ArtistRecommendations';

const debugMainContent = (message, data = {}) => {
  logDebug('MainContent', message, data);
};

const defaultAlbumArt = DefaultAlbumArt.src;

export function MainContent({
  roomInfo,
  queue,
  onQueueAction,
  onAddToQueue,
  onRating,
  onImageError = DefaultAlbumArt.onError,
  showRelatedArtists = true
}) {
  const [isYoutubeMode, setIsYoutubeMode] = useState(roomInfo?.roommode === 1);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  debugMainContent('Rendering with props', {
    hasRoomInfo: !!roomInfo,
    currentTrack: roomInfo?.currentTrack,
    libraryItem: roomInfo?.currentTrack?.libraryItem,
    hasHandlers: {
      rating: !!onRating
    }
  });

  useEffect(() => {
    // Update YouTube mode when room mode changes
    if (roomInfo?.roommode !== undefined) {
      setIsYoutubeMode(roomInfo.roommode === 1);
    }
  }, [roomInfo?.roommode]);

  const handleToggleYoutube = () => {
    setIsYoutubeMode(!isYoutubeMode);
  };

  const handleYoutubeReady = (player) => {
    console.log('YouTube player ready:', player);
  };

  const handleImageError = (e) => {
    e.target.src = defaultAlbumArt;
    e.target.classList.add('placeholder');
  };

  const handleAlbumClick = (album) => {
    console.log('Album clicked:', album);
    setSelectedItem(album);
    setIsSearchOpen(true);
  };

  const handleArtistClick = (artist) => {
    console.log('Artist clicked:', artist);
    setSelectedItem(artist);
    setIsSearchOpen(true);
  };

  const handleRating = useCallback((id, rating, type = 'track') => {
    if (!roomInfo?.currentTrack) {
      debugMainContent('No current track available');
      return;
    }

    debugMainContent('Handling rating', { 
      id, 
      rating, 
      type,
      currentTrack: roomInfo.currentTrack,
      artist: roomInfo.currentTrack?.track?.artist,
      albumKey: roomInfo.currentTrack?.track?.albumkey
    });
    if (!id) {
      debugMainContent('No ID provided');
      return;
    }
    onRating?.(id, rating, type);
  }, [onRating, roomInfo]);

  // Add a function to fetch album rating directly
  const fetchAlbumRating = async (mbid) => {
    try {
      console.log('Fetching album rating for MBID:', mbid);
      const response = await fetch(`https://auricle.ngrok.io/room/album?musicbrainz_id=${mbid}`);
      const data = await response.json();
      console.log('Album data response:', data);
      return data.rating || 0;
    } catch (error) {
      console.error('Error fetching album rating:', error);
      return 0;
    }
  };

  return (
    <div className="h-full w-full p-4 relative">
      {/* Using pure Tailwind classes for better compatibility */}
      <div className="grid grid-cols-1 xl:grid-cols-[350px_minmax(0,1fr)] gap-6">
        {/* Left Column - Room Info & Current Song */}
        <div className="flex flex-col gap-6 xl:max-w-[350px]">
          <RoomInfo roomInfo={roomInfo} />

          {roomInfo?.currentTrack && (
            <div className="bg-[#1A1A1A] rounded-lg">
              <h2 className="text-2xl font-bold p-6 text-center">Now Playing</h2>
              <div className="p-6 pt-0">
                <div className="flex justify-center">
                  <NowPlaying 
                    currentTrack={roomInfo.currentTrack}
                    isYoutubeMode={isYoutubeMode}
                    onToggleYoutube={handleToggleYoutube}
                    position={roomInfo.currentTrack?.position}
                    startedAt={roomInfo.currentTrack?.startedAt}
                    onAlbumClick={handleAlbumClick}
                    onArtistClick={handleArtistClick}
                    onRating={(id, rating, type) => handleRating(id, rating, type)}
                  />
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Right Column - Queue */}
        <div className="flex-1">
          <div className="flex flex-col gap-4">
            <div className="bg-[#1A1A1A] rounded-lg overflow-hidden">
              {console.log('MainContent - QueueList props:', {
                roomInfo,
                isUserSong: roomInfo?.isUserSong
              })}
              <QueueList
                queue={queue}
                onVote={(trackId, action) => onQueueAction(trackId, action)}
                onRemove={(trackId) => onQueueAction(trackId, 'remove')}
                onRate={handleRating}
                onAlbumClick={handleAlbumClick}
                onArtistClick={handleArtistClick}
                isUserSong={roomInfo?.isUserSong}
                roomInfo={roomInfo}
              />
            </div>
            
            {/* Related Artists section - positioned directly under Queue */}
            {showRelatedArtists && (
              <div className="bg-[#1A1A1A] rounded-lg">
                <h2 className="text-xl font-semibold p-4 border-b border-gray-700">Related Artists</h2>
                <div className="p-1"> 
                  <ArtistRecommendations />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <TrackRecommendations 
        onRate={handleRating}
        onAddToQueue={onAddToQueue}
      />

      <SearchDialog 
        isOpen={isSearchOpen}
        onClose={() => {
          setIsSearchOpen(false);
          setSelectedItem(null);
        }}
        onRate={handleRating}
        onAddToQueue={onAddToQueue}
        initialSearchItem={selectedItem}
      />
    </div>
  );
}
