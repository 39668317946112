import React, { useEffect, useCallback, useState, useRef } from 'react';
import { Dialog, DialogContent, DialogOverlay, DialogTitle, DialogClose } from '@radix-ui/react-dialog';
import { X, ChevronLeft, Search, Info, AlertCircle } from 'lucide-react';
import { SearchResultItem } from './SearchResults/SearchResultItem';
import { AlbumDetailView } from './SearchResults/DetailViews/AlbumDetailView';
import { ArtistDetailView } from './SearchResults/DetailViews/ArtistDetailView';
import { useSearch } from '../../hooks/useSearch/useSearch';
import { useArtistDetails } from '../../hooks/useSearch/useArtistDetails';
import { useAlbumDetails } from '../../hooks/useSearch/useAlbumDetails';
import { useRating } from '../../hooks/useSearch/useRating';
import { searchApi } from '../../services/api/searchApi';

export function SearchDialog({ isOpen, onClose, onRate, onAddToQueue, initialSearchItem }) {
  // Search state
  const [activeFilter, setActiveFilter] = useState('tracks');
  const [searchQuery, setSearchQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [selectedSuggestion, setSelectedSuggestion] = useState(-1);
  
  // Refs
  const inputRef = useRef(null);
  const suggestionsRef = useRef(null);
  const searchTimeoutRef = useRef(null);
  
  // Custom hook for search functionality
  const { 
    results, 
    isSearching, 
    error: searchError, 
    clearSearch,
    hasActiveSearch,
    performSearch
  } = useSearch({ type: activeFilter });

  // Artist view state
  const {
    selectedArtist,
    albums,
    isLoading: isLoadingArtist,
    error: artistError,
    loadArtistDetails,
    reset: resetArtist
  } = useArtistDetails();

  // Album view state
  const {
    selectedAlbum,
    tracks,
    isLoading: isLoadingAlbum,
    error: albumError,
    loadAlbumDetails,
    reset: resetAlbum
  } = useAlbumDetails();

  // Rating handlers
  const { handleTrackRate, handleArtistRate, handleAlbumRate } = useRating();

  // Handle filter change (track/album/artist)
  const handleFilterChange = useCallback((filter) => {
    setActiveFilter(filter);
    // If we have an active search query, perform search with new filter
    if (searchQuery.trim()) {
      performSearch(searchQuery, filter);
    }
  }, [searchQuery, performSearch]);

  // Handle search submission
  const handleSearchSubmit = useCallback(() => {
    if (searchQuery.trim()) {
      // Clear typeahead state
      setShowSuggestions(false);
      setSuggestions([]);
      setSelectedSuggestion(-1);
      
      // Perform the actual search
      performSearch(searchQuery, activeFilter);
      
      // Blur input to dismiss mobile keyboard
      if (inputRef.current) {
        inputRef.current.blur();
      }
    }
  }, [searchQuery, activeFilter, performSearch]);
  
  // Typeahead functionality
  useEffect(() => {
    if (!searchQuery || searchQuery.trim().length < 2) {
      setSuggestions([]);
      setShowSuggestions(false);
      return;
    }
    
    // Clear previous timeout
    if (searchTimeoutRef.current) {
      clearTimeout(searchTimeoutRef.current);
    }
    
    // Set a timeout to avoid fetching on every keystroke
    searchTimeoutRef.current = setTimeout(async () => {
      try {
        const results = await searchApi.getTypeaheadSuggestions(searchQuery);
        setSuggestions(results || []);
        setShowSuggestions(results && results.length > 0);
      } catch (error) {
        console.error('Error fetching typeahead suggestions:', error);
        setSuggestions([]);
        setShowSuggestions(false);
      }
    }, 300);
    
    return () => {
      if (searchTimeoutRef.current) {
        clearTimeout(searchTimeoutRef.current);
      }
    };
  }, [searchQuery]);
  
  // Handle outside clicks to close suggestions
  useEffect(() => {
    function handleClickOutside(e) {
      if (
        suggestionsRef.current && 
        !suggestionsRef.current.contains(e.target) &&
        inputRef.current && 
        !inputRef.current.contains(e.target)
      ) {
        setShowSuggestions(false);
      }
    }
    
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);
  
  // Handle keyboard navigation in suggestions
  const handleKeyDown = (e) => {
    // Handle Enter key for form submission
    if (e.key === 'Enter' && (!showSuggestions || selectedSuggestion === -1)) {
      e.preventDefault();
      setShowSuggestions(false);
      setSuggestions([]);
      handleSearchSubmit();
      return;
    }
    
    if (!showSuggestions || !suggestions.length) return;
    
    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        setSelectedSuggestion(prev => 
          prev < suggestions.length - 1 ? prev + 1 : 0
        );
        break;
        
      case 'ArrowUp':
        e.preventDefault();
        setSelectedSuggestion(prev => 
          prev > 0 ? prev - 1 : suggestions.length - 1
        );
        break;
        
      case 'Enter':
        if (selectedSuggestion >= 0) {
          e.preventDefault();
          const selected = suggestions[selectedSuggestion];
          // Use the same suggestion selection logic to maintain context
          selectSuggestion(selected);
        }
        break;
        
      case 'Escape':
        setShowSuggestions(false);
        setSuggestions([]);
        setSelectedSuggestion(-1);
        break;
        
      default:
        break;
    }
  };
  
  // Select a suggestion
  const selectSuggestion = (suggestion) => {
    // When selecting a suggestion, we want to use the full context for searching
    // For tracks, use both artist and title information
    let searchText = '';
    
    if (suggestion.type === 'track') {
      // For tracks, use 'artist title' format for better search precision
      searchText = `${suggestion.artist} ${suggestion.title}`;
    } else if (suggestion.type === 'album') {
      // For albums, include artist information
      searchText = suggestion.artist ? `${suggestion.artist} ${suggestion.title || suggestion.name}` : (suggestion.title || suggestion.name);
    } else {
      // For artists or other types, use name directly
      searchText = suggestion.name || suggestion.title || suggestion.text;
    }
    
    // Update search query with the full context
    setSearchQuery(searchText);
    
    // Clear typeahead state completely
    setShowSuggestions(false);
    setSuggestions([]);
    setSelectedSuggestion(-1);
    
    // Execute the search with the full context
    performSearch(searchText, activeFilter);
    
    // Blur input to dismiss mobile keyboard
    if (inputRef.current) {
      inputRef.current.blur();
    }
  };

  // Handle initial search item
  useEffect(() => {
    if (isOpen && initialSearchItem && !selectedArtist && !selectedAlbum) {
      const timer = setTimeout(() => {
        // Extract album ID from coverart URL if it's an album
        if (initialSearchItem.type === 'album' && initialSearchItem.coverart) {
          const match = initialSearchItem.coverart.match(/albums\/(alb\.\d+)\/images/i);
          if (match) {
            initialSearchItem.albumKey = match[1];
          }
        }
        
        if (initialSearchItem.type === 'artist') {
          setActiveFilter('artists');
          loadArtistDetails(initialSearchItem);
        } else if (initialSearchItem.type === 'album') {
          setActiveFilter('albums');
          loadAlbumDetails(initialSearchItem);
        }
      }, 0);
      return () => clearTimeout(timer);
    }
  }, [isOpen, initialSearchItem, selectedArtist, selectedAlbum, loadArtistDetails, loadAlbumDetails]);

  // Handle back button
  const handleBack = useCallback(() => {
    if (selectedAlbum && selectedArtist) {
      // If we're viewing an album from an artist, go back to artist
      resetAlbum();
    } else {
      // Otherwise reset everything
      resetArtist();
      resetAlbum();
      clearSearch();
    }
  }, [selectedAlbum, selectedArtist, resetAlbum, resetArtist, clearSearch]);

  // Handle close
  const handleClose = useCallback(() => {
    resetArtist();
    resetAlbum();
    clearSearch();
    setSearchQuery('');
    setSuggestions([]);
    setShowSuggestions(false);
    onClose();
  }, [resetArtist, resetAlbum, clearSearch, onClose]);

  // Handle item selection
  const handleItemClick = useCallback((item) => {
    if (item.type === 'album') {
      loadAlbumDetails(item);
    } else if (item.type === 'artist') {
      loadArtistDetails(item);
    }
  }, [loadArtistDetails, loadAlbumDetails]);

  // Add to queue handler
  const handleAddToQueue = (item) => {
    console.log('SearchDialog received:', item, 'Type:', typeof item);
    
    // Handle the case where item is already a track ID (number or string)
    if (typeof item === 'number' || (typeof item === 'string' && !isNaN(parseInt(item)))) {
      console.log('Using direct track ID:', item);
      onAddToQueue(Number(item));
      return;
    }
    
    // Handle objects - extract the correct track ID, not library ID
    if (item && typeof item === 'object') {
      // The backend expects a track ID, not a library ID
      const trackId = item.track?.id || item.viewTrackID || item.id;
      
      console.log('Extracted track ID from object:', trackId, 'Source:', item);
      
      if (trackId) {
        onAddToQueue(Number(trackId));
        return;
      }
    }
    
    console.error('Could not determine valid track ID for queue addition:', item);
  };

  // Determine loading and error states
  const isLoading = isLoadingArtist || isLoadingAlbum || isSearching;
  const error = artistError || albumError || searchError;

  return (
    <Dialog open={isOpen} onOpenChange={(open) => !open && handleClose()}>
      <DialogOverlay 
        className="fixed inset-0 bg-black/80 backdrop-blur-sm z-50 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0" 
      />
      
      <DialogContent 
        className="fixed left-[50%] top-[50%] z-50 grid w-full max-w-3xl translate-x-[-50%] translate-y-[-50%] gap-0 border border-gray-800 bg-[#121212] p-0 shadow-xl duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] sm:rounded-lg"
      >
        {/* Header */}
        <header className="flex items-center justify-between sticky top-0 bg-[#121212] p-4 border-b border-gray-800 z-10">
          <div className="flex items-center gap-2">
            {(selectedArtist || selectedAlbum) && (
              <button
                onClick={handleBack}
                className="inline-flex items-center justify-center rounded-md text-sm font-medium focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-gray-700 bg-transparent hover:bg-slate-900 h-9 px-3 py-2 mr-2"
                aria-label="Back"
              >
                <ChevronLeft className="h-4 w-4 mr-1" />
                Back
              </button>
            )}
            <DialogTitle className="text-xl font-semibold">
              {selectedAlbum ? selectedAlbum.title :
               selectedArtist ? selectedArtist.name :
               'Search Music'}
            </DialogTitle>
          </div>
          
          <DialogClose asChild>
            <button
              className="inline-flex items-center justify-center rounded-full h-8 w-8 border border-gray-700 bg-transparent hover:bg-slate-900 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2"
              aria-label="Close"
              onClick={handleClose}
            >
              <X className="h-4 w-4" />
            </button>
          </DialogClose>
        </header>
        
        {/* Main content */}
        <div className="flex-1 overflow-hidden h-[70vh]">
          {selectedAlbum ? (
            <AlbumDetailView
              album={selectedAlbum}
              tracks={tracks}
              onTrackRate={handleTrackRate}
              onAlbumRate={handleAlbumRate}
              onAddToQueue={handleAddToQueue}
            />
          ) : selectedArtist ? (
            <ArtistDetailView
              artist={selectedArtist}
              albums={albums}
              onAlbumClick={loadAlbumDetails}
              onArtistRate={handleArtistRate}
              onAlbumRate={handleAlbumRate}
            />
          ) : (
            <div className="flex flex-col h-full">
              {/* Tab buttons at the top */}
              <div className="px-4 pt-4">
                <div className="flex gap-1 bg-[#191919] rounded-lg p-1 w-fit">
                  {['tracks', 'albums', 'artists'].map(filter => (
                    <button
                      key={filter}
                      onClick={() => handleFilterChange(filter)}
                      className={`px-4 py-2 rounded-md text-sm font-medium transition-colors ${activeFilter === filter 
                        ? 'bg-blue-600 text-white' 
                        : 'bg-transparent text-gray-400 hover:text-white hover:bg-gray-700'}`}
                    >
                      {filter.charAt(0).toUpperCase() + filter.slice(1)}
                    </button>
                  ))}
                </div>
              </div>
              
              {/* Search input with typeahead */}
              <div className="px-4 pt-4 pb-2">
                <form onSubmit={(e) => { e.preventDefault(); handleSearchSubmit(); }} className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <Search className="h-5 w-5 text-gray-400" />
                  </div>
                  
                  <input
                    ref={inputRef}
                    type="text"
                    placeholder="Search for songs, artists, or albums..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    onKeyDown={handleKeyDown}
                    className="block w-full pl-10 pr-20 py-2 border border-gray-700 rounded-md leading-6 bg-[#222] text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    autoComplete="off"
                  />
                  
                  <div className="absolute inset-y-0 right-0 flex items-center pr-2">
                    <button
                      type="submit"
                      disabled={isSearching || !searchQuery.trim()}
                      className={`p-1 rounded-md ${isSearching ? 'opacity-70' : ''} ${searchQuery.trim() ? 'bg-blue-600 hover:bg-blue-700 text-white' : 'bg-gray-700 text-gray-400 cursor-not-allowed'}`}
                    >
                      {isSearching ? (
                        <div className="h-5 w-5 border-2 border-t-transparent border-white rounded-full animate-spin mx-3" />
                      ) : (
                        <span className="px-3">Search</span>
                      )}
                    </button>
                  </div>
                  
                  {/* Typeahead suggestions */}
                  {showSuggestions && suggestions.length > 0 && (
                    <div
                      ref={suggestionsRef} 
                      className="absolute left-0 right-0 mt-1 bg-[#272727] border border-gray-700 rounded-md shadow-lg max-h-[300px] overflow-y-auto z-50"
                      role="listbox"
                    >
                      <div className="sticky top-0 bg-gray-900 text-xs text-gray-400 py-1 px-3">
                        Found {suggestions.length} results for "{searchQuery}"
                      </div>
                      
                      {suggestions.map((suggestion, index) => {
                        console.log('Suggestion item:', suggestion);
                        
                        // Extract meaningful display text with context
                        const title = suggestion.title || suggestion.name || suggestion.text || '';
                        const artist = suggestion.artist || '';
                        const album = suggestion.album || '';
                        const type = suggestion.type || (suggestion.album ? 'track' : 'unknown');
                        
                        return (
                          <div
                            key={`${title}-${artist}-${album}-${index}`}
                            className={`p-3 cursor-pointer hover:bg-gray-800 border-l-4 ${selectedSuggestion === index ? 'bg-gray-800 border-blue-500' : 'border-transparent'}`}
                            onClick={() => selectSuggestion(suggestion)}
                            role="option"
                            aria-selected={selectedSuggestion === index}
                          >
                            <div className="flex items-start">
                              {/* Type and context information */}
                              <div className="flex flex-col items-center mr-3">
                                <span className="text-xs px-2 py-1 bg-gray-800 text-gray-300 rounded-md mb-1 font-medium w-16 text-center">
                                  {type === 'track' ? 'Track' : 
                                   type === 'album' ? 'Album' : 
                                   type === 'artist' ? 'Artist' : 'Item'}
                                </span>
                              </div>
                              
                              <div className="flex-1">
                                {/* Title with artist in one prominent line */}
                                <div className="flex items-baseline mb-1">
                                  <span className="font-bold text-white mr-2">{title}</span>
                                  {artist && (
                                    <span className="text-sm text-blue-300">by {artist}</span>
                                  )}
                                </div>
                                
                                {/* Album as a separate line with icon */}
                                {album && (
                                  <div className="flex items-center mt-1 text-xs text-gray-300">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3 mr-1" viewBox="0 0 20 20" fill="currentColor">
                                      <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM10 5a1 1 0 100 2 1 1 0 000-2zm0 8a1 1 0 100-2 1 1 0 000-2z" clipRule="evenodd" />
                                    </svg>
                                    <span>Album: <span className="font-medium">{album}</span></span>
                                  </div>
                                )}
                                
                                {/* Include any additional metadata that might be available */}
                                {suggestion.releaseYear && (
                                  <div className="text-xs text-gray-400 mt-1">
                                    Released: {suggestion.releaseYear}
                                  </div>
                                )}
                                
                                {/* Full text for context */}
                                {suggestion.text && (
                                  <div className="text-xs text-gray-500 mt-1 truncate border-t border-gray-700 pt-1">
                                    {suggestion.text}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </form>
              </div>
              
              {/* Results area */}
              <div className="flex-1 overflow-y-auto px-4 py-2">
                {isLoading ? (
                  <div className="flex flex-col items-center justify-center h-full py-12">
                    <div className="w-12 h-12 border-4 border-t-blue-500 border-blue-500/20 rounded-full animate-spin mb-4"></div>
                    <p className="text-gray-400">Searching...</p>
                  </div>
                ) : error ? (
                  <div className="flex flex-col items-center justify-center h-full text-center py-12">
                    <AlertCircle className="h-12 w-12 text-red-500 mb-4" />
                    <h3 className="text-lg font-medium text-red-400 mb-2">Search error</h3>
                    <p className="text-sm text-gray-400">{error}</p>
                  </div>
                ) : results.length > 0 ? (
                  <div className="space-y-2 pb-4">
                    {results.map(result => (
                      <SearchResultItem
                        key={result.id || `${result.title}-${result.artist}`}
                        result={result}
                        onItemClick={handleItemClick}
                        onRate={handleTrackRate}
                        onAddToQueue={handleAddToQueue}
                      />
                    ))}
                  </div>
                ) : hasActiveSearch ? (
                  <div className="flex flex-col items-center justify-center h-full text-center py-12">
                    <Info className="h-12 w-12 text-gray-600 mb-4" />
                    <h3 className="text-lg font-medium text-gray-300 mb-2">No results found</h3>
                    <p className="text-sm text-gray-400">Try different keywords or filters</p>
                  </div>
                ) : (
                  <div className="flex flex-col items-center justify-center h-full text-center py-12">
                    <Search className="h-12 w-12 text-gray-600 opacity-20 mb-4" />
                    <h3 className="text-lg font-medium text-gray-300 mb-2">Search for music</h3>
                    <p className="text-sm text-gray-400">Find your favorite songs, artists, or albums</p>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
}
