import React from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { Login } from './Login';
import { logDebug } from '../services/debugService';

const debugAuthWrapper = (message, data = {}) => {
  logDebug('AuthWrapper', message, data);
};

export function AuthWrapper({ children }) {
  const { user } = useAuth();
  const location = useLocation();
  
  debugAuthWrapper('Checking auth', { 
    path: location.pathname,
    isAuthenticated: !!user?.authenticated
  });
  
  if (!user?.authenticated) {
    debugAuthWrapper('User not authenticated, showing login', {
      path: location.pathname
    });
    
    // Only store the intended path if it's not the login path
    if (location.pathname !== '/login') {
      localStorage.setItem('intended_path', location.pathname);
      debugAuthWrapper('Stored intended path', {
        path: location.pathname
      });
    }
    
    // Show login component without changing URL
    return <Login />;
  }
  
  debugAuthWrapper('User authenticated, rendering content', {
    path: location.pathname
  });

  // Render the children components
  return children;
}
