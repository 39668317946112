import { useState } from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import { useAuth } from '../../contexts/AuthContext';
import { useRoom } from '../../contexts/RoomContext';
import RatingAdjustments from './sections/RatingAdjustments';
import QueueSettings from './sections/QueueSettings';
import RoomModeSettings from './sections/RoomModeSettings';
import MusicBrainzSettings from './sections/MusicBrainzSettings';

export default function RoomSettingsDrawer({ isOpen, onClose }) {
  const { user } = useAuth();
  const { currentRoom } = useRoom();
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [confirmUsername, setConfirmUsername] = useState('');
  const [deleteError, setDeleteError] = useState('');

  // Debug room data
  console.log('Room Settings:', {
    userName: user?.name,
    userLogin: user?.login,
    currentRoom: currentRoom,
    roomName: currentRoom?.name,
    isMatch: user?.name === currentRoom?.name || user?.login === currentRoom?.name
  });

  const handleDeleteConfirm = async () => {
    if (!user?.name) {
      setDeleteError('User not properly authenticated');
      return;
    }

    if (confirmUsername !== user.name) {
      setDeleteError('Username does not match');
      return;
    }

    try {
      // TODO: Implement delete user and room functionality
      setShowConfirmDelete(false);
    } catch (err) {
      setDeleteError(err.response?.data || 'Failed to delete account');
    }
  };

  // Show delete option if user is authenticated and matches room name
  const showDeleteOption = Boolean(user?.authenticated) && currentRoom?.name && (
    user.name === currentRoom.name || 
    user.login === currentRoom.name
  );
  
  console.log('Delete visibility check:', {
    userName: user?.name,
    userLogin: user?.login,
    roomName: currentRoom?.name,
    showDelete: showDeleteOption
  });

  return (
    <Dialog.Root open={isOpen} onOpenChange={onClose}>
      <Dialog.Portal>
        <Dialog.Overlay className="bg-black/50 fixed inset-0" />
        <Dialog.Content className="fixed right-0 top-0 h-full w-96 bg-[#1A1A1A] shadow-xl p-6 focus:outline-none overflow-y-auto">
          <Dialog.Title className="text-2xl font-bold mb-6">Room Settings</Dialog.Title>
          
          {/* Room Type Settings */}
          <RoomModeSettings />

          {/* Queue Settings */}
          <QueueSettings />

          {/* Rating Adjustments */}
          <RatingAdjustments />

          {/* MusicBrainz Replication Settings */}
          <MusicBrainzSettings />

          {/* Delete Account Section */}
          {showDeleteOption && (
            <div className="mt-8 border-t border-gray-700 pt-6">
              <h3 className="text-xl font-semibold text-red-500 mb-4">Delete Account</h3>
              
              {!showConfirmDelete ? (
                <button
                  onClick={() => setShowConfirmDelete(true)}
                  className="w-full bg-red-600 text-white py-2 px-4 rounded hover:bg-red-700 transition-colors"
                >
                  Delete Account and Room
                </button>
              ) : (
                <div className="space-y-4">
                  <p className="text-sm text-gray-400">
                    This action cannot be undone. Please type your username to confirm.
                  </p>
                  
                  <input
                    type="text"
                    value={confirmUsername}
                    onChange={(e) => setConfirmUsername(e.target.value)}
                    placeholder="Enter username to confirm"
                    className="w-full bg-[#2A2A2A] border border-gray-600 rounded px-3 py-2"
                  />
                  
                  {deleteError && (
                    <p className="text-red-500 text-sm">{deleteError}</p>
                  )}
                  
                  <div className="flex gap-4">
                    <button
                      onClick={() => setShowConfirmDelete(false)}
                      className="flex-1 bg-gray-600 text-white py-2 px-4 rounded hover:bg-gray-700 transition-colors"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={handleDeleteConfirm}
                      className="flex-1 bg-red-600 text-white py-2 px-4 rounded hover:bg-red-700 transition-colors"
                    >
                      Confirm Delete
                    </button>
                  </div>
                </div>
              )}
            </div>
          )}

          {/* Close Button */}
          <Dialog.Close asChild>
            <button
              className="absolute top-4 right-4 w-8 h-8 flex items-center justify-center text-gray-400 hover:text-white"
              aria-label="Close"
            >
              ✕
            </button>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
