import React from 'react';
import { Button } from '../../ui/Button';
import { RatingStars } from '../../RatingStars';
import { DefaultAlbumArt } from '../../common/DefaultAlbumArt';
import { Disc, User } from 'lucide-react';

/**
 * Search result item component
 */
export function SearchResultItem({ 
  result, 
  onItemClick, 
  onRate, 
  onAddToQueue,
  showRating = true,
  showAddButton = true
}) {
  // Get appropriate icon based on result type
  const getTypeIcon = () => {
    if (result.type === 'artist') return <User className="text-blue-400" size={12} />;
    if (result.type === 'album') return <Disc className="text-purple-400" size={12} />;
    return null;
  };

  // Handle item click, ensuring we have the correct data
  const handleItemClick = () => {
    // Extract album ID from coverart URL if needed
    let albumKey = result.albumKey || result.albumkey;
    if (!albumKey && result.coverart) {
      const match = result.coverart.match(/albums\/(alb\.\d+)\/images/i);
      if (match) {
        albumKey = match[1];
      }
    }

    // Ensure we pass the correct album information
    const itemInfo = {
      type: result.type,
      id: result.id || albumKey,
      albumKey: albumKey,
      album: result.track?.album || result.album,
      title: result.track?.title || result.title,
      name: result.name,
      artist: result.track?.artist || result.artist,
      coverart: result.track?.coverart || result.coverart
    };
    onItemClick?.(itemInfo);
  };

  // Add to queue handler
  const handleAddToQueue = (e) => {
    e.stopPropagation();
    
    // The server's /room/queueAdd endpoint expects a Track ID, not a library ID
    // Even though the param is called 'libraryItem', it's used to find a Track
    const trackId = result.track?.id || result.viewTrackID || result.id;
    
    if (!trackId) {
      console.error('No valid track ID found for queue addition:', result);
      return;
    }
    
    // Log for debugging
    console.log('Adding to queue with TRACK ID:', trackId, 'Source result:', result);
    
    // Pass the track ID to the parent component
    onAddToQueue?.(trackId);
  };

  // Rating change handler
  const handleRatingChange = (newRating) => {
    const trackId = result.viewTrackID || result.lid || result.track?.id;
    if (trackId && !isNaN(trackId)) {
      onRate?.(trackId, newRating);
    } else {
      console.error('No valid track ID found for rating:', result);
    }
  };

  // Check if item is a track (either has type='track' or has track-like properties)
  const isTrackItem = () => {
    // If it's explicitly an album or artist, it's not a track
    if (result.type === 'artist' || result.type === 'album') return false;
    
    // If it's explicitly a track, it is a track
    if (result.type === 'track') return true;
    
    // If there's no explicit type, check for track-like properties
    return Boolean(
      (result.track?.title) || // Has a nested track object with a title
      (result.title && !result.name) || // Has a title but no name (artists have name)
      (result.title && result.artist) // Has both title and artist
    );
  };

  // Always show add button for tracks that aren't artists or albums
  const shouldShowAddButton = showAddButton && 
    result.type !== 'artist' && 
    result.type !== 'album' && 
    onAddToQueue;

  return (
    <div 
      className="flex items-center gap-3 p-3 hover:bg-white/5 rounded-lg transition-colors cursor-pointer group border border-transparent hover:border-gray-800"
      onClick={handleItemClick}
      role="button"
      aria-label={`${result.type === 'track' ? 'Song' : result.type === 'album' ? 'Album' : 'Artist'}: ${result.track?.title || result.title || result.name || 'Unknown'} by ${result.track?.artist || result.artist || 'Unknown'}`}
      tabIndex="0"
      onKeyDown={(e) => e.key === 'Enter' && handleItemClick()}
    >
      {/* Album artwork with type indicator */}
      <div className="relative w-14 h-14 bg-[#282828] rounded-md overflow-hidden shrink-0 shadow-md">
        <img
          src={result.track?.album_baseicon || result.track?.coverart || result.coverart || DefaultAlbumArt.dataUrl}
          alt={result.track?.album || result.album || 'Album Art'}
          className="w-full h-full object-cover"
          onError={DefaultAlbumArt.onError}
          loading="lazy"
        />
        {result.type && (
          <div className="absolute bottom-0 left-0 right-0 bg-black/60 py-0.5 px-1 flex items-center justify-center text-xs font-medium">
            {getTypeIcon()}
            <span className="ml-1">{result.type.charAt(0).toUpperCase() + result.type.slice(1)}</span>
          </div>
        )}
      </div>

      {/* Content area */}
      <div className="flex-1 min-w-0 flex items-center gap-2">
        <div className="flex-1 min-w-0">
          <div className="font-medium text-white truncate">
            {result.track?.title || result.title || result.name || 'Unknown'}
          </div>
          
          <div className="text-sm text-gray-400 truncate">
            {result.track?.artist || result.artist || ''} 
            {result.track?.album || result.album ? ` \u2022 ${result.track?.album || result.album}` : ''}
          </div>
          
          {showRating && result.type !== 'artist' && (
            <div className="mt-1" onClick={(e) => e.stopPropagation()}>
              <RatingStars
                rating={result.rating / 20}
                onChange={handleRatingChange}
                size={16}
                aria-label={`Rate ${result.track?.title || result.title || 'track'}`}
              />
            </div>
          )}
        </div>

        {/* Always show Add button for tracks */}
        {shouldShowAddButton && (
          <Button 
            onClick={handleAddToQueue} 
            size="sm"
            variant="default"
            className="shrink-0 opacity-80 group-hover:opacity-100 transition-opacity"
            aria-label={`Add ${result.track?.title || result.title || 'track'} to queue`}
          >
            Add
          </Button>
        )}
      </div>
    </div>
  );
}
