import { useState } from 'react';
import { useAuth } from '../../../contexts/AuthContext';
import { musicbrainzApi } from '../../../services/api/musicbrainzApi';

export default function MusicBrainzSettings() {
  const { user } = useAuth();
  const [replicationStatus, setReplicationStatus] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Only show for eltmon
  if (!user?.name || user.name.toLowerCase() !== 'eltmon') {
    return null;
  }

  const checkReplicationStatus = async () => {
    try {
      setLoading(true);
      setError(null);
      const data = await musicbrainzApi.getReplicationStatus();
      setReplicationStatus(data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const triggerReplicationUpdate = async () => {
    try {
      setLoading(true);
      setError(null);
      const data = await musicbrainzApi.triggerReplicationUpdate();
      setReplicationStatus({ message: data });
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="mt-8 border-t border-gray-700 pt-6">
      <h3 className="text-xl font-semibold mb-4">MusicBrainz Replication</h3>
      
      {/* Status Display */}
      {replicationStatus && (
        <div className="mb-4 p-4 bg-[#2A2A2A] rounded">
          {replicationStatus.message ? (
            <p className="text-sm text-gray-300">{replicationStatus.message}</p>
          ) : (
            <>
              <p className="text-sm text-gray-300">
                Schema Sequence: {replicationStatus.current_schema_sequence}
              </p>
              <p className="text-sm text-gray-300">
                Replication Sequence: {replicationStatus.current_replication_sequence}
              </p>
              <p className="text-sm text-gray-300">
                Last Updated: {new Date(replicationStatus.last_updated).toLocaleString()}
              </p>
            </>
          )}
        </div>
      )}

      {/* Error Display */}
      {error && (
        <div className="mb-4 p-4 bg-red-900/50 text-red-300 rounded">
          {error}
        </div>
      )}

      {/* Action Buttons */}
      <div className="flex gap-4">
        <button
          onClick={checkReplicationStatus}
          disabled={loading}
          className="flex-1 bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700 transition-colors disabled:opacity-50"
        >
          Check Status
        </button>
        <button
          onClick={triggerReplicationUpdate}
          disabled={loading}
          className="flex-1 bg-green-600 text-white py-2 px-4 rounded hover:bg-green-700 transition-colors disabled:opacity-50"
        >
          Update Now
        </button>
      </div>
    </div>
  );
}
