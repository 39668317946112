import React from 'react';

export const DefaultAlbumArt = {
  // SVG data URL for the default album art
  dataUrl: 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9IiM2NjYiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj48cGF0aCBkPSJNOSAxOFY1bDEyLTJ2MTNIOWM0IDAgNC00IDAtNHoiLz48L3N2Zz4=',

  // Reusable onError handler for img elements
  onError: (e) => {
    e.target.src = DefaultAlbumArt.dataUrl;
    e.target.classList.add('placeholder');
  },

  // Component for rendering the default album art
  Component: ({ className = '', ...props }) => (
    <img
      src={DefaultAlbumArt.dataUrl}
      alt="Default album art"
      className={`${className} placeholder`}
      {...props}
    />
  )
};
